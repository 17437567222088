import { DirectDebitSessionHeader } from "@app/products/direct-debit/sessions/model";

export const DDSessionsBookmark = {
  getBookmarkListViewDisplayName() {
    return "Direct Debit - Session";
  },

  getBookmarkListViewDetail(viewName: string) {
    return `Direct Debit - Session - ${viewName}`;
  },

  getBookmarkListViewDetailRecord(selectedRow: DirectDebitSessionHeader) {
    const dynamicDisplayName = selectedRow?.Session_ID
      ? `- ${selectedRow.Session_ID}`
      : "";
    return `Direct Debit - Session ${dynamicDisplayName}`;
  },
};

import {
  DirectDebitBankAccountView,
  DirectDebitGroup,
  DirectDebitStatusCodeView,
  IntegrationModuleItemType,
} from "@app/products/direct-debit/system-admin/model";
import { isNil } from "lodash";

export const DDSystemAdminBookmark = {
  getBookmarkListViewDisplayName() {
    return "Direct Debit - System Admin";
  },

  getBookmarkListViewDetail(viewName: string) {
    return `Direct Debit - System Admin - ${viewName}`;
  },

  getBookmarkListViewDetailRecordBankAccount(
    selectedRow: DirectDebitBankAccountView
  ) {
    const dynamicDisplayName = selectedRow?.Direct_Debit_Bank_Account_Number
      ? `- ${selectedRow.Direct_Debit_Bank_Account_Number}`
      : "";
    return `Direct Debit - System Admin ${dynamicDisplayName}`;
  },

  getBookmarkListViewDetailRecordGroup(selectedRow: DirectDebitGroup) {
    const dynamicDisplayName = selectedRow?.Direct_Debit_Group_ID
      ? `- ${selectedRow.Direct_Debit_Group_ID}`
      : "";
    return `Direct Debit - System Admin ${dynamicDisplayName}`;
  },

  getBookmarkListViewDetailRecordModuleItemType(
    selectedRow: IntegrationModuleItemType
  ) {
    const dynamicDisplayName = selectedRow?.Item_Type_ID
      ? `- ${selectedRow.Item_Type_ID}`
      : "";
    return `Direct Debit - System Admin ${dynamicDisplayName}`;
  },

  getBookmarkListViewDetailRecordStatusCode(
    selectedRow: DirectDebitStatusCodeView
  ) {
    const dynamicDisplayName = selectedRow?.Direct_Debit_Status_Code_ID
      ? `- ${selectedRow.Direct_Debit_Status_Code_ID}`
      : "";
    return `Direct Debit - System Admin ${dynamicDisplayName}`;
  },
};

export const stringLengthValidator = (
  value: any,
  minLength: number,
  maxLength: number
) => {
  if (minLength > 0 && (isNil(value) || value?.length === 0)) {
    return "This field is required.";
  }

  if (value?.length < minLength) {
    return `The minimum is ${minLength} characters.`;
  }

  if (value?.length > maxLength) {
    return `The maximum is ${maxLength} characters.`;
  }

  return "";
};

import { Svc_Contact } from "@app/core/contacts/_id/model";
import { useDirectDebitStore } from "@app/products/direct-debit/accounts/[id]/store";
import {
  DirectDebitAccount,
  Svc_Contact_With_ID,
} from "@app/products/direct-debit/accounts/model";
import { DIRECT_DEBIT_AUTHORISATIONS } from "@app/products/direct-debit/constant";
import { ContactPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/contact-picker/_index";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredStringValidator } from "@common/utils/field-validators";
import { getFullAddressSingleLine } from "@common/utils/formatting";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import React from "react";
import { Link } from "react-router-dom";

interface IDDAccountGeneralSectionProps {
  formRenderProps: FormRenderProps;
  isDisabled?: boolean;
}

const nameOf = nameOfFactory<DirectDebitAccount>();

export const DDAccountGeneralSection = ({
  formRenderProps,
  isDisabled,
}: IDDAccountGeneralSectionProps) => {
  const { valueGetter, onChange } = formRenderProps;

  const { accountContact, setAccountContact } = useDirectDebitStore();
  const { dataForm } = useFlexibleFormStore();

  const getValue = (nameOfField: keyof DirectDebitAccount) =>
    valueGetter(nameOf(nameOfField));
  const onChangeField = (nameOfField: keyof DirectDebitAccount, value: any) => {
    onChange(nameOf(nameOfField as keyof DirectDebitAccount), {
      value: value,
    });
  };

  const ddGroupsPickerHandler = (value: ComboBoxChangeEvent) => {
    onChangeField("Direct_Debit_Group_ID", value?.value?.Key);
    onChangeField("Direct_Debit_Group_Name", value?.value?.Value);
  };

  const getAddressFromContact = (contact?: Svc_Contact) => {
    if (contact)
      return getFullAddressSingleLine(
        contact?.PreferredAddress ?? contact?.PostalAddress
      );
    return "";
  };

  const handleChangeDDContact = (event: ComboBoxChangeEvent | null) => {
    const value = event?.value;
    if (!value) {
      setAccountContact(undefined);
      onChangeField("Contact_ID", undefined);
      onChangeField("Address", undefined);
      onChangeField("Name", undefined);
      onChangeField("State", undefined);
      onChangeField("Post_Code", undefined);
      return;
    }
    setAccountContact(value);
    onChangeField("Contact_ID", value?.Contact_Id ?? value?.Contact_ID);
    onChangeField("Address", getAddressFromContact(value as Svc_Contact));
    onChangeField("Name", value?.DisplayName);
    onChangeField("State", value?.PreferredAddress?.State);
    onChangeField("Post_Code", value?.PreferredAddress?.Postcode);
  };

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <CCValueField
          label="Account number"
          value={getValue("Account_Number")}
        />
        <CCValueField
          label="Account reference"
          value={dataForm?.GeneralForm?.Account_Reference ?? ""}
        />
        <CCValueField
          label="Authorisation Number"
          value={
            <div className="cc-label-field cc-link-field">
              <Link
                to={
                  DIRECT_DEBIT_AUTHORISATIONS +
                  "/" +
                  getValue("Authorisation_Id")
                }
                rel="noopener noreferrer"
                className="cc-grid-link-text cc-label-field"
              >
                {getValue("Authorisation_Number")}
              </Link>
            </div>
          }
        />
      </div>
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <CCLabel title="Manual description" />
          <Field
            name={nameOf("Manual_Description")}
            component={CCTextArea}
            placeholder="Manual description"
            rows={4}
            readOnly={isDisabled}
          />
        </div>
      </div>
      <div className="cc-form-cols-3">
        <div className="cc-field">
          <CCLabel title="Name" isMandatory />
          <Field
            name={nameOf("Name")}
            nameDisplay="DisplayName"
            placeholder="Select name"
            value={{
              ...accountContact,
              Contact_ID:
                accountContact?.Contact_Id ??
                (accountContact as Svc_Contact_With_ID)?.Contact_ID,
            }}
            component={ContactPicker}
            onChange={handleChangeDDContact}
            displayValue={accountContact?.DisplayName}
            validator={requiredStringValidator}
            disabled={isDisabled}
            disabledButton={isDisabled}
          />
        </div>
        <div className="cc-field cc-col-span-2">
          <CCValueField
            label={"Address"}
            value={getAddressFromContact(accountContact)}
          />
        </div>
      </div>
      <div className="cc-form-cols-3">
        <div className="cc-field">
          <CCValueField label={"Email"} value={accountContact?.Email} />
        </div>
        <div className="cc-field">
          <CCValueField
            label={"Phone number"}
            value={accountContact?.WorkPhone}
          />
        </div>
        <div className="cc-field">
          <CCValueField
            label={"Mobile phone number"}
            value={accountContact?.Mobile}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="Group" />
          <Field
            component={CCComboBox}
            name={nameOf("Direct_Debit_Group_ID")}
            nameDisplay="DisplayName"
            textField={"Value"}
            dataItemKey={"Key"}
            value={getDropdownValue(
              getValue("Direct_Debit_Group_ID"),
              dataForm.GeneralFormLovs?.IncludeGroups ?? [],
              "Key"
            )}
            data={dataForm.GeneralFormLovs?.IncludeGroups ?? []}
            onChange={ddGroupsPickerHandler}
            disabled={isDisabled}
          />
        </div>
      </div>
    </section>
  );
};

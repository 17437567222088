import { changeDirectDebitAccountStatus } from "@app/products/direct-debit/accounts/[id]/api";
import { checkIsInactiveDirectDebitAccount } from "@app/products/direct-debit/accounts/[id]/util";
import {
  DirectDebitAccount,
  DirectDebitAccountStatus,
} from "@app/products/direct-debit/accounts/model";
import { APIResponse } from "@common/apis/model";
import {
  fetchApiByAlias,
  useFlexibleFetchData,
} from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { IdentityPacket } from "@common/models/identityPacket";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { observer } from "mobx-react-lite";
import React from "react";

interface ISaveButtonDirectDebitSetStatusProps {
  status: DirectDebitAccountStatus;
  title: string;
  disabled?: boolean;
  isLoadingPermission?: boolean;
}

export const SetStatusButtonDirectDebit = observer(
  ({
    status,
    title = "",
    disabled = false,
    isLoadingPermission = false,
  }: ISaveButtonDirectDebitSetStatusProps) => {
    //#region STORE ========/
    const { submitFormGetData, isLoadingForm, setIsLoadingForm } =
      useFlexibleFormStore();
    //#endregion STORE =====/

    //#region HOOK ========/
    const { pushNotificationPortal } = useNotificationPortalStore();
    const { cancelToken } = useCancelRequest();
    //#endregion HOOK =====/

    const slider = useFlexibleFetchData({
      slides: [
        {
          fetch: ({ initialData }) => {
            const directDebitAccount: DirectDebitAccount = initialData;
            directDebitAccount.AccountStatus_ENUM = status;
            directDebitAccount.AccountStatus_Name =
              DirectDebitAccountStatus[status];
            return changeDirectDebitAccountStatus(
              directDebitAccount,
              cancelToken()
            );
          },
          handleSuccess: ({ dataFromApi }) => {
            const response: APIResponse<IdentityPacket | undefined> =
              dataFromApi;
            if (response?.data) {
              fetchApiByAlias("changeStatusSlider");
            }
          },
          handleError: (error) => {
            const { errorFromApi, initialData } = error;
            pushNotificationPortal({
              title: initialData?.errorMsg,
              type: "error",
              description: errorFromApi.error,
              autoClose: false,
            });
          },
        },
      ],
    });

    const handleClickButton = async () => {
      const dataForm = await submitFormGetData("GeneralForm", {
        skipCheckModified: true,
      });
      const directDebitAccount = dataForm?.GeneralForm as DirectDebitAccount;

      if (directDebitAccount) {
        setIsLoadingForm(true);
        await slider.fetchApi({
          initialData: directDebitAccount,
        });
        setIsLoadingForm(false);
      }
    };

    return (
      <CCNavButton
        title={title}
        onClick={handleClickButton}
        disabled={
          isLoadingForm ||
          checkIsInactiveDirectDebitAccount() ||
          disabled ||
          isLoadingPermission
        }
      />
    );
  }
);

import { EditFinancialSummaryButton } from "@app/products/property/assessments/financial-summaries/components/action-bar/buttons/edit-financial-summary/_index";
import { AssessmentFinancialSummaryDetailTab } from "@app/products/property/assessments/financial-summaries/[id]/components/reference-sidebar/detail/_index";
import { colFinancialSummaries } from "@app/products/property/assessments/financial-summaries/[id]/config";
import { IGridSummary } from "@app/products/property/assessments/financial-summaries/[id]/model";
import { useFinancialSummaryStore } from "@app/products/property/assessments/financial-summaries/[id]/store";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useGlobalStore } from "@common/stores/global/store";
import { nameOfFactory } from "@common/utils/common";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import "./_index.scss";
import { ECustomColNameProperty } from "@app/products/property/config";
import { Label } from "@common/stores/products/config";

const PropertyFinancialSummaryDetail = observer(() => {
  const [showSlideBar, setShowSlideBar] = useState(true);
  const params: { id: string } = useParams();
  const financialId = params.id;
  const managePageUrl = window.location.pathname;
  const {
    loadFinancialSummary,
    isLoading,
    resetStore,
    financialSummary,
    responseLoadError,
  } = useFinancialSummaryStore();
  const { resetMenu } = useCCSubActionBarStore();
  const nameOf = nameOfFactory<IGridSummary>();
  const { currentUserInfo } = useGlobalStore();

  //Get label
  const assessmentLabel = Label.CommunityProperty.getLabel(
    ECustomColNameProperty.Assessment
  );

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `Property - ${assessmentLabel} - Financial Summaries`,
      LinkUrl: managePageUrl,
      LinkText: `Property - Financial Summaries - ${financialId}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.CommunityProperty,
      RecordType_ENUM: RECORDTYPE.CommunityProperty_Unknown,
      Record_ID: +financialId,
    },
  ];
  useEffectOnce(() => {
    return () => {
      resetStore();
      resetMenu();
    };
  });

  useEffect(() => {
    loadFinancialSummary(financialId);
  }, [financialId, loadFinancialSummary]);

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <AssessmentFinancialSummaryDetailTab /> },
    ],
  });

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="Financial Summary" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadFinancialSummary(financialId)}
        />
      ) : (
        <>
          <FormTitle title={""} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <EditFinancialSummaryButton financialId={financialId} />
              </CCNavButton>,
              <CCNavButton title={"Print"} />,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <div className="cc-manage-form-body cc-financial-summary-body">
                <CCGrid
                  style={{ height: "100%" }}
                  data={financialSummary ?? []}
                  columnFields={colFinancialSummaries}
                  primaryField={nameOf("Id")}
                  itemPerPage={50}
                />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});

export default PropertyFinancialSummaryDetail;

import {
  apiCoreCloneContactProperty,
  apiCoreGetContactsByClassification,
} from "@app/core/components/common/utils";
import {
  CONTACTSEARCHFILTER,
  DTO_COO_EntitySummary,
  DTO_Entity_Create,
  DTO_Entity_Lookup,
} from "@app/products/property/components/fields/search-name/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { ContactLookahead_JSON } from "@common/models/contact";
import { CancelTokenSource } from "axios";

//Recheck with lookup api. will remove this.
export const loadSearchName = async (
  searchString: string,
  searchParams: string,
  requestController?: CancelTokenSource
): Promise<APIResponse<DTO_Entity_Lookup>> => {
  try {
    const response = await CoreAPIService.getClient().get(
      `/api/property/int/entity/${searchString}/lookup?${searchParams}`,
      {
        cancelToken: requestController?.token,
      }
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error:
        requestController?.token.reason?.message ?? errorResponse.data?.Message,
    };
  }
};

export const putCloneEntityProperty = async (
  contactSearchResultId: number
): Promise<APIResponse<DTO_Entity_Create | undefined>> => {
  try {
    return await CoreAPIService.getClient().put(
      apiCoreCloneContactProperty(contactSearchResultId),
      {},
      {
        params: {
          productType: 42,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const searchContacts = async (
  searchText: string,
  productType: PRODUCT_TYPE_NUMBER,
  filter: CONTACTSEARCHFILTER,
  requestController?: CancelTokenSource
): Promise<APIResponse<ContactLookahead_JSON[] | undefined>> => {
  try {
    return await CoreAPIService.getClient().get(
      apiCoreGetContactsByClassification(),
      {
        params: {
          searchText,
          filter,
          productType,
        },
        cancelToken: requestController?.token,
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error:
        requestController?.token.reason?.message ?? errorResponse.data?.Message,
    };
  }
};

export const loadNameDetail = async (
  entityId: string | number
): Promise<APIResponse<DTO_COO_EntitySummary>> => {
  try {
    const response = await CoreAPIService.getClient().get(
      `/api/property/int/changeofownership/${entityId}/entity`
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
    };
  }
};

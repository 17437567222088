import { DIRECT_DEBIT_MODULE_ITEM_TYPES_ODATA } from "@app/products/direct-debit/constant";
import { directDebitRoute } from "@app/products/direct-debit/route";
import { IntegrationModuleItemType } from "@app/products/direct-debit/system-admin/model";
import { SysAdminModuleItemTypesHistoryTab } from "@app/products/direct-debit/system-admin/module-item-types/[id]/components/slidebar/history/_index";
import { colModuleItemTypes } from "@app/products/direct-debit/system-admin/module-item-types/config";
import { nameOfFactory } from "@common/utils/common";
import { CCActionBarNavDropdownNew } from "@components/cc-list-view-action-bar/components/nav-dropdown-new/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";

const nameOf = nameOfFactory<IntegrationModuleItemType>();

export default () => {
  useCCListViewActionBar({
    title: directDebitRoute.name,
    leftComponents: [<CCActionBarNavDropdownNew />],
    centerComponents: [<CCNavButton title="New" />],
    rightComponents: [
      // <ActionBarExportIcon />,
      // <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      // <ListViewBookmarkIcon
      //   linkUrl={DIRECT_DEBIT_MODULE_ITEM_TYPES}
      //   productType={PRODUCT_TYPE.DirectDebit}
      //   recordType={RECORDTYPE.Receipting_Integration_Module_Item_Type}
      //   detail={DDSystemAdminBookmark.getBookmarkListViewDisplayName}
      //   displayName={
      //     DDSystemAdminBookmark.getBookmarkListViewDetailRecordModuleItemType
      //   }
      //   listViewDetail={() =>
      //     DDSystemAdminBookmark.getBookmarkListViewDetail("Module Item Types")
      //   }
      //   listViewDisplayName={
      //     DDSystemAdminBookmark.getBookmarkListViewDisplayName
      //   }
      // />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "History",
        component: <SysAdminModuleItemTypesHistoryTab />,
      },
    ],
  });

  return (
    <CCProductListView
      columnFields={colModuleItemTypes}
      dataUrl={DIRECT_DEBIT_MODULE_ITEM_TYPES_ODATA}
      primaryField={nameOf("Item_Type_ID")}
      state={{
        sort: [{ field: nameOf("Item_Type_ID"), dir: "desc" }],
      }}
    />
  );
};

import { AddCommentPropertyButton } from "@app/core/comments/components/buttons/add-comment/components/add-comment-property/_index";
import { PropertyComments } from "@app/core/comments/components/property-comments/_index";
import { AddToSupplementaryButton } from "@app/products/property/assessments/master-properties/components/actionbar/buttons/add-to-supplementary/_index";
import { ModifyMasterPropertyButton } from "@app/products/property/assessments/master-properties/components/actionbar/buttons/modify-master-property/_index";
import MasterPropertyAssessments from "@app/products/property/assessments/master-properties/[id]/components/child-screens/assessments/_index";
import { MasterPropertyAttributes } from "@app/products/property/assessments/master-properties/[id]/components/child-screens/attributes/_index";
import { MasterPropertyForm } from "@app/products/property/assessments/master-properties/[id]/components/child-screens/general/_index";
import { MasterPropertiesValuations } from "@app/products/property/assessments/master-properties/[id]/components/child-screens/valuations/_index";
import { MasterPropertyAssociationsTab } from "@app/products/property/assessments/master-properties/[id]/components/reference-sidebar/associations/_index";
import { MasterPropertyDetailTab } from "@app/products/property/assessments/master-properties/[id]/components/reference-sidebar/detail/_index";
import { useMasterPropertyStore } from "@app/products/property/assessments/master-properties/[id]/store";
import { eDocumentTypeCategory } from "@app/products/property/components/action-bar/button/add-attachment/model";
import { AddAttachmentPropertyButton } from "@app/products/property/components/action-bar/button/add-attachment/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyAssociations } from "@app/products/property/components/associations/_index";
import { PropertyActions } from "@app/products/property/components/child-screen/actions";
import { PropertyDocuments } from "@app/products/property/components/child-screen/documents/_index";
import {
  IPropertyPanelBar,
  PropertyPanelBar,
} from "@app/products/property/components/panel-bar/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { AddActionWithParentButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/action/_index";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useGlobalStore } from "@common/stores/global/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { MapTab } from "@components/cc-reference-sidebar/components/Map/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { eAccordionType } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ECustomColNameProperty } from "@app/products/property/config";
import { Label } from "@common/stores/products/config";

const ExistedMasterProperties = observer(() => {
  const params: { id: string } = useParams();
  const masterPropertyId = parseInt(params.id);
  const [showSlideBar, setShowSlideBar] = useState(true);
  const managePageUrl = window.location.pathname;
  const { masterProperty, isLoading, loadMasterProperty, responseLoadError } =
    useMasterPropertyStore();
  const getBookmarkDetail = useMemo(() => {
    return (
      masterPropertyId +
      (masterProperty?.MP_Property_Address
        ? " - " + masterProperty.MP_Property_Address
        : "")
    );
  }, [masterProperty, masterPropertyId]);
  const { currentUserInfo } = useGlobalStore();

  //Get label
  const assessmentLabel = Label.CommunityProperty.getLabel(
    ECustomColNameProperty.Assessment
  );

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: getBookmarkDetail,
      LinkUrl: managePageUrl,
      LinkText: `Property - ${assessmentLabel} - ${masterPropertyId}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.CommunityProperty,
      RecordType_ENUM: RECORDTYPE.CommunityProperty_Unknown,
      Record_ID: masterPropertyId,
    },
  ];

  const listPanelBar: IPropertyPanelBar[] = [
    {
      title: "Actions and Workflows",
      component: (
        <PropertyActions
          id={masterPropertyId}
          recordType={RECORDTYPE.CommunityProperty_Master_Property}
          componentNumber={eComponent.Master_Property}
        />
      ),
      accordionType: eAccordionType.Actions_And_Workflows,
    },
    {
      title: "Related",
      component: (
        <PropertyAssociations
          id={masterPropertyId}
          componentNumber={eComponent.Master_Property}
        />
      ),
    },
    {
      title: "Comments",
      component: (
        <PropertyComments
          id={masterPropertyId}
          recordType={RECORDTYPE.CommunityProperty_Master_Property}
        />
      ),
    },
    {
      title: "Assessments",
      component: <MasterPropertyAssessments />,
    },
    {
      title: "Attributes",
      component: <MasterPropertyAttributes />,
    },
    {
      title: "Valuations",
      component: <MasterPropertiesValuations />,
    },
    {
      title: "Documents",
      component: (
        <PropertyDocuments
          componentId={masterPropertyId}
          componentNumber={eComponent.Master_Property}
        />
      ),
    },
  ];

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <MasterPropertyDetailTab /> },
      {
        title: "Map",
        component: (
          <MapTab address={masterProperty?.Property_Name_Address_Locality} />
        ),
      },
      {
        title: "Related",
        component: <MasterPropertyAssociationsTab />,
      },
      {
        title: "History",
        component: (
          <PropertyHistoryTab
            recordType={RECORDTYPE.CommunityProperty_Master_Property}
          />
        ),
      },
    ],
  });

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="Master Property" />

      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadMasterProperty(masterPropertyId)}
        />
      ) : (
        <>
          <FormTitle
            title={`${
              masterProperty?.MP_Property_Address
                ? masterProperty?.MP_Property_Address + ", "
                : ""
            }Id: ${masterPropertyId}`}
          />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <AddToSupplementaryButton
                  componentNumber={eComponent.Master_Property}
                />
                <ModifyMasterPropertyButton />
              </CCNavButton>,
              <CCNavButton title={"Add"} type="sub">
                <AddActionWithParentButton
                  id={masterPropertyId}
                  recordType={RECORDTYPE.CommunityProperty_Master_Property}
                />
                <AddAttachmentPropertyButton
                  id={masterPropertyId}
                  recordType={RECORDTYPE.CommunityProperty_Master_Property}
                  documentCategoryTypeId={
                    eDocumentTypeCategory.Master_Property_Document
                  }
                />
                <AddCommentPropertyButton
                  id={masterPropertyId}
                  recordType={RECORDTYPE.CommunityProperty_Master_Property}
                />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {masterProperty && (
                  <CCGeneralPanel component={<MasterPropertyForm />} />
                )}
                <PropertyPanelBar
                  initialPanels={listPanelBar}
                  componentId={masterPropertyId}
                  componentNumber={eComponent.Master_Property}
                />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});

export default ExistedMasterProperties;

import { DirectDebitStatus } from "@app/products/direct-debit/model";
import { postDDSessionChangeStatus } from "@app/products/direct-debit/sessions/[id]/api";
import { DirectDebitSessionHeader } from "@app/products/direct-debit/sessions/model";
import {
  fetchApiByAlias,
  useFlexibleFetchData,
} from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import React, { useMemo, useState } from "react";

interface IDDSessionChangeStatusButtonProps {
  isDisabled?: boolean;
  statusUpdate?: DirectDebitStatus.Cancelled | DirectDebitStatus.Completed;
}
export const DDSessionChangeStatusButton = ({
  isDisabled = false,
  statusUpdate = DirectDebitStatus.Completed,
}: IDDSessionChangeStatusButtonProps) => {
  const { pushNotificationPortal } = useNotificationPortalStore();
  const { dataForm } = useFlexibleFormStore();
  const { cancelToken } = useCancelRequest();

  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false);

  const title = useMemo(() => {
    if (statusUpdate === DirectDebitStatus.Cancelled) {
      return "Cancel";
    }
    return "Complete";
  }, [statusUpdate]);

  const ddSessionCancelButtonSlider = useFlexibleFetchData({
    slides: [
      {
        fetch: ({ initialData }) => {
          const payload: DirectDebitSessionHeader = initialData;
          return postDDSessionChangeStatus(payload, cancelToken());
        },
        handleSuccess: async () => {
          setIsShowConfirmDialog(false);
          await fetchApiByAlias("manageDirectDebitSessionSlider");
          pushNotificationPortal({
            title: "Record successfully saved.",
            type: "success",
          });
        },
        handleError: ({ errorFromApi }) => {
          setIsShowConfirmDialog(false);
          pushNotificationPortal({
            title: "Record could not be saved.",
            type: "error",
            autoClose: false,
            description: errorFromApi.error,
          });
        },
      },
    ],
  });

  const handleChangeStatus = () => {
    ddSessionCancelButtonSlider.fetchApi({
      initialData: {
        ...dataForm?.GeneralForm,
        Status_Type_ENUM: statusUpdate,
      },
    });
  };

  return (
    <>
      <CCNavButton
        title={title}
        disabled={isDisabled}
        onClick={() => setIsShowConfirmDialog(true)}
      />
      {isShowConfirmDialog ? (
        <ConfirmDialog
          onClosePopup={() => {
            setIsShowConfirmDialog(false);
          }}
          title={"Confirmation"}
          onAsyncConfirm={handleChangeStatus}
          subMessage={`Are you sure you want to change status to ${title}?`}
          isLoadingYes={ddSessionCancelButtonSlider?.isFetching}
        />
      ) : null}
    </>
  );
};

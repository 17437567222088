import { DirectDebitSessionFormElement } from "@app/products/direct-debit/sessions/[id]/components/general/components/form-element/_index";
import { DirectDebitSessionHeader } from "@app/products/direct-debit/sessions/model";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const DirectDebitSessionForm = observer(() => {
  // #region STORE
  const { dataForm, setOnSubmitFlexible } = useFlexibleFormStore();
  // #endregion STORE

  // #region INITIAL_VALUES
  const directDebitSession = dataForm?.GeneralForm as DirectDebitSessionHeader;
  // #endregion INITIAL_VALUES

  return (
    <div className="cc-form">
      <Form
        initialValues={directDebitSession}
        key={JSON.stringify(directDebitSession)}
        render={(formRenderProps: FormRenderProps) => {
          setOnSubmitFlexible({
            formName: "GeneralForm",
            eventSubmit: formRenderProps.onSubmit,
          });
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={<DirectDebitSessionFormElement />}
            />
          );
        }}
      />
    </div>
  );
});

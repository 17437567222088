import { eventEmitter } from "@/App";
import { saveDocumentAsWebLink } from "@app/core/documents/api";
import { INVALID_WORKFLOW_DRAFT_ID } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import {
  Attachment,
  eDocumentTypeCategory,
  IWebLink,
  Svc_Attachment,
} from "@app/products/property/components/action-bar/button/add-attachment/model";
import { AddAttachmentPropertyDialog } from "@app/products/property/components/action-bar/dialog/add-attachment/_index";
import { saveAttachmentProperty } from "@app/products/property/components/action-bar/dialog/add-attachment/api";
import {
  eUploadMode,
  IAddAttachmentDialog,
} from "@app/products/property/components/action-bar/dialog/add-attachment/model";
import { convertToFileStreamEntry } from "@app/products/property/components/action-bar/dialog/add-attachment/util";
import {
  getChangeOfOwnershipDocumentTypes,
  getDocumentsList,
} from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/api";
import {
  colDocuments,
  colDocumentTypes,
} from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/config";
import {
  DocumentCategory,
  DocumentStepEventType,
} from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/constant";
import {
  DTO_Documents,
  DTO_DocumentType,
} from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/model";
import {
  deactivateAssessmentDocument,
  downloadAssessmentDocument,
  putDeactivateWeblink,
} from "@app/products/property/components/child-screen/documents/api";
import { DTO_Document } from "@app/products/property/components/child-screen/documents/model";
import { WorkflowTypes } from "@app/products/property/model";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { DownloadFile } from "@common/constants/downloadfile";
import { RECORDTYPE } from "@common/constants/recordtype";
import { APIResponseStatus } from "@common/constants/response-status";
import { useCallApiHandleLoadFailed } from "@common/hooks/useCallApiHandleLoadFailed";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLabel } from "@components/cc-label/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { FieldArray } from "@progress/kendo-react-form";
import { isNil } from "lodash";
import React, { useMemo, useState } from "react";

const nameOfDocuments = nameOfFactory<DTO_Document>();

export const DocumentsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const nameOfDocument = nameOfFactory<DTO_Documents>();
const nameOfDocumentType = nameOfFactory<DTO_DocumentType>();

const FormStepElement = ({
  formRenderProps,
  nameOf,
  options = {},
  localNotificationRef,
}: IFormStepElement) => {
  const {
    workflowDraftId = INVALID_WORKFLOW_DRAFT_ID,
    componentId = null,
    recordType = RECORDTYPE.CommunityProperty_Workflow, // Task 14291: Default RecordType for Document stepper
    isReadOnly = false,
    workflowType,
    subType,
    isFromActionList,
  } = options;
  const { valueGetter, onChange } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const documentSelected: DTO_Document[] =
    getFieldValue("_option.DocumentSelected") ?? [];
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] =
    useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isShowAttachmentDialog, setIsShowAttachmentDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const documentWillbeSent =
    getFieldValue(nameOfDocument("Document_Type_Id")) ?? [];
  const documentTypes = getFieldValue("_option.DocumentTypes");
  const isChangeOfOwnershipDocument =
    workflowType === WorkflowTypes.Change_Of_Ownership;
  const selectedDocumentType = getFieldValue("_option.SelectedDocumentType");

  //#region Handle get Documents to be produced ========/
  const { cancelToken } = useCancelRequest();
  const [isLoadingDocumentTypes, setIsLoadingDocumentTypes] =
    useState<boolean>(false);

  let errorComponentToBeProduced = useCallApiHandleLoadFailed({
    isActive: isChangeOfOwnershipDocument && !documentTypes,
    setIsLoading: setIsLoadingDocumentTypes,
    api: async () => getChangeOfOwnershipDocumentTypes(subType, cancelToken()),
    watch: [subType],
    handler: async (response: APIResponse<any> | undefined) => {
      let newDocumentTypes: DTO_DocumentType[] | undefined;
      let newDocumentWillbeSent = [...documentWillbeSent];

      newDocumentTypes = response?.data?.Document_Types ?? [];
      newDocumentTypes = newDocumentTypes?.map((item: DTO_DocumentType) => {
        if (isFromActionList) {
          const includeDocument = documentWillbeSent?.includes(
            item?.Document_Type_Id
          );
          return {
            ...item,
            InCluded: includeDocument ?? false,
          };
        } else {
          //Default value when create Document
          newDocumentWillbeSent.push(item?.Document_Type_Id);
          return {
            InCluded: true,
            ...item,
          };
        }
      });

      onChange(nameOf("_option.DocumentTypes"), { value: newDocumentTypes });
      onChange(nameOf("Document_Type_Id"), {
        value: newDocumentWillbeSent,
      });
    },
  });
  //#endregion Handle get Documents to be produced =====/

  //#region Handle get Documents ========/
  const [documentList, setDocumentList] = useState<DTO_Documents[]>([]);
  const [isLoadingDocument, setIsLoadingDocument] = useState<boolean>(false);

  let errorComponent = useCallApiHandleLoadFailed({
    isActive: !isNil(workflowDraftId),
    setIsLoading: setIsLoadingDocument,
    api: async () => getDocumentsList(workflowDraftId, cancelToken()),
    watch: [workflowDraftId],
    handler: async (response: APIResponse<any> | undefined) => {
      setDocumentList(response?.data?.Documents ?? []);
    },
    eventType: DocumentStepEventType.RefreshData,
  });
  //#endregion Handle get Documents =====/

  //#region Handle delete Documents ========/
  const handleDeleteFile = async (id: number) => {
    setIsDeleting(true);
    const response = await deactivateAssessmentDocument(id);
    setIsDeleting(false);
    if (isSuccessResponse(response)) {
      if (response.data?.DeactivateAttachmentResponse?.IsSuccess) {
        eventEmitter.emit(DocumentStepEventType.RefreshData);
        onChange(nameOf("_option.DocumentSelected"), { value: [] });
        localNotificationRef?.current?.pushNotification({
          title: "Document deleted successfully.",
          type: "success",
        });
      } else {
        localNotificationRef?.current?.pushNotification({
          autoClose: false,
          description:
            response.data?.DeactivateAttachmentResponse?.SuccessMessage ??
            response.statusText ??
            "Delete document failed.",
          type: "error",
        });
      }
    } else {
      localNotificationRef?.current?.pushNotification({
        autoClose: false,
        description: response.data.ErrorMessage ?? response.statusText,
        type: "error",
      });
    }
  };

  const handleDeleteWeblink = async (id: number) => {
    setIsDeleting(true);
    const response = await putDeactivateWeblink(id);
    setIsDeleting(false);
    if (
      isSuccessResponse(response) &&
      response.data?.ErrorStatus === IdentityPacketErrorStatus.Success
    ) {
      eventEmitter.emit(DocumentStepEventType.RefreshData);
      onChange(nameOf("_option.DocumentSelected"), { value: [] });
      localNotificationRef?.current?.pushNotification({
        title: "Document deleted successfully.",
        type: "success",
      });
    } else {
      localNotificationRef?.current?.pushNotification({
        autoClose: false,
        title: response.data?.Errors ?? "Delete document failed.",
        type: "error",
      });
    }
  };

  const handleOnDelete = async () => {
    const category: string | undefined = documentSelected[0]?.Category;
    const id: number | undefined = documentSelected[0]?.ID ?? 0;
    await (category === DocumentCategory.File
      ? handleDeleteFile(id)
      : handleDeleteWeblink(id));
    setShowConfirmDeleteDialog(false);
  };
  //#region Handle delete Documents ========/

  const columnFields = useMemo(
    () =>
      colDocuments.map((col: IColumnFields) => {
        if (col.field === nameOfDocuments("Title")) {
          col.handleOnClick = async (dataItem: DTO_Document) => {
            const response = await downloadAssessmentDocument(dataItem.ID);
            DownloadFile(
              response?.data ?? "",
              dataItem?.ContentType ?? "",
              dataItem?.FileName ?? "document"
            );
            if (isSuccessResponse(response)) {
              setShowConfirmDeleteDialog(false);
              localNotificationRef?.current?.pushNotification({
                title: "Document download successfully",
                type: "success",
              });
            } else {
              localNotificationRef?.current?.pushNotification({
                autoClose: false,
                description: response.data.Errors ?? response.statusText,
                type: "error",
              });
            }
          };
        }
        return col;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleOnSubmit = async (data: IAddAttachmentDialog) => {
    if (workflowDraftId) {
      setIsLoading(true);
      switch (data?.UploadMode) {
        case eUploadMode.UPLOAD_FILE:
          if (!isNil(data?.File)) {
            Promise.all(
              data?.File?.filter((item) => isNil(item.validationErrors))?.map(
                async (item) => {
                  return {
                    FileEntry: await convertToFileStreamEntry(item),
                    Title: data.Title ?? item?.name,
                    Attachment_ID: 0,
                  } as Attachment;
                }
              )
            ).then(async (attachments) => {
              const requestBody = {
                Workflow_Draft_Id: workflowDraftId,
                RecordType: recordType,
                AttachmentList: attachments,
                DocumentType: data?.Category?.Code ? +data.Category.Code : null,
                ComponentID: componentId ? parseInt(componentId) : componentId,
              } as Svc_Attachment;
              const response = await saveAttachmentProperty(requestBody);
              setIsLoading(false);
              if (isSuccessResponse(response)) {
                localNotificationRef?.current?.resetNotifications();
                localNotificationRef?.current?.pushNotification({
                  title: "Attachment created successfully",
                  type: "success",
                });
                //Refresh document grid after updated successfully
                eventEmitter.emit(DocumentStepEventType.RefreshData);
              } else {
                if (
                  response?.status === APIResponseStatus.EXCEEDED_SIZE_LIMIT
                ) {
                  localNotificationRef?.current?.pushNotification({
                    autoClose: false,
                    title: "Exceeded the specified size limit",
                    type: "error",
                  });
                } else {
                  localNotificationRef?.current?.pushNotification({
                    autoClose: false,
                    title:
                      response.data?.ErrorStatus ?? "Create attachment failed",
                    description: response.data?.Errors ?? response.statusText,
                    type: "error",
                  });
                }
              }
              setIsShowAttachmentDialog(false);
            });
          }
          break;
        //Don't have task and api to do
        case eUploadMode.UPLOAD_WEB_LINK:
          const requestBody = {
            WebLinkURI: data.WebLink,
            Tag: data?.Tag ?? "",
            WeblinkCategory_KWD: data?.WebLinkCategory?.Keyword_ID ?? null,
          } as IWebLink;
          const response = await saveDocumentAsWebLink(
            workflowDraftId,
            recordType,
            requestBody
          );
          setIsLoading(false);
          if (isSuccessResponse(response)) {
            if (
              response.data?.ErrorStatus === IdentityPacketErrorStatus.Success
            ) {
              localNotificationRef?.current?.resetNotifications();
              localNotificationRef?.current?.pushNotification({
                title: "Attachment created successfully",
                type: "success",
              });
              //Refresh document grid after success
              eventEmitter.emit(DocumentStepEventType.RefreshData);
            } else {
              localNotificationRef?.current?.pushNotification({
                autoClose: false,
                title: "Attachment could not be saved",
                description: response.data?.Errors,
                type: "error",
              });
            }
          } else {
            localNotificationRef?.current?.pushNotification({
              autoClose: false,
              title: response.statusText,
              type: "error",
            });
          }
          setIsShowAttachmentDialog(false);
          break;
      }
    }
  };

  const handleDocumentTypeInfoChange = (field: string, value: any) => {
    if (selectedDocumentType) {
      let newDocumentTypes =
        documentTypes?.map((documentType: any) => {
          if (
            documentType?.Document_Type_Id !==
            selectedDocumentType?.Document_Type_Id
          )
            return documentType;
          documentType[field] = value;
          return documentType;
        }) ?? [];
      let newSelectedDocumentType = newDocumentTypes?.find(
        (item: any) =>
          item?.Document_Type_Id === selectedDocumentType?.Document_Type_Id
      );
      onChange(nameOf("_option.DocumentTypes"), { value: newDocumentTypes });
      onChange(nameOf("_option.SelectedDocumentType"), {
        value: newSelectedDocumentType,
      });
      if (field === nameOfDocumentType("InCluded")) {
        let newDocumentWillbeSent = [...documentWillbeSent];
        if (value) {
          newDocumentWillbeSent.push(selectedDocumentType?.Document_Type_Id);
          onChange(nameOf("Document_Type_Id"), {
            value: newDocumentWillbeSent,
          });
        } else {
          const removeIndex = newDocumentWillbeSent.indexOf(
            selectedDocumentType?.Document_Type_Id
          );
          newDocumentWillbeSent.splice(removeIndex, 1);
          onChange(nameOf("Document_Type_Id"), {
            value: newDocumentWillbeSent,
          });
        }
      }
    }
  };

  return (
    <div className="cc-property-documents cc-field-group">
      <div className="cc-form-cols-1">
        {isChangeOfOwnershipDocument && (
          <div className="cc-field">
            <CCLabel title="Documents to be produced" />
            <CCGrid
              errorComponent={errorComponentToBeProduced}
              isLoading={isLoadingDocumentTypes}
              columnFields={colDocumentTypes}
              data={documentTypes ?? []}
              primaryField={nameOfDocumentType("Document_Type_Id")}
              selectableMode="single"
              selectedRows={
                selectedDocumentType ? [selectedDocumentType] : undefined
              }
              onSelectionChange={(dataItem) => {
                onChange(nameOf("_option.SelectedDocumentType"), {
                  value: dataItem?.[0] ?? undefined,
                });
              }}
              toolbar={
                <div className="cc-grid-tools-bar">
                  <Button
                    iconClass="fas fa-check text-success"
                    title="Included"
                    disabled={selectedDocumentType?.InCluded ?? true}
                    onClick={() => {
                      handleDocumentTypeInfoChange(
                        nameOfDocumentType("InCluded"),
                        true
                      );
                    }}
                  />
                  <Button
                    iconClass="fas fa-times text-danger"
                    title="Excluded"
                    disabled={!selectedDocumentType?.InCluded ?? true}
                    onClick={() => {
                      handleDocumentTypeInfoChange(
                        nameOfDocumentType("InCluded"),
                        false
                      );
                    }}
                  />
                </div>
              }
              readOnly={isReadOnly}
            />
          </div>
        )}
        <div className="cc-field">
          <CCGrid
            errorComponent={errorComponent}
            isLoading={isLoadingDocument}
            data={documentList}
            toolbar={
              <div className="cc-grid-tools-bar">
                <Button
                  type="button"
                  iconClass="fas fa-plus"
                  title="Add new attachment"
                  onClick={() => setIsShowAttachmentDialog(true)}
                  disabled={
                    !workflowDraftId ||
                    workflowDraftId === INVALID_WORKFLOW_DRAFT_ID
                  }
                />

                <Button
                  type="button"
                  iconClass="fas fa-minus"
                  title="Remove"
                  disabled={!documentSelected.length}
                  onClick={() => setShowConfirmDeleteDialog(true)}
                />
              </div>
            }
            readOnly={isReadOnly}
            selectableMode="single"
            primaryField={nameOfDocuments("ID")}
            selectedRows={documentSelected}
            onSelectionChange={(dataItem: DTO_Document[]) => {
              onChange(nameOf("_option.DocumentSelected"), {
                value: dataItem,
              });
            }}
            columnFields={columnFields}
            state={{
              sort: [{ field: nameOfDocuments("CreatedOn"), dir: "desc" }],
            }}
          />
        </div>
      </div>

      {isShowAttachmentDialog && (
        <AddAttachmentPropertyDialog
          onClose={() => setIsShowAttachmentDialog(false)}
          onSubmit={handleOnSubmit}
          isLoadingButton={isLoading}
          documentCategoryTypeId={eDocumentTypeCategory.Workflow_Document}
          workflowType={workflowType}
          subType={subType}
        />
      )}
      {showConfirmDeleteDialog && (
        <ConfirmDialog
          title="Confirmation"
          subMessage={"Are you sure you want to delete this record?"}
          isLoadingYes={isDeleting}
          onClosePopup={() => setShowConfirmDeleteDialog(false)}
          onAsyncConfirm={handleOnDelete}
        />
      )}
    </div>
  );
};

import { FormatNameAndAddressDialog } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/dialogs/format-name-and-address/_index";
import { fieldName } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/names/config";
import { DTO_Role } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/model";
import { SearchNameCombobox } from "@app/products/property/components/fields/search-name/_index";
import { loadNameDetail } from "@app/products/property/components/fields/search-name/api";
import {
  DTO_Entity,
  EAddNameMode,
} from "@app/products/property/components/fields/search-name/model";
import {
  ISearchNameProcessParams,
  handleSearchNameProcess,
} from "@app/products/property/components/fields/search-name/util";
import { SearchOptions } from "@app/products/property/components/fields/search-options/_index";
import { DTO_Workflow_CreateContact } from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/model";
import { UpdateContactDialog } from "@app/products/property/contacts-central-names/list/components/dialogs/update-contact/_index";
import { ResponseMessage } from "@app/products/property/model";
import { colNameContactStep } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/contacts/config";
import {
  DTO_Entity_Details,
  EDialogContact,
} from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/contacts/model";
import { useNewPICContactStepStoreContext } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/contacts/store";
import { validatorContact } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/contacts/util";
import {
  DTO_Contact,
  EKeysOfStepsNewPIC,
} from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/model";
import { isSuccessResponse } from "@common/apis/util";
import { ContactLookahead_JSON } from "@common/models/contact";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import "./_index.scss";

export const ContactsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={!props?.options?.isReadOnly ? validatorContact : undefined}
    />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    localNotificationRef,
    nameOf,
    options = {
      isReadOnly: false,
      assessmentId: null,
      contactId: null,
    },
  }: IFormStepElement) => {
    //store
    const { lovContact } = useNewPICContactStepStoreContext();
    const { valueGetter, onChange, errors } = formRenderProps;
    //state
    const [updateContactData, setUpdateContactData] = useState<
      DTO_Entity_Details | undefined
    >();
    const [showDialog, setShowDialog] = useState<EDialogContact | undefined>();

    //get value fields
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const nameData = getFieldValue(fieldName.Names) ?? [];
    const selectedName = getFieldValue("_option.SelectedName");
    const loading = getFieldValue("_option.Loading");

    const handleLoadNameDetail = async (value: any, mode: EAddNameMode) => {
      const listName = [...nameData];
      const response = await loadNameDetail(value?.Entity_Id);
      if (isSuccessResponse(response)) {
        const contactDetail = response?.data?.Entity_Name_Address;
        //get default role
        const rolePicManager = await lovContact?.Role?.find(
          (item: DTO_Role) => item.Role_Type_Id === 12 // Role_Type_Id: 12 - Role_Name: PIC Manager
        );
        const newContact: DTO_Entity_Details = {
          Entity_Id: value?.Entity_Id,
          Entity_Name_Address_Id: contactDetail?.Entity_Name_Address_Id,
          Role_Name: rolePicManager?.Role_Name ?? "",
          Name: contactDetail?.Name || value?.DisplayName,
          Address: contactDetail?.Full_Address,
          isnew: mode === EAddNameMode.New,
        };
        let newNames;
        //update new data names
        if (mode === EAddNameMode.Update) {
          newNames = listName?.map((item: DTO_Entity_Details) => {
            if (item?.Entity_Id === value?.Entity_Id) {
              return newContact;
            }
            return item;
          });
        } else {
          newNames = [newContact];
        }
        //process update field Formatted managers name and address
        handleChangeFormatted(newContact, "grid");
        onChange(nameOf(fieldName.Names), { value: newNames });
      } else {
        localNotificationRef?.current?.pushNotification({
          title: response?.error ?? "Name could not be added",
          type: "error",
          autoClose: false,
        });
      }
    };

    const checkExistedEntity = (entityId: number) => {
      const listName = [...nameData];
      return (
        listName &&
        listName?.some(
          (contactItem: DTO_Entity_Details) =>
            contactItem?.Entity_Id === entityId
        )
      );
    };

    /**
     * handle add Name in grid
     * @param value
     * @param mode "New" | "Update" | "Existing"
     */
    const handleAddName = async (value: any, mode: EAddNameMode) => {
      let existingProcess = undefined;
      const isExistingEntity =
        value?.Entity_Id &&
        mode === EAddNameMode.Existing &&
        checkExistedEntity(value?.Entity_Id);

      if (isExistingEntity) {
        existingProcess = () => {
          localNotificationRef?.current?.pushNotification({
            title: `${value?.DisplayName} already exists`,
            type: "warning",
          });
        };
      }

      const dataProcess: ISearchNameProcessParams = {
        data: value,
        handleLoadNameDetail: async (data: any) => {
          if (checkExistedEntity(data?.Entity_Id)) {
            localNotificationRef?.current?.pushNotification({
              title: `${data?.Name ?? data?.DisplayName} already exists`,
              type: "warning",
            });
          }
          await handleLoadNameDetail(data, mode);
        },
        existingProcess,
        notificationAction: {
          canNotAddName: (response) => {
            localNotificationRef?.current?.pushNotification({
              title: response?.error ?? "Name could not be added",
              type: "error",
              autoClose: false,
            });
          },
        },
        setLoading: (isLoading: boolean) => {
          options?.setIsLoadingStep(isLoading);
          onChange(nameOf("_option.Loading"), { value: isLoading });
        },
      };

      await handleSearchNameProcess(dataProcess);
    };

    /**
     * handle change formatted
     * @param values
     * @param mode : "dialog" | "grid"
     */
    const handleChangeFormatted = (values: any, mode: "dialog" | "grid") => {
      //this mode use when add new or update in grid Name
      if (mode === "grid") {
        let formattedNameAddress = "";
        const formatData: DTO_Entity_Details = values;
        //Format by Name and Address
        const listFormatted: string[] = [
          formatData?.Name ?? "",
          formatData?.Address ?? "",
        ];
        formattedNameAddress = listFormatted
          ?.filter((item: string) => item?.length)
          .join(`\r\n`);
        onChange(nameOf("Formatted_Name_Address"), {
          value: formattedNameAddress,
        });
        //this mode use when completing Format Name and Address dialog
      } else if (mode === "dialog") {
        const contactFields = [
          fieldName.Name,
          fieldName.AttentionOf,
          fieldName.CareOf,
          fieldName.Address,
          fieldName.Locality,
          fieldName.State,
          fieldName.Country,
          fieldName.Postcode,
          fieldName.DPID,
          fieldName.FormattedNameAddress,
          fieldName.NoticeId,
        ];
        //update new values just changed
        contactFields.forEach((field: string) => {
          onChange(`${EKeysOfStepsNewPIC.Contact}.${field}`, {
            value: values?.[field as keyof DTO_Contact],
          });
        });
      }
    };

    /**
     * handle remove name out grid
     */
    const handleRemoveName = () => {
      const listData = [...nameData];
      const contact = selectedName?.[0];
      //filter get the new list without Name selected
      if (contact) {
        let newNames = listData?.filter(
          (item: any) =>
            item[fieldName.PrimaryKey] !== contact[fieldName.PrimaryKey]
        );
        onChange(nameOf("_option.SelectedName"), { value: [] });
        onChange(nameOf(fieldName.Names), { value: newNames });
        const contactFields = [
          fieldName.Name,
          fieldName.AttentionOf,
          fieldName.CareOf,
          fieldName.Address,
          fieldName.Locality,
          fieldName.State,
          fieldName.Country,
          fieldName.Postcode,
          fieldName.DPID,
          fieldName.NoticeId,
        ];
        //reset data is empty
        contactFields.forEach((field: string) => {
          onChange(`${EKeysOfStepsNewPIC.Contact}.${field}`, {
            value: undefined,
          });
        });
        onChange(
          `${EKeysOfStepsNewPIC.Contact}.${fieldName.FormattedNameAddress}`,
          {
            value: "",
          }
        );
      }
    };

    /**
     * process name columns
     * add handle Onclick for Name column when updating names
     * @param cols
     * @returns
     */
    const processNamesColumn = (cols: IColumnFields[]) => {
      return cols.map((col: IColumnFields) => {
        //Do nothing while in readOnly mode
        if (col.field === fieldName.Name && !options?.isReadOnly) {
          col.handleOnClick = (dataItem: DTO_Entity_Details) => {
            onChange(nameOf("_option.SelectedName"), {
              value: [dataItem],
            });
            //data for dialog update name
            setUpdateContactData(dataItem);
          };
        }
        return col;
      });
    };

    /**
     * handle data change name
     * using for edit mode
     * field update: Role => default PIC Manager
     * @param dataRow
     * @param valueChange
     */
    const handleDataChangeName = async (
      dataRow: DTO_Entity_Details,
      valueChange: string
    ) => {
      const contactId = dataRow.Entity_Id;
      let newNames = [...nameData];
      //update new list
      newNames = await newNames.map((item: DTO_Entity_Details) => {
        if (item.Entity_Id === contactId && valueChange === "Role_Name") {
          return { ...item, Role_Name: dataRow[valueChange] ?? null };
        }
        return item;
      });
      onChange(nameOf(fieldName.Names), { value: newNames });
    };

    const handleSubmitNewContactDialog = (
      _payload: DTO_Workflow_CreateContact,
      responseMessage: ResponseMessage
    ) => {
      if (responseMessage?.IsSuccess && responseMessage?.Component_ID) {
        handleAddName(
          { Entity_Id: responseMessage.Component_ID } as DTO_Entity,
          EAddNameMode.New
        );
      } else {
        localNotificationRef?.current?.pushNotification({
          title: responseMessage?.ErrorMessage ?? "Name could not be added",
          type: "error",
          autoClose: false,
        });
      }
    };

    return (
      <>
        <section className="cc-field-group cc-new-pic-contacts-step">
          <div className="cc-form-cols-2">
            <SearchNameCombobox
              name={nameOf(fieldName.SearchName)}
              onSelectionChange={(values: ContactLookahead_JSON) => {
                handleAddName(values, EAddNameMode.Existing);
              }}
              disabled={options?.isReadOnly}
              selectedSearchOptions={getFieldValue("SearchOptions")}
              onError={(error: any) => {
                localNotificationRef?.current?.pushNotification({
                  type: "error",
                  title: error ?? "Search name error",
                  autoClose: false,
                });
              }}
              onSubmitNewContactDialog={handleSubmitNewContactDialog}
            />
            <SearchOptions
              formRenderProps={formRenderProps}
              nameOf={nameOf}
              isDisable={options?.isReadOnly}
            />
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">
                Names
                <CCTooltip type="validator" position="right" />
                {errors?.[nameOf("")] ? (
                  <Error>{errors?.[nameOf("")]}</Error>
                ) : null}
              </label>
              <CCGrid
                data={nameData || []}
                columnFields={processNamesColumn(colNameContactStep)}
                isLoading={loading}
                readOnly={options?.isReadOnly}
                selectableMode="single"
                onSelectionChange={(dataItem: DTO_Entity_Details[]) => {
                  //Do nothing while in readOnly mode
                  if (options?.isReadOnly) return;
                  onChange(nameOf("_option.SelectedName"), {
                    value: dataItem,
                  });
                }}
                primaryField={"Entity_Id"}
                toolbar={
                  !options?.isReadOnly ? (
                    <div className="cc-grid-tools-bar">
                      <Button
                        iconClass="fas fa-minus"
                        title="Remove one contact"
                        disabled={selectedName?.length !== 1}
                        onClick={handleRemoveName}
                      />
                    </div>
                  ) : null
                }
                onDataRowChange={handleDataChangeName}
                editableMode={!options?.isReadOnly ? "cell" : undefined}
              />
            </div>
            <div className="cc-field">
              <div className="cc-label-control-between">
                <label className="cc-label">
                  Formatted managers name and address
                </label>
                <Button
                  fillMode="flat"
                  themeColor="primary"
                  className={"cc-edit-field-button"}
                  onClick={() => {
                    setShowDialog(EDialogContact.Formatted);
                  }}
                  title="Edit formatted managers name and address"
                  iconClass="fa fa-edit"
                  disabled={options?.isReadOnly}
                />
              </div>
              <Field
                name={nameOf(fieldName.FormattedNameAddress)}
                rows={4}
                readOnly
                component={CCTextArea}
              />
            </div>
          </div>
        </section>
        {showDialog === EDialogContact.Formatted ? (
          <FormatNameAndAddressDialog
            onClose={() => {
              setShowDialog(undefined);
            }}
            onSubmit={(data) => {
              handleChangeFormatted(data, "dialog");
              setShowDialog(undefined);
            }}
            initialValues={getFieldValue("")}
            noticeGroupData={lovContact?.NoticeGroup ?? []}
          />
        ) : null}
        {updateContactData ? (
          <UpdateContactDialog
            entityId={updateContactData?.Entity_Id}
            onClose={() => {
              setUpdateContactData(undefined);
            }}
            handleSubmitDialog={(_values, response) => {
              if (response?.IsSuccess) {
                handleAddName(
                  { Entity_Id: updateContactData?.Entity_Id } as DTO_Entity,
                  EAddNameMode.Update
                );
              } else {
                localNotificationRef?.current?.pushNotification({
                  title: response?.ErrorMessage ?? "Name could not be updated",
                  type: "error",
                  autoClose: false,
                });
              }
              setUpdateContactData(undefined);
            }}
            isSaveOnNextStep
          />
        ) : null}
      </>
    );
  }
);

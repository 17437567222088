import { isSuccessResponse } from "@common/apis/util";

export const errorPromiseAllResponse = (apiResult: any[]) => {
  return apiResult.find((v) => !isSuccessResponse(v));
};

export const runInSync = (runFunction: Function) => {
  return new Promise((resolve) => {
    const result = runFunction();
    resolve(result);
  });
};

import { DirectDebitMenu } from "@app/products/direct-debit/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const ddSystemAdminRoute: ICCRoute = {
  path: "system-admin",
  name: "System Admin",
  enum: DirectDebitMenu.SystemsAdmin,
  children: [
    {
      path: "module-item-types",
      name: "Module Item Types",
      enum: DirectDebitMenu.ModuleItemTypes,
      component: require("./module-item-types/_index").default,
    },
    {
      path: "groups",
      name: "Groups",
      enum: DirectDebitMenu.Groups,
      component: require("./groups/_index").default,
      children: [
        {
          path: ":id(new|[0-9]+)",
          component: require("./groups/[id]/_index").default,
        },
      ],
    },
    {
      path: "status-codes",
      name: "Status Codes",
      enum: DirectDebitMenu.StatusCodes,
      component: require("./status-codes/_index").default,
      children: [
        {
          path: ":id(new|[0-9]+)",
          component: require("./status-codes/[id]/_index").default,
        },
      ],
    },
    {
      path: "bank-accounts",
      name: "Bank Accounts",
      enum: DirectDebitMenu.BankAccounts,
      component: require("./bank-accounts/_index").default,
      children: [
        {
          path: ":id(new|[0-9]+)",
          component: require("./bank-accounts/[id]/_index").default,
        },
      ],
    },
  ],
};

import { validateBSB } from "@app/products/direct-debit/system-admin/bank-accounts/[id]/components/general/components/form-element/direct-debits/util";
import { DirectDebitBankAccount } from "@app/products/direct-debit/system-admin/model";
import { stringLengthValidator } from "@app/products/direct-debit/system-admin/util";
import { nameOfFactory } from "@common/utils/common";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCMaskedTextBox } from "@components/cc-masked-textbox/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import React from "react";

interface IDDBankAccountDirectDebitsSectionProps {
  isInactive?: boolean;
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<DirectDebitBankAccount>();

export const DDBankAccountDirectDebitsSection = ({
  isInactive,
  formRenderProps,
}: IDDBankAccountDirectDebitsSectionProps) => {
  const { valueGetter } = formRenderProps;

  const getValue = (nameOfField: keyof DirectDebitBankAccount) =>
    valueGetter(nameOf(nameOfField));

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <div className="cc-field">
          <CCLabel title="Bank code" isMandatory />
          <Field
            name={nameOf("Direct_Debit_Bank_Code")}
            component={CCInput}
            placeholder="Bank code"
            disabled={isInactive}
            validator={(value) => stringLengthValidator(value, 1, 10)}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="Account name" isMandatory />
          <Field
            name={nameOf("Direct_Debit_Bank_Acc_Name")}
            component={CCInput}
            placeholder="Account name"
            disabled={isInactive}
            validator={(value) => stringLengthValidator(value, 1, 50)}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="BSB" isMandatory />
          <Field
            name={nameOf("Direct_Debit_Bank_BSB")}
            component={CCMaskedTextBox}
            mask="000-000"
            placeholder="BSB"
            disabled={isInactive}
            validator={validateBSB}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="Account number" isMandatory />
          <Field
            name={nameOf("Direct_Debit_Bank_Acc_No")}
            component={CCInput}
            placeholder="Account number"
            disabled={isInactive}
            validator={(value) => stringLengthValidator(value, 1, 20)}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="Security name" />
          <Field
            name={nameOf("Direct_Debit_Security_Name")}
            component={CCInput}
            placeholder="Security name"
            disabled={isInactive}
            validator={(value) => stringLengthValidator(value, 0, 20)}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="Security number" />
          <Field
            name={nameOf("Direct_Debit_Security_No")}
            component={CCInput}
            placeholder="Security number"
            disabled={isInactive}
            validator={(value) => stringLengthValidator(value, 0, 10)}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="Balancing line required" />
          <Field
            name={nameOf("Direct_Debit_Balancing_Line")}
            component={CCSwitch}
            disabled={isInactive}
            checked={getValue("Direct_Debit_Balancing_Line")}
          />
        </div>
      </div>
    </section>
  );
};

import { DirectDebitGroup } from "@app/products/direct-debit/system-admin/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { IdentityPacket } from "@common/models/identityPacket";

export const postSaveDirectDebitGroup = async (
  directDebitGroup: DirectDebitGroup
): Promise<APIResponse<IdentityPacket> | undefined> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      "api/receipting/internal/directdebitgroup",
      directDebitGroup
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const deleteDirectDebitGroup = async (
  data: Svc_RecordDetails[]
): Promise<APIResponse<IdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().delete(
      `api/receipting/internal/directdebitgroup/delete`,
      {
        data,
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

interface Svc_RecordDetails {
  ID: number;
  RecordType_ENUM: RECORDTYPE;
}

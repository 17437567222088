import { AuthorisationsHistoryTab } from "@app/products/direct-debit/authorisations/[id]/components/slidebar/history/_index";
import { colDirectDebitAuthorisation } from "@app/products/direct-debit/authorisations/config";
import { DirectDebitAuthorisation } from "@app/products/direct-debit/authorisations/model";
import { getDirectDebitAuthorisationsOdataByMenuType } from "@app/products/direct-debit/constant";
import { DirectDebitMenu } from "@app/products/direct-debit/model";
import { directDebitRoute } from "@app/products/direct-debit/route";
import { nameOfFactory } from "@common/utils/common";
import { CCActionBarNavDropdownNew } from "@components/cc-list-view-action-bar/components/nav-dropdown-new/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";

const nameOf = nameOfFactory<DirectDebitAuthorisation>();

export default () => {
  useCCListViewActionBar({
    title: directDebitRoute.name,
    leftComponents: [<CCActionBarNavDropdownNew />],
    centerComponents: [<CCNavButton title="New" />],
    rightComponents: [
      // <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      // <ListViewBookmarkIcon
      //   linkUrl={DIRECT_DEBIT_AUTHORISATIONS}
      //   productType={PRODUCT_TYPE.DirectDebit}
      //   recordType={RECORDTYPE.Receipting_DirectDebitAuthorisation}
      //   detail={DDAuthorisationsBookmark.getBookmarkListViewDisplayName}
      //   displayName={
      //     DDAuthorisationsBookmark.getBookmarkListViewDetailRecord
      //   }
      //   listViewDetail={() => DDAuthorisationsBookmark.getBookmarkListViewDetail("By Contact")}
      //   listViewDisplayName={
      //     DDAuthorisationsBookmark.getBookmarkListViewDisplayName
      //   }
      // />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "History",
        component: <AuthorisationsHistoryTab />,
      },
    ],
  });

  return (
    <CCProductListView
      columnFields={colDirectDebitAuthorisation}
      dataUrl={getDirectDebitAuthorisationsOdataByMenuType(
        DirectDebitMenu.AuthorisationsByContact
      )}
      primaryField={nameOf("Authorisation_Id")}
      state={{
        group: [{ field: nameOf("Authorised_By_Name"), dir: "asc" }],
        sort: [{ field: nameOf("Authorisation_Id"), dir: "desc" }],
      }}
    />
  );
};

import { eventEmitter } from "@/App";
import { THandleCatchResponse } from "@common/apis/model";
import { handleCatchResponse, isSuccessResponse } from "@common/apis/util";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import {
  IUseFlexibleFetchData,
  TApiProps,
} from "@common/hooks/flexible-fetch-data/model";
import { flexibleFetchDataStoreInstance } from "@common/hooks/flexible-fetch-data/store";
import { errorPromiseAllResponse } from "@common/hooks/flexible-fetch-data/util";
import { customLogger } from "@common/utils/logger";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { isArray } from "lodash";
import React, { useCallback, useMemo, useRef, useState } from "react";

export const fetchApiByAlias = async (alias: string, data?: TApiProps) => {
  eventEmitter.emit(alias, data);
  await flexibleFetchDataStoreInstance?.waitingFetch;
  return flexibleFetchDataStoreInstance.isSuccess;
};

export const useFlexibleFetchData = ({
  slides,
  isActive = true,
  alias,
}: IUseFlexibleFetchData) => {
  const initialDataRef = useRef<any>();
  const paramRef = useRef<any>();

  const [isFetching, setIsFetching] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [errorContent, setErrorContent] = useState<{
    initialData: any;
    errorFromApi: THandleCatchResponse;
  }>();

  useAddUniqueEventEmitter([
    {
      eventType: alias ?? "NoEvent",
      listener: (data?: TApiProps) => {
        fetchApi(data);
      },
    },
  ]);

  const fetchApi = useCallback(
    async (props?: TApiProps) => {
      if (!isActive || isFetching) return;
      //#region handle waiting fetchDataByAlias ========/
      alias && flexibleFetchDataStoreInstance?.resetWaitingFetch();
      //#endregion handle waiting fetchDataByAlias =====/
      const { initialData } = props || {};
      if (initialData) {
        initialDataRef.current = initialData;
      }

      let index = 0;
      for (const slide of slides) {
        let apiError = null;
        let apiResult = null;
        try {
          setErrorContent(undefined);
          setIsFetching(true);
          const dataFetch = {
            dataFromExtractParam: paramRef.current,
            initialData: initialDataRef.current,
          };
          if (isArray(slide.fetch)) {
            apiResult = await Promise.all(
              slide.fetch.map((fetchItem) => fetchItem(dataFetch))
            );
            apiError = errorPromiseAllResponse(apiResult);
          } else {
            apiResult = await slide.fetch(dataFetch);
            if (!isSuccessResponse(apiResult)) {
              apiError = apiResult;
            }
          }
          //#region Handle Error ========/
          if (apiError) {
            setIsFetching(false);
            //#region handle waiting fetchDataByAlias ========/
            flexibleFetchDataStoreInstance?.handleNext();
            flexibleFetchDataStoreInstance.setIsSuccess(false);
            //#endregion handle waiting fetchDataByAlias =====/

            const error = {
              initialData: initialDataRef.current,
              errorFromApi: handleCatchResponse(apiError),
            };
            setErrorContent(error);

            if (slide.handleError) {
              slide.handleError(error);
            }
            break;
          }
          //#endregion Handle Error =====/

          //#region Handle Success ========/
          const dataSuccess = {
            dataFromApi: apiResult,
            dataFromExtractParam: paramRef.current,
            initialData: initialDataRef.current,
          };

          if (slide.extractParamForNextAPI) {
            paramRef.current = slide.extractParamForNextAPI(dataSuccess);
          }
          if (slide.handleSuccess) {
            setIsFetching(false);
            const runSlides = await slide.handleSuccess(dataSuccess);
            if (runSlides === false) {
              break;
            }
          }
          //#endregion Handle Success =====/

          //#region Handle return status ========/
          if (index === slides.length - 1) {
            setIsDone(true);
            setIsFetching(false);
            //#region handle waiting fetchDataByAlias ========/
            flexibleFetchDataStoreInstance?.handleNext();
            flexibleFetchDataStoreInstance.setIsSuccess(true);
            //#endregion handle waiting fetchDataByAlias =====/
          }
          //#endregion Handle return status =====/
        } catch (err) {
          customLogger("useFlexibleFetchData").error(err);
          setIsFetching(false);
          break;
        }
        index++;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isActive, slides]
  );

  let errorComponent: JSX.Element | null = null;
  errorComponent = useMemo(() => {
    if (errorContent?.errorFromApi) {
      return (
        <CCLoadFailed
          key={JSON.stringify(errorContent.errorFromApi)}
          responseError={errorContent.errorFromApi}
          onReload={() => fetchApi(initialDataRef.current)}
          isLoadingButton={isFetching}
        />
      );
    }
    return null;
  }, [errorContent, isFetching, fetchApi]);

  return {
    fetchApi,
    isFetching,
    errorComponent,
    errorResponse: errorContent?.errorFromApi,
    isDone,
  };
};

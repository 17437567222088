import { DBRowState } from "@app/products/crms/[id]/model";
import { DirectDebitGroup } from "@app/products/direct-debit/system-admin/model";
import { flexibleFormStoreInstance } from "@common/stores/flexible-form/store";

export const checkIsInactiveDirectDebitGroup = (): boolean => {
  const data = flexibleFormStoreInstance?.dataForm
    ?.GeneralForm as DirectDebitGroup;
  return (data?.Sys_DBRowState as DBRowState) === DBRowState.Inactive;
};

export const generateBadgeDBRowState = (initStatus: DBRowState) => {
  switch (initStatus) {
    case DBRowState.Active:
      return DBRowState[DBRowState.Active];
    case DBRowState.Inactive:
      return DBRowState[DBRowState.Inactive];
    case DBRowState.Archive:
      return DBRowState[DBRowState.Archive];
    default:
      return undefined;
  }
};

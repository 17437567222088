import { DirectDebitMenu } from "@app/products/direct-debit/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const ddAccountsRoute: ICCRoute = {
  path: "accounts",
  name: "Accounts",
  enum: DirectDebitMenu.Accounts,
  children: [
    {
      path: ":id([0-9]+)",
      component: require("./[id]/_index").default,
    },
    {
      path: "by-account",
      name: "By Account",
      enum: DirectDebitMenu.AccountsByAccount,
      component: require("./by-account/_index").default,
    },
    {
      path: "by-contact",
      name: "By Contact",
      enum: DirectDebitMenu.AccountsByContact,
      component: require("./by-contact/_index").default,
    },
    {
      path: "by-module-type",
      name: "By Module Type",
      enum: DirectDebitMenu.AccountsByModuleType,
      component: require("./by-module-type/_index").default,
    },
    {
      path: "by-status",
      name: "By Status",
      enum: DirectDebitMenu.AccountsByStatus,
      component: require("./by-status/_index").default,
    },
    {
      path: "vip",
      name: "VIP",
      enum: DirectDebitMenu.AccountsVIP,
      component: require("./vip/_index").default,
    },
  ],
};

import { DIRECT_DEBIT_BANK_ACCOUNTS } from "@app/products/direct-debit/constant";
import { DBRowStateWithoutArchive } from "@app/products/direct-debit/model";
import { DBRowStateCustomCell } from "@app/products/direct-debit/system-admin/components/db-row-state-custom-cell/_index";
import { DirectDebitBankAccountView } from "@app/products/direct-debit/system-admin/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DirectDebitBankAccountView>();

export const colDirectDebitBankAccount: IColumnFields[] = [
  {
    field: nameOf("Description"),
    title: "Description",
    locked: true,
    linkTo: (dataItem: DirectDebitBankAccountView) => {
      return (
        `${DIRECT_DEBIT_BANK_ACCOUNTS}/` + dataItem.Direct_Debit_Bank_Account_Id
      );
    },
  },
  {
    field: nameOf("Direct_Debit_Bank_Acc_Name"),
    title: "Account Name",
  },
  { field: nameOf("Direct_Debit_Bank_BSB"), title: "BSB" },
  {
    field: nameOf("Direct_Debit_Bank_Acc_No"),
    title: "Account Number",
  },
  {
    field: nameOf("Sys_DBRowState"),
    title: "Status",
    filterByEnum: DBRowStateWithoutArchive,
    cell: DBRowStateCustomCell,
  },
];

import { ElementDisplayStatus } from "@app/products/crms/[id]/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";

export const CRMSFieldMapping = {
  RefNo: "RefNo",
  Description: "Description",
  DateTarget: "DateTarget",
  Source: "Source",
  Category: "Category",
  Location: "Location",
  RequestedBy: "RequestedBy",
  ContactDetails: "ContactDetails",
  Status: "Status",
  DueStatus: "DueStatus",
  DateRecorded: "DateRecorded",
  RecordedBy: "RecordedBy",
  OrgStructure: "OrgStructure",
  ActionOfficer: "ActionOfficer",
  Coordinator: "Coordinator",
  DateWork: "DateWork",
  FileNumber: "FileNumber",
  EnquiryRefNo: "EnquiryRefNo",
  Event_ReferenceNoAdditional: "Event_ReferenceNoAdditional",
  OnBehalfOf: "OnBehalfOf",
  Flag_ITSupport: "Flag_ITSupport",
  Flag_Safety: "Flag_Safety",
  Flag_Risk: "Flag_Risk",
  Flag_VIP: "Flag_VIP",
  AckTargetDate: "AckTargetDate",
  DateAck: "DateAck",
  Ward: "Ward",
  ContactPostal: "ContactPostal",
  ContactEmail: "ContactEmail",
  ResentCount: "ResentCount",
  DateRiskDOB: "DateRiskDOB",
  WSR_Errors: "WSR_Errors",
  Flag_AssetHasAsbestos: "Flag_AssetHasAsbestos",
  Flag_AssetIsLeased: "Flag_AssetIsLeased",
  AssetEnquiryRefNo: "AssetEnquiryRefNo",
  Location_Suburb: "Location_Suburb",
  CollectionDate: "CollectionDate",
  DocSentDate: "DocSentDate",
  CollectionItems: "CollectionItems",
  NumItems: "NumItems",
  AdminOverride: "AdminOverride",
  Collected: "Collected",
  Missed: "Missed",
  AgentName: "AgentName",
  QualityCheckItems: "QualityCheckItems",
  SSSubCat: "SSSubCat",
  Title: "Title",
  DateReceived: "DateReceived",
  Worker: "Worker",
  WorkerContactDetails: "WorkerContactDetails",
  Priority: "Priority",
  ServiceProvidedBy: "ServiceProvidedBy",
  Workstream_Display: "Workstream_Display",
  Cancelled: "Cancelled",
  RecordedByAddress: "RecordedByAddress",
  OnBehalfOf_DisplayName: "OnBehalfOf_DisplayName",
  OnBehalfOf_Email: "OnBehalfOf_Email",
  OnBehalfOf_WorkPhone: "OnBehalfOf_WorkPhone",
  VIP: "VIP",
  CollectionMonthName: "CollectionMonthName",
  CollectionDay: "CollectionDay",
  MaxCollections: "MaxCollections",
  AreaCode: "AreaCode",
  NumCollections: "NumCollections",
  Message: "Message",
  Contact: "Contact",
  DateSent: "DateSent",
  Sender: "Sender",
  DateForwarded: "DateForwarded",
  DateCompleted: "DateCompleted",
  Recipient: "Recipient",
  ContactType: "ContactType",
  PreferredMethod: "PreferredMethod",
  WorkPhone: "WorkPhone",
  MobileNo: "MobileNo",
  HomePhone: "HomePhone",
  Email: "Email",
  DateInspected: "DateInspected",
  InspectionType: "InspectionType",
  Reason: "Reason",
  Compliance: "Compliance",
  ReferenceNumber: "ReferenceNumber",
  Officer: "Officer",
  Address: "Address",
  Area: "Area",
  DateServed: "DateServed",
  Type: "Type",
  DateDue: "DateDue",
  DateRevoked: "DateRevoked",
  PhoneNo: "PhoneNo",
  FaxNo: "FaxNo",
  Manager: "Manager",
  Reviewer: "Reviewer",
  ExpiryDate: "ExpiryDate",
  NextReviewDate: "NextReviewDate",
  Released: "Released",
  External: "External",
  HitCount: "HitCount",
  AlwaysDisplay: "AlwaysDisplay",
  LinkArticle: "LinkArticle",
  AlertType: "AlertType",
  StartDate: "StartDate",
  EndDate: "EndDate",
  Tag: "Tag",
  SubCategory: "SubCategory",
  Name: "Name",
  Classification: "Classification",
  CategoryCode: "CategoryCode",
  DisplayAsCRMSChoice: "DisplayAsCRMSChoice",
  DisplayAsInternetChoice: "DisplayAsInternetChoice",
  Flag_MobileChoice: "Flag_MobileChoice",
  Flag_Payroll: "Flag_Payroll",
  Flag_System: "Flag_System",
  Flag_ExternalUser: "Flag_ExternalUser",
  ServiceStandardType_Name: "ServiceStandardType_Name",
  IsAnimal: "IsAnimal",
  IsHealth: "IsHealth",
  HasWasteWater: "HasWasteWater",
  LookupTableType_Name: "LookupTableType_Name",
  SortIndex: "SortIndex",
  LookupTable_EnumeratedValue_Name: "LookupTable_EnumeratedValue_Name",
  RosterType: "RosterType",
  RosterName: "RosterName",
  StreetNo: "StreetNo",
  StreetName: "StreetName",
  Suburb: "Suburb",
  Councillor: "Councillor",
  Org1: "Org1",
  Requestor: "Requestor",
  Confidentiality: "Confidentiality",
  RequestorType: "RequestorType",
  Position: "Position",
  OnBeHalfOf: "OnBeHalfOf",
  LocationType: "LocationType",
  LocationDetails: "LocationDetails",
  Date_DOB: "Date_DOB",
};
export class CRMSEvent {
  // LOAD BY DEFAULT
  id?: string | number = "";
  ID?: string | number = "";
  Target?: string = "";
  Source?: string = "";
  Location?: any | string = "";
  Contact?: string = "";
  ContactInfo?: string = "";
  Status?: string = "";
  Status_ENUM?: number;

  DueStatus?: string;
  Recorded?: string;
  RecordedBy?: string;
  OrgStructure?: string;
  ActionOfficer?: string = "";
  Coordinator?: string = "";
  WorkDate?: string = "";
  AssetsRefNo?: string;
  AddRefNo?: string = "";
  OnbehalfOf?: string;

  ItSupport?: boolean | false;
  Safety?: boolean = false;
  Risk?: boolean = false;
  Vip?: boolean = false;
  RefNo?: number;
  Description?: string = "";

  Type?: string = "";
}

export const crmsSettingEnum = [
  ECorporateSettingsField.CorporateSettings_CategoriesGridPageSize,
  ECorporateSettingsField.CUSTOMERSERVICE_ServiceStandard_EnableQuickClose,
  ECorporateSettingsField.CUSTOMERSERVICE_Event_AllowRecategorise,
  ECorporateSettingsField.CUSTOMERSERVICE_ServiceStandardCategoryLevels,
  ECorporateSettingsField.CUSTOMERSERVICE_KnowledgeBase_URL,
  ECorporateSettingsField.CorporateSettings_SiteBaseURL,
  ECorporateSettingsField.CUSTOMERSERVICE_Event_CustomFlag1_Workflow,
  ECorporateSettingsField.CUSTOMERSERVICE_Event_CustomFlag2_Workflow,
  ECorporateSettingsField.CUSTOMERSERVICE_Event_CustomFlag3_Workflow,
  ECorporateSettingsField.CUSTOMERSERVICE_Event_SendNotificationInBatchClose,
  ECorporateSettingsField.CUSTOMERSERVICE_Common_ActionOfficerLabel,
  ECorporateSettingsField.CUSTOMERSERVICE_Common_CoordinatorLabel,
  ECorporateSettingsField.CUSTOMERSERVICE_Common_SendToActionOfficerLabel,
  ECorporateSettingsField.CorporateSettings_LabelOrg1,
  ECorporateSettingsField.CUSTOMERSERVICE_Event_CouncillorViewMode,
  ECorporateSettingsField.CUSTOMERSERVICE_CouncillorMyEventsDisplayClosed,
  ECorporateSettingsField.CUSTOMERSERVICE_CouncillorRequests_ShowRelatedEventsTab,
  ECorporateSettingsField.CUSTOMERSERVICE_CouncillorRequests_ShowActions,
  ECorporateSettingsField.CUSTOMERSERVICE_CouncillorRequests_ShowRecords,
  ECorporateSettingsField.CUSTOMERSERVICE_CouncillorRequests_ShowComments,
  ECorporateSettingsField.CUSTOMERSERVICE_SimpleEvent_HelpURL,
  ECorporateSettingsField.CUSTOMERSERVICE_ServiceStandard_Response1Caption,
  ECorporateSettingsField.CUSTOMERSERVICE_ServiceStandard_Response2Caption,
  ECorporateSettingsField.CUSTOMERSERVICE_ServiceStandard_Response3Caption,
  ECorporateSettingsField.CUSTOMERSERVICE_CouncillorRequests_MandatoryOBO,
  ECorporateSettingsField.CUSTOMERSERVICE_AllowReallocateOfficer,
  ECorporateSettingsField.CUSTOMERSERVICE_Event_CommentOnReassign,
  ECorporateSettingsField.CUSTOMERSERVICE_Event_CommentOnRecategorise,
  ECorporateSettingsField.CUSTOMERSERVICE_ServiceStandardSubCategoryLevel3Caption,
  ECorporateSettingsField.CUSTOMERSERVICE_ServiceStandardSubCategoryLevel3Mandatory,
  ECorporateSettingsField.CUSTOMERSERVICE_ServiceStandardSubCategoryLevel4Caption,
  ECorporateSettingsField.CUSTOMERSERVICE_ServiceStandardSubCategoryLevel4Mandatory,
  ECorporateSettingsField.CUSTOMERSERVICE_Event_RestrictAllocateOfficerOrgStructure,
  ECorporateSettingsField.CUSTOMERSERVICE_Common_ProductName,
  ECorporateSettingsField.CorporateSettings_PNFSystemLable,
  ECorporateSettingsField.CUSTOMERSERVICE_Event_OnSendToActionSendEmailToRequestor,
  ECorporateSettingsField.CUSTOMERSERVICE_Event_OnReSendToActionSendEmailToRequestor,
  ECorporateSettingsField.CUSTOMERSERVICE_Event_OnSendToActionSendSMSToRequestor,
  ECorporateSettingsField.CUSTOMERSERVICE_Event_OnReSendToActionSendSMSToRequestor,
  ECorporateSettingsField.CUSTOMERSERVICE_Event_OnSendToActionSendEmailToOnBehalfOf,
  ECorporateSettingsField.CUSTOMERSERVICE_Event_OnReSendToActionSendEmailToOnBehalfOf,
  ECorporateSettingsField.CUSTOMERSERVICE_Event_OnClosureSendSMSToRequestor,
  ECorporateSettingsField.CUSTOMERSERVICE_Security_ConfidentialEnableAddAttachment,
  ECorporateSettingsField.CUSTOMERSERVICE_EnableComplaintHandling,
  ECorporateSettingsField.CUSTOMERSERVICE_SourceType_CorrespondenceAcknowledgementRequired,
  ECorporateSettingsField.CUSTOMERSERVICE_Event_AllowReopen,
  ECorporateSettingsField.CorporateSettings_AssetsSystem,
  ECorporateSettingsField.CUSTOMERSERVICE_ServiceStandard_EnableResponses,
  ECorporateSettingsField.CorporateSettings_UsePNFSystem,
  ECorporateSettingsField.CUSTOMERSERVICE_Event_Tier4Option,
  ECorporateSettingsField.HealthManager_3StarComplianceLabel,
  ECorporateSettingsField.CorporateSettings_EnableNoticeStatusList,
  ECorporateSettingsField.CorporateSettings_CalculateInfringementTimesPenaltyUnits,
  ECorporateSettingsField.CorporateSettings_InfringementUnitAmount,
  ECorporateSettingsField.CorporateSettings_EnableRoundedInfringementAmount,
  ECorporateSettingsField.CorporateSettings_NoticeNumberingMethod,
  ECorporateSettingsField.CUSTOMERSERVICE_Event_ManualRemovalOfAutoAction,
  ECorporateSettingsField.CUSTOMERSERVICE_SendForAction_CreateWorkflowActions,
  ECorporateSettingsField.CUSTOMERSERVICE_Event_RecentEventsShowOpenEventsOnly,
  ECorporateSettingsField.CUSTOMERSERVICE_Event_RecentEventsFilterDays,
  ECorporateSettingsField.CUSTOMERSERVICE_Event_ShowNewRequestSameIssueButton,
  ECorporateSettingsField.CUSTOMERSERVICE_Records_SharePoint_DocumentLibrary,
  ECorporateSettingsField.SharePoint_URL,
  ECorporateSettingsField.CUSTOMERSERVICE_Views_PhoneMessages,
];

export enum CRMSViews {
  Unspecified = 0,
  Unresolved_MyEvents = 1,
  Unresolved_MyCoordinatorEvents = 2,
  Unresolved_ByActionOfficer = 3,
  Unresolved_ByCoordinator = 4,
  Unresolved_ByRefNo = 5,
  Unresolved_ByCategory = 6,
  Unresolved_ByStreetName = 7,
  Unresolved_ByDueStatus = 8,
  Unresolved_ByOrgStructure = 9,
  Unresolved_ByAssignedToOrgStructure = 10,
  Unresolved_ByRecordedBy = 11,
  Unresolved_ByWorkDate = 12,
  Unresolved_BySpecialInterest = 13,
  Unresolved_AcknowledgementRequired = 14,
  Unresolved_Resent = 15,
  Unresolved_Drafts = 16,
  Unresolved_Safety = 17,
  Unresolved_VIP = 18,
  Unresolved_Risk = 19,
  Unresolved_CSAdmin = 20,
  Unresolved_ByAsset = 21,
  Unresolved_Assets = 22,
  Unresolved_HardRubbish = 23,
  Unresolved_AfterHoursService = 24,
  Unresolved_QualityCheck = 25,
  Unresolved_Incident = 26,
  Unresolved_ByStatus = 27,
  Unresolved_BySuburb = 28,
  Unresolved_ByRecipient = 29,
  Unresolved_MyEvents_DWCMS = 30,
  Unresolved_ByActionOfficer_DWCMS = 31,
  Unresolved_ByRefNo_DWCMS = 32,
  Unresolved_ByCategory_DWCMS = 33,
  Unresolved_Drafts_DWCMS = 34,
  Unresolved_ByTier4 = 35,
  UnreadMailMessagingEvents = 36,
  All_ByActionOfficer = 101,
  All_ByCoordinator = 102,
  All_ByRefNo = 103,
  All_ByCategory = 104,
  All_ByStreetName = 105,
  All_ByStatus = 106,
  All_ByOrgStructure = 107,
  All_ByRecordedBy = 108,
  All_MyCoordinatorEvents = 109,
  All_ByAsset = 110,
  All_Assets = 111,
  All_HardRubbish = 112,
  All_AfterHoursService = 113,
  All_QualityCheck = 114,
  All_Incident = 115,
  All_BySuburb = 116,
  All_Safety = 117,
  All_VIP = 118,
  All_ByActionOfficer_DWCMS = 119,
  All_ByRefNo_DWCMS = 120,
  All_ByCategory_DWCMS = 121,
  All_MyEvents_DWCMS = 122,
  All_Risk = 123,
  UnreadMailMessagingAllEvents = 124,
  Councillor_UnresolvedByCouncillor = 201,
  Councillor_AllByCouncillor = 202,
  Councillor_AllByOrg1 = 203,
  Councillor_AllByWard = 204,
  Councillor_UnresolvedMyEvents = 205,
  ExternalUser_UnresolvedByActionOfficer = 251,
  ExternalUser_AllByActionOfficer = 252,
  ExternalUser_AllByRefNo = 253,
  ExternalUser_UnresolvedByRefNo = 254,
  HardRubbish_NotCollected = 301,
  HardRubbish_AllCollections = 302,
  HardRubbish_AllEvents = 303,
  HardRubbish_CollectionLookup = 304,
  ITSupport_UnallocatedEvents = 401,
  ITSupport_AllEvents = 402,
  ITSupport_UnresolvedEvents = 403,
  Payroll_AllEvents = 451,
  Payroll_UnresolvedEvents = 452,
  Payroll_MyUnresolvedEvents = 453,
  Payroll_Drafts = 454,
  Payroll_AllMyEvents = 455,
  Payroll_MyDrafts = 456,
  KB_Published = 501,
  KB_PublishedByOrgStructure = 502,
  KB_Unreleased = 503,
  KB_DueForReview = 504,
  KB_UnderReview = 505,
  KB_RequiresAdminApproval = 506,
  KB_Expired = 507,
  KB_Drafts = 508,
  KB_NoServiceStandards = 509,
  KB_Alerts = 510,
  KB_Topics = 511,
  ServiceStandard_ByActionOfficer = 601,
  ServiceStandard_ByCategory = 602,
  ServiceStandard_ByClassification = 603,
  ServiceStandard_ByCoordinator = 604,
  ServiceStandard_ByName = 605,
  ServiceStandard_ByOrgStructure = 606,
  ServiceStandard_DeactivatedServiceStandards = 607,
  ServiceStandard_DeactivatedOrgs = 608,
  Admin_Categories = 701,
  Admin_LocationRegister = 702,
  Admin_Rosters = 703,
  Admin_LookupTables = 711,
  Admin_SystemSettings = 712,
  PhoneMessages_All = 705,
  PhoneMessages_Open = 706,
  Inspections_ByDate = 901,
  Inspections_ByType = 902,
  Inspections_ByOfficer = 903,
  Inspections_ByMonth = 904,
  Notices_ByDate = 905,
  Notices_ByDueStatus = 906,
  OpenInvestigationsByDate = 907,
  OpenInvestigationsByInvestigator = 908,
  OpenInvestigationsByStatus = 909,
  OpenInvestigationsBySource = 910,
  InvestigationsByDate = 911,
  InvestigationsByInvestigator = 912,
  InvestigationsByStatus = 913,
  InvestigationsBySource = 914,
}

export interface ViewActionButton {
  FormAction: FormAction;
  Label: string;
  DisplayStatus: ElementDisplayStatus;
}

export enum FormAction {
  CORE_ALLOW_NEW = 0,
  CORE_ALLOW_MAILMERGE = 1,
  CORE_ALLOW_EXPORT = 2,
  CORE_ALLOW_CLOSE = 3,
  CORE_ALLOW_EDIT = 4,
  CORE_ALLOW_CANCEL = 5,
  CORE_ALLOW_SAVE = 6,
  CORE_ALLOW_DELETE = 7,
  CORE_TOOLBAR_ADD = 8,
  CORE_TOOLBAR_ADD_COMMENT = 9,
  CORE_TOOLBAR_ADD_ACTION = 10,
  CORE_TOOLBAR_ADD_CONTACT = 11,
  CORE_TOOLBAR_ADD_DOCUMENT = 12,
  CORE_TOOLBAR_ADD_ATTACHMENT = 13,
  CORE_TOOLBAR_ADD_FEE = 14,
  CORE_TOOLBAR_ADD_PAYMENT = 15,
  CORE_TOOLBAR_ADD_COMPLAINT = 16,
  CORE_TOOLBAR_ADD_INSPECTION = 17,
  CORE_TOOLBAR_ADD_NOTICE = 18,
  CORE_TOOLBAR_ADD_PROSECUTION = 19,
  CORE_TOOLBAR_ADD_ADDITIONALCONTACT = 20,
  CORE_TOOLBAR_ADD_VCATREVIEW = 21,
  CORE_TOOLBAR_ADD_PROSECUTIONCHARGE = 22,
  CORE_DELETE_COMMENT = 23,
  CORE_DELETE_ACTION = 24,
  CORE_DELETE_ATTACHMENT = 25,
  CORE_DELETE_FEE = 26,
  CORE_DELETE_PAYMENT = 27,
  CORE_DELETE_COMPLAINT = 28,
  CORE_DELETE_INSPECTION = 29,
  CORE_DELETE_NOTICE = 30,
  CORE_DELETE_PROSECUTION = 31,
  CORE_DELETE_ADDITIONALCONTACT = 32,
  CORE_DELETE_VCATREVIEW = 33,
  CORE_DELETE_PROSECUTIONCHARGE = 34,
  CORE_DELETE_ACTIONPLAN = 35,
  CORE_DELETE_NCITEMRESULT = 36,
  CORE_DELETE_ADVERTISING = 37,
  CORE_DELETE_FURTHERINFO = 38,
  CORE_DELETE_APPEAL = 39,
  CORE_DELETE_CHECKLIST = 40,
  CORE_TOOLBAR_ADD_FURTHERINFO = 41,
  CORE_TOOLBAR_ADD_APPEAL = 42,
  CORE_TOOLBAR_ADD_ADVERTISING = 43,
  CORE_TOOLBAR_ADD_REGISTER = 44,
  CORE_DELETE_REGISTER = 45,
  CORE_TOOLBAR_ADD_COMMUNICATION = 46,
  CORE_DELETE_SAMPLE = 47,
  CORE_DELETE_SAMPLETEST = 48,
  CORE_DELETE_RECALL = 49,
  CORE_TOOLBAR_ADD_SAMPLE = 50,
  CORE_TOOLBAR_ADD_RECALL = 51,
  CORE_TRIM_ADMIN = 52,
  CORE_TOOLBAR_ADMIN = 53,
  CORE_TOOLBAR_PRINT = 54,
  CORE_ALLOW_RESTORE = 55,
  CORE_INTEGRATEDREPORTS_NEWREPORT = 56,
  CORE_INTEGRATEDREPORTS_NEWDASHBOARD = 57,
  CORE_INTEGRATEDREPORTS_REFRESHDATACACHE = 58,
  CORE_HOMEPAGE_SEARCH = 59,
  CORE_ALLOW_BATCH_CHANGEINSPECTIONTYPE = 60,
  CORE_TRIM_MENU = 61,
  CORE_RENAME_ATTACHMENT = 62,
  CORE_ALLOW_FINANCE_EXPORT = 63,
  CORE_ALLOW_FINANCE_IMPORT = 64,
  CORE_CHANGESTATUS_ATTACHMENT = 65,
  CORE_ALLOW_ACTIVATE = 66,
  CORE_TOOLBAR_ADD_BOND = 67,
  CORE_TOOLBAR_ADD_CONDITION = 68,
  CORE_DELETE_INTERVIEW = 69,
  CORE_TOOLBAR_ADD_ADDITIONALADDRESS = 70,
  CORE_TOOLBAR_ADD_MEETING = 71,
  CORE_TOOLBAR_ADD_REFERRAL = 72,
  CORE_ADD_DRIVERLICENCE = 73,
  CORE_DASHBOARD_REPORTS = 101,
  CORE_DASHBOARD_CONTACTS = 102,
  CORE_DASHBOARD_ACTIONS = 103,
  CORE_DASHBOARD_CORPORATEADMIN = 104,
  CORE_DASHBOARD_KNOWLEDGEBASE = 105,
  CORE_DASHBOARD_HEALTHMANAGER = 106,
  CORE_DASHBOARD_WASTEWATER = 107,
  CORE_DASHBOARD_HOMEPAGE = 108,
  CORE_DASHBOARD_CUSTOMERSERVICE = 109,
  CORE_DASHBOARD_CRS = 110,
  CORE_DASHBOARD_DISRES = 111,
  CORE_DASHBOARD_MCH = 112,
  CORE_DASHBOARD_TOWNPLANNING = 113,
  CORE_DASHBOARD_DEVAPPS = 114,
  CORE_DASHBOARD_BUILDING = 115,
  CORE_DASHBOARD_LLPERMITS = 116,
  CORE_DASHBOARD_ANIMALS = 117,
  CORE_DASHBOARD_WASTEWATERSPI = 118,
  CORE_DASHBOARD_CSM = 119,
  CORE_DASHBOARD_STREETPROTECTION = 120,
  CORE_DASHBOARD_AMS_APPOINTMENTBOOKING = 121,
  CORE_DASHBOARD_EMS_FACILITYBOOKING = 122,
  CORE_DASHBOARD_PHONEMESSAGE = 123,
  CORE_DASHBOARD_CRSCENTREACCESS = 124,
  CORE_CONTACTRELATION_MODIFYNAMES = 125,
  CORE_CONTACTRELATION_MODIFYCONTACTINFO = 126,
  CORE_CONTACTRELATION_MODIFYCONTACTADDRESS = 127,
  CORE_DASHBOARD_TRAININGMANAGER = 128,
  CORE_DASHBOARD_NSPR = 129,
  CORE_DASHBOARD_PROJECT = 130,
  CORE_DASHBOARD_RADIATION = 131,
  CORE_DASHBOARD_COMPLAINTS = 132,
  CORE_DASHBOARD_ASSET = 133,
  CORE_DASHBOARD_PROPERTY = 134,
  CORE_DASHBOARD_PAYROLL = 135,
  CORE_DASHBOARD_RECEIPTING = 136,
  CORE_DASHBOARD_HR = 137,
  CORE_DASHBOARD_GIS = 138,
  CORE_DASHBOARD_INFRINGEMENTS = 139,
  CORE_DASHBOARD_SSMS = 140,
  CORE_DASHBOARD_COUNCILONLINE = 141,
  CORE_DASHBOARD_RECORDS = 142,
  CORE_DASHBOARD_OTHERSYSTEMS = 143,
  CORE_DASHBOARD_SRU = 144,
  CORE_DASHBOARD_HAZARD = 145,
  CORE_DASHBOARD_CEM = 146,
  CORE_DASHBOARD_DWCMS = 147,
  CORE_COMPLAINT_EDIT_TARGETDATE = 148,
  CORE_TOOLBAR_ADD_MATTER = 149,
  CORE_COMPLAINT_CLOSE = 150,
  CORE_DASHBOARD_FLEETBOOKING = 151,
  CORE_DASHBOARD_LANDMANAGEMENT = 152,
  CORE_DASHBOARD_PNF = 153,
  CORE_DASHBOARD_Advertising = 154,
  CORE_DASHBOARD_COMMUNITYASSET = 155,
  CORE_DASHBOARD_COMMUNITYPROPERTY = 156,
  CORE_DELETE_FLEETBookings = 157,
  CONTACTMANAGER_ALLOW_CHANGENAME = 5001,
  CONTACTMANAGER_ALLOW_NEW_RELATIONSHIP = 5002,
  CONTACTMANAGER_ALLOW_CHANGEADDRESS = 5003,
  CONTACTMANAGER_ALLOW_PERSONALINFO = 5004,
  CONTACTMANAGER_ALLOW_MERGE = 5005,
  CORPADMIN_ALLOW_SORT = 6001,
  CORPADMIN_MENU_COMMUNICATIONS = 6002,
  CORPADMIN_MENU_SECURITY = 6003,
  CORPADMIN_MENU_SETTINGS = 6004,
  ACTIONS_MENU_ALLACTIONS = 6005,
  ACTIONS_MENU_OUTSTANDINGACTIONS = 6006,
  CORPADMIN_HOMEPAGE_SETTING = 6007,
  TELEPHONE_ACTIVITY_HOMEPAGE_WORKBENCH = 6008,
  ACTIONS_MENU_MYACTIONS = 6009,
  CORE_ACTION_EDIT_RECORDEDBY = 6010,
  CRS_FORM_REGISTRATION_ALLOW_PROGRESSWORKFLOW = 8001,
  CRS_FORM_CENTRE_ALLOW_ADDROOM = 8002,
  CRS_FORM_CENTRE_ALLOW_ADDGROUP = 8003,
  CRS_FORM_CENTRE_ALLOW_EDITCHARACTERISTICS = 8004,
  CRS_ALLOW_NEW_HBCREGISTRATION = 8005,
  CRS_ALLOW_NEW_CBCREGISTRATION = 8006,
  CRS_ALLOW_NEW_3yoREGISTRATION = 8007,
  CRS_ALLOW_NEW_4yoREGISTRATION = 8008,
  CRS_ALLOW_CHANGESTATUTORYINFO = 8009,
  CRS_FORM_CENTRE_ALLOW_CBC = 8010,
  CRS_FORM_CENTRE_ALLOW_HBC = 8011,
  CRS_FORM_CENTRE_ALLOW_3yoKINDER = 8012,
  CRS_FORM_CENTRE_ALLOW_4yoKINDER = 8013,
  CRS_FORM_CENTRE_ALLOW_ADDSESSION = 8014,
  CRS_FORM_REGISTRATION_ALLOW_ARCHIVE = 8015,
  CRS_FORM_REGISTRATION_ALLOW_REMOVEDUPLICATE = 8016,
  CRS_VIEW_ALLOW_SEARCH = 8017,
  CRS_FORM_CENTREGROUP_ALLOW_DELETEGROUP = 8018,
  CRS_FORM_CENTREGROUP_ALLOW_WAITLIST = 8019,
  CRS_FORM_CENTRE_ALLOW_ADDCENTRE = 8020,
  CRS_FORM_ALLOW_CHANGEDEBTOR = 8021,
  CRS_FORM_ALLOW_CHANGEGROUPSSELECTED3YO = 8022,
  CRS_FORM_ALLOW_CHANGEGROUPSSELECTED4YO = 8023,
  CRS_FORM_WORKFLOW_BUTTONS_CBC = 8024,
  CRS_FORM_WORKFLOW_BUTTONS_HBC = 8025,
  CRS_FORM_WORKFLOW_BUTTONS_3YO = 8026,
  CRS_FORM_WORKFLOW_BUTTONS_4YO = 8027,
  CRS_FORM_REGISTRATION_ALLOW_LODGE = 8028,
  CRS_FORM_REGISTRATION_ALLOW_REMOVE_PARENT2 = 8029,
  CRS_FORM_PORTALMANAGEMENT_WORKFLOW_BUTTONS = 8030,
  CRS_FORM_SIBLINGS_MENU = 8031,
  CRS_FORM_3YO_REGISTRATION_TOOLBAR_ADD_FEE = 8032,
  CRS_FORM_3YO_REGISTRATION_TOOLBAR_ADD_REFUND = 8033,
  CRS_FORM_4YO_REGISTRATION_TOOLBAR_ADD_FEE = 8034,
  CRS_FORM_4YO_REGISTRATION_TOOLBAR_ADD_REFUND = 8035,
  CRS_FORM_REMOVEDUPLICATEAPPLICATION_MENU = 8036,
  CM_FORM_CANCEL_ORDER = 9001,
  CM_FORM_VALIDATE_ORDER = 9002,
  CM_FORM_PARTICULARS_ACCEPTED = 9003,
  CM_FORM_RECEIVED_AT_CREM = 9004,
  CM_FORM_COMPLETE_ORDER = 9005,
  CM_FORM_CALCULATE_FEES = 9006,
  CM_FORM_CONFIRM_ORDER = 9007,
  CM_FORM_PLACED_AT_SITE = 9008,
  CM_FORM_RECEIVED_AT_ADMIN = 9009,
  CM_FORM_LEFT_BUILDING = 9010,
  CM_FORM_PHOTO_TAKEN = 9011,
  CM_FORM_DOC_VERIFIED = 9012,
  CM_FORM_CREMATION_IN_PROGRESS = 9013,
  CM_FORM_IN_RADIO_STORAGE = 9014,
  CM_FORM_READY_FOR_HANDLING = 9015,
  CM_FORM_UPDATE_INTERMENT = 9016,
  CM_FORM_ADD_DECEASED_PERSON = 9017,
  CM_FORM_SITE_PREPARED = 9018,
  CM_FORM_SITE_SETUP = 9019,
  CM_FORM_REMOVE_DECEASED = 9020,
  CM_FORM_CONFIRM_DECEASED = 9021,
  CM_FORM_ADD_CREM_REM = 9022,
  CM_FORM_SCHEDULE_INTERMENT = 9023,
  CM_FORM_RETRIEVED_REMAINS = 9024,
  CM_FORM_RESCHEDULE_INTERMENT = 9026,
  CM_FORM_REMOVE_CREM_REM = 9027,
  CM_FORM_DOC_INCOMPLETE = 9028,
  CM_FORM_LEFT_ADMIN = 9029,
  CM_FORM_LEFT_CREM = 9030,
  CM_FORM_DETAILS_VERIFIED = 9031,
  CM_FORM_LICENCE_IS_VALID = 9032,
  CM_FORM_REMAINS_PACKAGED = 9033,
  CM_FORM_PICKED_UP_BY_FD = 9035,
  CM_FORM_DROPPED_OFF_BY_FD = 9036,
  CM_FORM_REMAINS_DELIVERED = 9037,
  CM_FORM_REMAINS_UNDELIVERED = 9038,
  CM_FORM_RECEIVED_AT_CREM_ITEM = 9039,
  CM_POS_FINALISEPAYMENT = 9040,
  CM_FORM_SCHEDULE_APPOINTMENT = 9041,
  CM_FORM_SCHEDULE_BOOKING_INTERMENT = 9042,
  CM_FORM_SCHEDULE_BOOKING_CREMATION = 9043,
  CM_FORM_SCHEDULE_CRIO = 9044,
  CM_FORM_CONFIRM_CRRO = 9045,
  CM_VIEW_CANCEL_LICENCE_CA = 9046,
  CM_VIEW_CANCEL_LICENCE_HO = 9047,
  CM_FORM_SCHEDULE_MEMORIALONLYSERVICE = 9048,
  CM_FORM_CONFIRM_MEMORIALONLYSERVICE = 9049,
  CM_FORM_RENEW_LICENCE_CA = 9050,
  CM_MENU_VIEW_APPOINTMENT = 10201,
  CM_MENU_VIEW_BOOKINGS = 10202,
  CM_MENU_VIEW_CONSULTATIONS = 10203,
  CM_MENU_VIEW_CREMATED_REMAINS = 10204,
  CM_MENU_VIEW_FUNERAL_PLANS = 10205,
  CM_MENU_VIEW_INTERMENTS = 10206,
  CM_MENU_VIEW_LICENCES = 10207,
  CM_MENU_VIEW_LICENCE_GROUPS = 10208,
  CM_MENU_VIEW_MONUMENTS = 10209,
  CM_MENU_VIEW_ROSE_AREAS = 10210,
  CM_MENU_VIEW_ROSE_VARIETIES = 10211,
  CM_MENU_VIEW_SECTIONS = 10212,
  CM_MENU_VIEW_SITES = 10213,
  CM_MENU_VIEW_SUBSECTIONS = 10214,
  CM_MENU_VIEW_SUBMITTED_BOOKINGS = 10215,
  CM_MENU_VIEW_COMPLAINTS = 10216,
  CM_MENU_PERSONS_ALL_PERSONS = 10217,
  CM_MENU_PERSONS_DECEASED_PERSONS = 10218,
  CM_MENU_TRANSACTIONS_BURIAL_INTERMENTS = 10219,
  CM_MENU_TRANSACTIONS_EXUMATIONS = 10220,
  CM_MENU_TRANSACTIONS_NEW_LICENCES = 10221,
  CM_MENU_TRANSACTIONS_RENEW_LICENCES = 10222,
  CM_MENU_TRANSACTIONS_SURRENDER_LICENCES = 10223,
  CM_MENU_TRANSACTIONS_TRANSFER_LICENCES = 10224,
  CM_MENU_CREMATIONS = 10225,
  CM_MENU_CREM_ORDER_ITEMS = 10226,
  CM_MENU_CREM_REM_INTERMENTS = 10227,
  CM_MENU_CREM_REM_REMOVALS = 10228,
  CM_MENU_REC_OUTSIDE_CREM_REM = 10229,
  CM_MENU_ALL_ORDERS = 10230,
  CM_MENU_HEADSTONES = 10231,
  CM_MENU_PLAQUES = 10232,
  CM_MENU_OTHER_PARTS = 10233,
  CM_MENU_RENEWAL_NOTICES = 10234,
  CM_MENU_FIRST_EXPIRED_NOTICES = 10235,
  CM_MENU_SECOND_EXPIRED_NOTICES = 10236,
  CM_MENU_CANCELLATIONS = 10237,
  CM_MENU_EXPORT_DEBTORS = 10238,
  CM_MENU_EXPORT_TRANSACTIONS = 10239,
  CM_MENU_IMPORT_DEBTORS = 10240,
  CM_MENU_IMPORT_INVENTORY = 10241,
  CM_MENU_IMPORT_TRANSACTIONS = 10242,
  CM_MENU_SALES = 10243,
  CM_MENU_CP_USERS = 10244,
  CM_MENU_FEE_SETUP = 10245,
  CM_MENU_INVENTORY = 10246,
  CM_MENU_LICENCE_CATEGORY = 10247,
  CM_MENU_MONUMENT_PARTS = 10248,
  CM_MENU_OPTIONS = 10249,
  CM_MENU_PREPAID_FEES = 10250,
  CM_MENU_CHANGE_PASSWORD = 10251,
  CM_MENU_PRODUCTREPORTS = 10252,
  CM_MENU_INTERNALBOOKINGS = 10253,
  CM_MENU_CALENDAR_NOTES = 10254,
  CM_MENU_ADDITIONAL_FEES = 10255,
  CM_MENU_RENEWALS_SENT = 10256,
  CM_MENU_HOMEPAGE = 10257,
  CM_MENU_ACCOUNTSRECEIVABLE_FEES = 10258,
  CRS_MENU_VIEW_APPPLICATION = 10301,
  CRS_MENU_VIEW_CENTREBASEDCARE = 10302,
  CRS_MENU_VIEW_HOMEBASEDCARE = 10303,
  CRS_MENU_VIEW_THREEYOKINDER = 10304,
  CRS_MENU_VIEW_FOURYOKINDER = 10305,
  CRS_MENU_VIEW_CENTRES = 10306,
  CRS_MENU_VIEW_ADMIN = 10307,
  CRS_MENU_VIEW_CHANGEPASSWORD = 10308,
  CRS_MENU_VIEW_INFOWORKFLOW = 10309,
  CRS_MENU_VIEW_FEE = 10310,
  CRS_MENU_VIEW_PORTAL = 10311,
  CRS_MENU_VIEW_ALLOCATION = 10312,
  CRS_MENU_VIEW_POA = 10313,
  CRS_MENU_VIEW_EXPORT = 10314,
  CRS_MENU_VIEW_COMMUNICATION = 10315,
  CUSTOMERSERVICE_MENU_ALLEVENTS = 10401,
  CUSTOMERSERVICE_MENU_SERVICESTANDARD = 10402,
  CUSTOMERSERVICE_MENU_VIEW_CATEGORIES = 10403,
  CUSTOMERSERVICE_MENU_VIEW_LOOKUPTABLES = 10404,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDASSETEVENTS = 10405,
  CUSTOMERSERVICE_MENU_VIEW_ALLASSETEVENTS = 10406,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDAFTERHOURSSERVICEEVENTS = 10407,
  CUSTOMERSERVICE_MENU_VIEW_ALLAFTERHOURSSERVICEEVENTS = 10408,
  CUSTOMERSERVICE_MENU_VIEW_ALLMYCOORDINATOREVENTS = 10409,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDMYCOORDINATOREVENTS = 10410,
  CUSTOMERSERVICE_MENU_UNRESOLVEDEVENTS = 10411,
  CUSTOMERSERVICE_MENU_VIEW_SERVICESTANDARDBYACTIONOFFICER = 10413,
  CUSTOMERSERVICE_MENU_VIEW_SERVICESTANDARDBYCATEGORY = 10414,
  CUSTOMERSERVICE_MENU_VIEW_SERVICESTANDARDBYCLASSIFICATION = 10415,
  CUSTOMERSERVICE_MENU_VIEW_SERVICESTANDARDBYCOORDINATOR = 10416,
  CUSTOMERSERVICE_MENU_VIEW_SERVICESTANDARDBYNAME = 10417,
  CUSTOMERSERVICE_MENU_VIEW_SERVICESTANDARDBYORGSTRUCTURE = 10418,
  CUSTOMERSERVICE_MENU_VIEW_SERVICESTANDARDDEACTIVATEDORGS = 10419,
  CUSTOMERSERVICE_MENU_VIEW_ALLEVENTSBYACTIONOFFICER = 10420,
  CUSTOMERSERVICE_MENU_VIEW_ALLEVENTSBYACTIONCOORDINATOR = 10421,
  CUSTOMERSERVICE_MENU_VIEW_ALLEVENTSBYREFNO = 10422,
  CUSTOMERSERVICE_MENU_VIEW_ALLEVENTSBYCATEGORY = 10423,
  CUSTOMERSERVICE_MENU_VIEW_ALLEVENTSBYSTREETNAME = 10424,
  CUSTOMERSERVICE_MENU_VIEW_ALLEVENTSBYSTATUS = 10425,
  CUSTOMERSERVICE_MENU_VIEW_ALLEVENTSBYORGSTRUCTURE = 10426,
  CUSTOMERSERVICE_MENU_VIEW_ALLEVENTSBYRECORDEDBY = 10427,
  CUSTOMERSERVICE_MENU_VIEW_ALLEVENTSHARDRUBBISHCRMS = 10428,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDEVENTSMYEVENTS = 10429,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDEVENTSBYACTIONOFFICER = 10430,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDEVENTSBYACTIONCOORDINATOR = 10431,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDEVENTSBYREFNO = 10432,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDEVENTSBYCATEGORY = 10433,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDEVENTSBYSTREETNAME = 10434,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDEVENTSBYDUESTATUS = 10435,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDEVENTSBYSTATUS = 10436,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDEVENTSBYORGSTRUCTURE = 10437,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDEVENTSBYRECORDEDBY = 10438,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDEVENTSBYWORKDATE = 10439,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDEVENTSHARDRUBBISHCRMS = 10440,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDEVENTSAFTERHOURSSERVICE = 10441,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDINCIDENTS = 10442,
  CUSTOMERSERVICE_MENU_VIEW_ALLINCIDENTS = 10443,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDEVENTSBYASSIGNEDTOORGSTRUCTURE = 10444,
  CUSTOMERSERVICE_MENU_VIEW_DEACTIVATEDSERVICESTANDARDS = 10445,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDQUALITYCHECKBYITEM = 10446,
  CUSTOMERSERVICE_MENU_VIEW_ALLQUALITYCHECKBYITEM = 10447,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDEVENTSBYASSET = 10448,
  CUSTOMERSERVICE_MENU_VIEW_ALLEVENTSBYASSET = 10449,
  CUSTOMERSERVICE_MENU_VIEW_ALLEVENTSBYSUBURB = 10450,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDEVENTSBYSUBURB = 10451,
  CUSTOMERSERVICE_MENU_VIEW_ALLEVENTSVIP = 10452,
  CUSTOMERSERVICE_MENU_VIEW_ALLEVENTSRISK = 10453,
  CUSTOMERSERVICE_MENU_VIEW_ALLEVENTSSAFE = 10454,
  CUSTOMERSERVICE_MENU_VIEW_ALLEVENTSUNREADMAILs = 10455,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDEVENTSUNREADMAILs = 10456,
  CUSTOMERSERVICE_MENU_ADMIN = 10470,
  CUSTOMERSERVICE_MENU_SYSTEMSETTINGS = 10472,
  CUSTOMERSERVICE_MENU_CHANGEPASSWORD = 10490,
  CUSTOMERSERVICE_MENU_MOBILE_HOME = 10491,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDEVENTS_BYSPECIALINTEREST = 10492,
  CUSTOMERSERVICE_MENU_VIEW_DRAFTS = 10493,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDEVENTS_ACKNOWLEDGEMENTREQUIRED = 10494,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDRESENTEVENTS = 10495,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDVIPEVENTS = 10496,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDRISKEVENTS = 10497,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDSAFETYEVENTS = 10498,
  CUSTOMERSERVICE_MENU_VIEW_CSADMIN = 10499,
  CUSTOMERSERVICE_MENU_VIEW_ROSTERS = 10500,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDHARDRUBBISHEVENTS_CRMS = 10501,
  CUSTOMERSERVICE_MENU_VIEW_ALLHARDRUBBISHEVENTS_CRMS = 10502,
  CUSTOMERSERVICE_MENU_HARDRUBBISH = 10503,
  CUSTOMERSERVICE_MENU_VIEW_HARDRUBBISHCOLLECTIONSLOOKUP = 10504,
  CUSTOMERSERVICE_MENU_VIEW_NOTCOLLECTEDHARDRUBBISHEVENTS = 10505,
  CUSTOMERSERVICE_MENU_VIEW_ALLHARDRUBBISHEVENTSBYREFNO = 10506,
  CUSTOMERSERVICE_MENU_COUNCILLOR = 10507,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDCOUNCILLOREVENTSBYCOUNCILLOR = 10508,
  CUSTOMERSERVICE_MENU_VIEW_ALLCOUNCILLOREVENTSBYCOUNCILLOR = 10509,
  CUSTOMERSERVICE_MENU_VIEW_ALLCOUNCILLOREVENTSBYORG1 = 10510,
  CUSTOMERSERVICE_MENU_VIEW_ALLCOUNCILLOREVENTSBYWARD = 10511,
  CUSTOMERSERVICE_MENU_VIEW_ALLHARDRUBBISHEVENTSBYMONTH = 10512,
  CUSTOMERSERVICE_MENU_PHONEMESSAGES = 10513,
  CUSTOMERSERVICE_MENU_VIEW_ALLPHONEMESSAGES = 10514,
  CUSTOMERSERVICE_MENU_VIEW_OPENPHONEMESSAGES = 10515,
  CUSTOMERSERVICE_MENU_EXTERNALUSER = 10516,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDEXTERNALUSEREVENTSBYACTIONOFFICER = 10517,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDEXTERNALUSEREVENTSBYREFNO = 10518,
  CUSTOMERSERVICE_MENU_VIEW_ALLEXTERNALUSEREVENTSBYREFNO = 10519,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDMYCOUNCILLOREVENTS = 10520,
  CUSTOMERSERVICE_MENU_VIEW_ALLEXTERNALUSEREVENTSBYACTIONOFFICER = 10521,
  CUSTOMERSERVICE_MENU_INSPECTIONS = 10522,
  CUSTOMERSERVICE_MENU_VIEW_INSPECTIONSBYDATE = 10523,
  CUSTOMERSERVICE_MENU_VIEW_INSPECTIONSBYMONTH = 10524,
  CUSTOMERSERVICE_MENU_VIEW_INSPECTIONSBYOFFICER = 10525,
  CUSTOMERSERVICE_MENU_VIEW_INSPECTIONSBYTYPE = 10526,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDEVENTSBYRECIPIENT = 10527,
  CUSTOMERSERVICE_MENU_NOTICES = 10528,
  CUSTOMERSERVICE_MENU_VIEW_NOTICEBYDATE = 10529,
  CUSTOMERSERVICE_MENU_VIEW_NOTICEBYDUESTATUS = 10530,
  CUSTOMERSERVICE_MENU_VIEW_INVESTIGATIONSBYDATE = 10531,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDMYEVENTSDWCMS = 10532,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDBYACTIONOFFICERDWCMS = 10533,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDBYREFNODWCMS = 10534,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDBYCATEGORYDWCMS = 10535,
  CUSTOMERSERVICE_MENU_VIEW_ALLBYACTIONOFFICERDWCMS = 10536,
  CUSTOMERSERVICE_MENU_VIEW_ALLBYREFNODWCMS = 10537,
  CUSTOMERSERVICE_MENU_VIEW_ALLBYCATEGORYDWCMS = 10538,
  CUSTOMERSERVICE_MENU_VIEW_DRAFTSDWCMS = 10539,
  CUSTOMERSERVICE_MENU_VIEW_ALLMYEVENTSDWCMS = 10540,
  CUSTOMERSERVICE_MENU_VIEW_INVESTIGATIONSBYINVESTIGATOR = 10541,
  CUSTOMERSERVICE_MENU_VIEW_INVESTIGATIONSBYREFERENCENUMBER = 10542,
  CUSTOMERSERVICE_MENU_VIEW_INVESTIGATIONSBYSTATUS = 10543,
  CUSTOMERSERVICE_MENU_VIEW_INVESTIGATIONSBYSOURCE = 10544,
  CUSTOMERSERVICE_MENU_VIEW_OPENINVESTIGATIONSBYDATE = 10545,
  CUSTOMERSERVICE_MENU_VIEW_OPENINVESTIGATIONSBYINVESTIGATOR = 10547,
  CUSTOMERSERVICE_MENU_VIEW_OPENINVESTIGATIONSBYREFERENCENUMBER = 10548,
  CUSTOMERSERVICE_MENU_VIEW_OPENINVESTIGATIONSBYSTATUS = 10549,
  CUSTOMERSERVICE_MENU_VIEW_OPENINVESTIGATIONSBYSOURCE = 10550,
  CUSTOMERSERVICE_MENU_VIEW_UNRESOLVEDEVENTSBYTIER4 = 10551,
  EMS_MENU_VIEW_ADMIN = 10601,
  EMS_MENU_VIEW_FACILITY = 10602,
  EMS_MENU_VIEW_ALL_EVENTS = 10603,
  EMS_MENU_VIEW_FUTURE_EVENTS = 10604,
  EMS_MENU_VIEW_ALL_CALENDAR = 10605,
  EMS_MENU_VIEW_SUBMITTED_EVENTS = 10606,
  EMS_MENU_VIEW_NOT_YET_APPROOVED_EVENTS = 10607,
  EMS_MENU_VIEW_EVENT_TYPES = 10608,
  EMS_MENU_VIEW_CANCELLED_EVENTS = 10609,
  EMS_MENU_VIEW_ALL_EVENTS_BYSTATUS = 10610,
  EMS_MENU_VIEW_ALL_EVENTS_BYREQUESTOR = 10611,
  EMS_MENU_VIEW_ALL_EVENTSBYORGANISATION = 10612,
  AMS_MENU_VIEW_ADMIN = 10701,
  AMS_MENU_VIEW_DEPARTMNETS = 10702,
  AMS_MENU_VIEW_APPOINTMENTS_ALL = 10703,
  AMS_MENU_VIEW_APPOINTMENTS_FUTURE = 10704,
  AMS_MENU_VIEW_APPOINTMENTS_NOT_YET_APPROVED = 10705,
  AMS_MENU_VIEW_APPOINTMENTS_SUBMITTED = 10706,
  AMS_MENU_VIEW_APPOINTMENTS_ALL_CALENDAR = 10707,
  FLEET_MENU_VIEW_ADMIN = 10801,
  FLEET_MENU_VIEW_VEHICLE = 10802,
  FLEET_MENU_VIEW_ALL_EVENTS = 10803,
  FLEET_MENU_VIEW_FUTURE_EVENTS = 10804,
  FLEET_MENU_VIEW_ALL_CALENDAR = 10805,
  FLEET_MENU_VIEW_SUBMITTED_EVENTS = 10806,
  FLEET_MENU_VIEW_NOT_YET_APPROOVED_EVENTS = 10807,
  FLEET_MENU_VIEW_EVENT_TYPES = 10808,
  FLEET_MENU_VIEW_CANCELLED_EVENTS = 10809,
  FLEET_MENU_VIEW_ALL_EVENTS_BYSTATUS = 10810,
  FLEET_MENU_VIEW_ALL_EVENTS_BYREQUESTOR = 10811,
  FLEET_MENU_VIEW_ALL_EVENTSBYORGANISATION = 10812,
  FLEET_FORM_BOOKING_CONFIRM_EVENT = 10813,
  FLEET_FORM_BOOKING_SUBMIT_EVENT = 10814,
  FLEET_FORM_BOOKING_DECLINE_EVENT = 10815,
  FLEET_FORM_BOOKING_VIEW_INVOICE = 10816,
  FLEET_FORM_VEHICLE_SUBSCRIBE_TO_CALENDAR = 10817,
  FLEET_FORM_VEHICLE_ACTIVE = 10818,
  FLEET_FORM_VEHICLE_INACTIVE = 10819,
  FLEET_FORM_CALENDAR_ADD = 10820,
  FLEET_FORM_BOOKING_CHANGE_VEHICLE = 10821,
  FLEET_FORM_VEHICLE_ACCESSCONTROL_ADMINISTRATION = 10822,
  FLEET_FORM_BOOKING_ADD_VEHICLE = 10823,
  FLEET_MENU_VIEW_SEARCH_VEHICLE = 10824,
  FLEET_MENU_VIEW_AWAITING_PICKUP = 10825,
  FLEET_MENU_VIEW_AWAITING_RETURN = 10826,
  FLEET_MENU_VIEW_COMPLETED_EVENTS = 10827,
  FLEET_MENU_VIEW_NOTCOMPLETED_EVENTS = 10828,
  SRS_AMENDMENT_APPROVE = 11000,
  SRS_AMENDMENT_DECLINE = 11001,
  SRS_FACILITY_INPROGRESS = 11002,
  SRS_FACILITY_WITHDRAW = 11003,
  SRS_FACILITY_REINSTATE = 11004,
  SRS_FACILITY_OPERATIONAL = 11005,
  SRS_FACILITY_NOTOPERATIONAL = 11006,
  SRS_FACILITY_CLOSE = 11007,
  SRS_FACILITY_REOPEN = 11008,
  SRS_FACILITY_ADMINISTRATION = 11009,
  SRS_FACILITY_ISSUECERT = 11010,
  SRS_ADD_AMENDMENT = 11011,
  SRS_ADD_SEIZURE = 11012,
  SRS_ADD_REPORTABLEINCIDENT = 11013,
  SRS_ADD_REPORTABLETRANSACTION = 11014,
  SRS_ADD_SANCTION = 11015,
  SRS_ADD_STATEMENT = 11016,
  SRS_ADD_UNDERTAKING = 11017,
  SRS_ADD_SAVVIEXPENDITURE = 11018,
  SRS_FACILITY_ALERT = 11019,
  SRS_FACILITY_APPLICATIONDETAILS = 11020,
  SRS_DELETE_AMENDMENT = 11021,
  SRS_DELETE_SEIZURE = 11022,
  SRS_DELETE_REPORTABLEINCIDENT = 11023,
  SRS_DELETE_REPORTABLETRANSACTION = 11024,
  SRS_DELETE_SANCTION = 11025,
  SRS_DELETE_STATEMENT = 11026,
  SRS_DELETE_UNDERTAKING = 11027,
  SRS_DELETE_SAVVIEXPENDITURE = 11028,
  SRS_EDIT_ADMIN = 11029,
  SRS_AMENDMENT_EDIT_ADMIN = 11030,
  HM_CHANGEPREMISESTYPE = 12001,
  HM_OVERRIDEINSPFREQUENCY = 12002,
  HM_REGISTERREGISTRATION = 12003,
  HM_RENEWREGISTRATION = 12004,
  HM_CLOSEPREMISES = 12005,
  HM_REOPENPREMISES = 12006,
  HM_TRANSFERPREMISES = 12007,
  HM_EDIT_REGISTRATION = 12008,
  DISRES_MENU_VIEW_ALLPERMITBYSTREET = 13001,
  DISRES_MENU_VIEW_ALLDISABLEDPERMIT = 13002,
  DISRES_MENU_VIEW_ALLSTAFF = 13003,
  DISRES_MENU_VIEW_WORKSTATS = 13004,
  DISRES_MENU_VIEW_OVERRIDE = 13005,
  DISRES_MENU_VIEW_PERMITNOTISSUED = 13006,
  DISRES_MENU_VIEW_PERMITNOTPRINTED = 13007,
  DISRES_MENU_VIEW_RENEWPERMIT = 13008,
  DISRES_MENU_VIEW_KEYWORDS = 13008,
  DISRES_MENU_VIEW_SYSTEMSETTINGS = 13009,
  DISRES_MENU_VIEW_PERMITTYPE = 13010,
  DISRES_MENU_VIEW_RESIDENTAREAPARKINGDEFINITION = 13011,
  DISRES_MENU_VIEW_STAFFAREAPARKINGDEFINITION = 13012,
  DISRES_MENU_VIEW_PROPERTYRESTRICTION = 13013,
  DISRES_MENU_VIEW_PROPERTYEXCEPTION = 13014,
  DISRES_MENU_VIEW_ADMINEXPORTDATA = 13015,
  DISRES_MENU_VIEW_INSPECTION = 13016,
  DISRES_MENU_VIEW_RENEWALADMIN = 13017,
  DISRES_PERMIT_CREATE = 13101,
  DISRES_PERMIT_ISSUE = 13102,
  DISRES_PERMIT_RENEW = 13103,
  DISRES_PERMIT_CANCEL = 13104,
  LAMP_ADD_SITE = 14001,
  LAMP_ADD_ACTIVITY = 14002,
  LAMP_ADD_VEHICLE = 14003,
  LAMP_ADD_SITEPOLLUTANT = 14004,
  LAMP_ADD_SITEPOLLUTANTDATA = 14005,
  LAMP_ADD_DE_NOTIFICATION = 14006,
  LAMP_ADD_DE_RETURN = 14007,
  LAMP_ADD_WD_RETURN = 14008,
  LAMP_ADD_WD_RETURNSUMMARY = 14009,
  LAMP_ADD_VOLSURVEY = 14010,
  LAMP_ADD_CERTIFICATEOFTITLE = 14011,
  LAMP_ADD_DEVELOPMENTAPPROVAL = 14012,
  LAMP_ADD_WT_ACTIVITY = 14013,
  LAMP_DELETE_SITE = 14101,
  LAMP_DELETE_ACTIVITY = 14102,
  LAMP_DELETE_VEHICLE = 14103,
  LAMP_DELETE_SITEPOLLUTANT = 14104,
  LAMP_DELETE_SITEPOLLUTANTDATA = 14105,
  LAMP_DELETE_DE_NOTIFICATION = 14106,
  LAMP_DELETE_DE_RETURN = 14107,
  LAMP_DELETE_WD_RETURN = 14108,
  LAMP_DELETE_WD_RETURNSUMMARY = 14109,
  LAMP_DELETE_VOLSURVEY = 14110,
  LAMP_DELETE_CLAIMHEADER = 14111,
  LAMP_DELETE_CLAIMDETAIL = 14112,
  LAMP_DELETE_CONDITIONCLAUSE = 14113,
  LAMP_DELETE_CASEOFKINDAPPLIED = 14114,
  LAMP_DELETE_INVOICE = 14115,
  LAMP_DELETE_REFUND = 14116,
  LAMP_DELETE_ELFSITE = 14117,
  LAMP_DELETE_AUTHORISEDCONTACT = 14118,
  LAMP_FORM_AUTHORISATION_ADD_REFUND = 14200,
  LAMP_FORM_AUTHORISATION_ADD_ISSUE = 14201,
  LAMP_FORM_AUTHORISATION_ADD_CERTIFICATEATTACHMENT = 14202,
  LAMP_FORM_AUTHORISATION_ADD_CASEOFKIND = 14203,
  LAMP_FORM_AUTHORISATION_ADD_AUTHORISEDCONTACT = 14204,
  LAMP_FORM_AUTHORISATION_ADDPROCESS = 14211,
  LAMP_FORM_AUTHORISATION_READYTONOTIFY = 14231,
  LAMP_FORM_AUTHORISATION_PUBLICNOTIFICATIONNOTREQUIRED = 14232,
  LAMP_FORM_AUTHORISATION_CONDITIONS = 14233,
  LAMP_FORM_AUTHORISATION_GENI = 14234,
  LAMP_FORM_AUTHORISATION_FURTHERINFORMATIONREQUEST = 14235,
  LAMP_FORM_AUTHORISATION_PUBLICNOTIFICATION = 14236,
  LAMP_FORM_AUTHORISATION_SENDSUBMISSIONSTOAPPLICANT = 14237,
  LAMP_FORM_AUTHORISATION_NOSUBMISSIONS = 14238,
  LAMP_FORM_AUTHORISATION_READYFORDELEGATEAPPROVAL = 14239,
  LAMP_FORM_AUTHORISATION_GENERATEINVOICE = 14240,
  LAMP_FORM_AUTHORISATION_PAYMENTRECEIVED = 14241,
  LAMP_FORM_AUTHORISATION_GENERATECERTIFICATE = 14242,
  LAMP_FORM_AUTHORISATION_ISSUEAUTHORISATION = 14243,
  LAMP_FORM_APPLICATION_LODGEAPPLICATION = 14301,
  LAMP_FORM_APPLICATION_SENDTOLICENCECOORDINATOR = 14302,
  LAMP_FORM_APPLICATION_FURTHERINFORMATIONREQUEST = 14303,
  LAMP_FORM_APPLICATION_LIMITEDPURPOSE = 14304,
  LAMP_FORM_APPLICATION_REFUSEAPPLICATION = 14305,
  LAMP_FORM_APPLICATION_PROCEEDTOAUTHORISATION = 14306,
  TP_DELETE_APPLICATIONCONDITION = 15001,
  TP_TOOLBAR_ADD_REFERRAL = 15002,
  TP_TOOLBAR_ADD_CONDITION = 15003,
  TP_MENU_VIEW_RecycleBin = 15004,
  TP_MENU_VIEW_PSA = 15005,
  TP_MENU_VIEW_OAs = 15006,
  TP_FORM_WORKFLOW_BUTTONS = 15007,
  TP_MENU_EPLANNING = 15008,
  TP_DELETE_PRESCRIBEEVENT = 15009,
  TP_FORM_WORKFLOW_BUTTONS_PROCEDURAL = 15010,
  TP_FORM_WORKFLOW_BUTTONS_DELEGATEAPPROVAL = 15011,
  TP_TOOLBAR_PUBLISH = 15012,
  TP_TOOLBAR_PUBLISH_PERMIT_LOOKUP = 15013,
  PPR_MENU_REGISTERS = 15014,
  CUSTOMERSERVICE_MENU_VIEW_COLDCHAIN_LOOKUPTABLES = 16001,
  CUSTOMERSERVICE_MENU_VIEW_COLDCHAIN_VACCINES = 16002,
  CUSTOMERSERVICE_MENU_VIEW_COLDCHAIN_TEMPERATUREDURATIONRANGES = 16003,
  CUSTOMERSERVICE_MENU_VIEW_COLDCHAIN_VACCINEACTIONS = 16004,
  CUSTOMERSERVICE_MENU_VIEW_COLDCHAIN_PORTALBREACHES = 16005,
  CUSTOMERSERVICE_MENU_COLDCHAIN_ADMIN = 16006,
  CUSTOMERSERVICE_MENU_COLDCHAIN_SYSTEMSETTINGS = 16007,
  CUSTOMERSERVICE_MENU_COLDCHAIN_PORTAL = 16008,
  CUSTOMERSERVICE_MENU_VIEW_COLDCHAIN_ALLBREACHES = 16009,
  CUSTOMERSERVICE_MENU_VIEW_COLDCHAIN_UNRESOLVEDBREACHES = 16010,
  CUSTOMERSERVICE_MENU_VIEW_COLDCHAIN_BREACHMASTEREVENTS = 16011,
  CUSTOMERSERVICE_MENU_VIEW_COLDCHAIN_COMMUNICATIONTEMPLATES = 16012,
  CUSTOMERSERVICE_MENU_VIEW_COLDCHAIN_DOCUMENTTEMPLATES = 16013,
  CUSTOMERSERVICE_MENU_VIEW_COLDCHAIN_GPDIVISIONS = 16014,
  CUSTOMERSERVICE_MENU_VIEW_COLDCHAIN_SERVICEPROVIDERS = 16015,
  CRMS_TOOLS_CLOSEEVENT = 17002,
  CRMS_TOOLS_SERVICEDIRECTION = 17003,
  CRMS_TOOLS_ACKNOWLEDGEEVENT = 17004,
  CRMS_TOOLS_BATCHUPDATE = 17005,
  CRMS_CLONE_NEW = 17006,
  CRMS_SERVICESTANDARD_ADD_STANDARDDOCUMENT = 17007,
  CRMS_SERVICESTANDARD_ADD_SERVICEDIRECTION = 17008,
  CRMS_SERVICESTANDARDCATEGORY_ADD_SERVICESTANDARDSUBCATEGORY = 17009,
  CRMS_SERVICESTANDARDSUBCATEGORY_ADD_SERVICESTANDARD = 17010,
  CRMS_CLOSEDEVENT_REOPENEVENT = 17011,
  CRMS_CLOSEDEVENT_ADDACTION = 17012,
  CRMS_CLOSEDEVENT_ADDCOMMENT = 17013,
  CRMS_CLOSEDEVENT_ADDATTACHMENT = 17014,
  CRMS_CLOSEDEVENT_ADDDOCUMENT = 17015,
  CRMS_CLOSEDEVENT_ADDADDITIONALCONTACT = 17016,
  CRMS_CLOSEDEVENT_ADDCOMMUNICATION = 17017,
  CRMS_VIP = 17018,
  CUSTOMERSERVICE_MENU_KNOWLEDGEBASE = 17019,
  CUSTOMERSERVICE_MENU_VIEW_KNOWLEDGEBASE_KBRELEASED = 17020,
  CUSTOMERSERVICE_MENU_VIEW_KNOWLEDGEBASE_KBUNRELEASED = 17021,
  CUSTOMERSERVICE_MENU_VIEW_KNOWLEDGEBASE_KBALERTS = 17022,
  CUSTOMERSERVICE_KB_STARTREVIEW = 17023,
  CUSTOMERSERVICE_KB_COMPLETEREVIEW = 17024,
  CUSTOMERSERVICE_KB_PUBLISH = 17025,
  CUSTOMERSERVICE_MENU_VIEW_KNOWLEDGEBASE_DUEFORREVIEW = 17026,
  CUSTOMERSERVICE_MENU_VIEW_KNOWLEDGEBASE_UNDERREVIEW = 17027,
  CUSTOMERSERVICE_MENU_VIEW_KNOWLEDGEBASE_REQUIRESADMINAPPROVAL = 17028,
  CUSTOMERSERVICE_MENU_VIEW_KNOWLEDGEBASE_EXPIRED = 17029,
  CUSTOMERSERVICE_KB_EDITPUBLISHEDRECORD = 17030,
  CUSTOMERSERVICE_MENU_VIEW_KNOWLEDGEBASE_DRAFTS = 17031,
  CRMS_HARDRUBBISH_SENDLETTER = 17032,
  CRMS_HARDRUBBISH_SENDLETTERMARKASCOLLECTED = 17033,
  CRMS_HARDRUBBISH_MARKASCOLLECTED = 17034,
  CUSTOMERSERVICE_MENU_VIEW_KNOWLEDGEBASE_NOSERVICESTANDARD = 17035,
  CUSTOMERSERVICE_MENU_VIEW_KNOWLEDGEBASE_KBRELEASEDBYORG3 = 17036,
  CRMS_HARDRUBBISH_MARKASCANCELLED = 17037,
  CRMS_CLOSEDEVENT_ADDINSPECTION = 17038,
  CRMS_TOOLBAR_ADD_SPECIALCOMMENT = 17039,
  CRMS_CLOSEDEVENT_ADDSPECIALCOMMENT = 17040,
  CRMS_TOOLS_UPDATEEVENTSECURITY = 17041,
  CUSTOMERSERVICE_MENU_VIEW_KNOWLEDGEBASE_KBTOPICS = 17042,
  CRMS_CLOSE_EVENT = 17043,
  CRMS_RESEND_EVENT = 17044,
  CRMS_COMPLETE_ACTION = 17045,
  CRMS_TOOLS_VIEWONMAP = 17046,
  CUSTOMERSERVIC_TRIM_ADMIN_PARENT = 17047,
  CRMS_EVENT_LOCATION_CHANGE = 17048,
  CRMS_CLOSEDEVENT_ADDNOTICE = 17049,
  CRMS_ADDCOMPLAINEE = 17050,
  CRMS_ADDDWCMSPROVIDER = 17051,
  CRMS_ADDDWCMSADDITIONALWORKER = 17052,
  CRMS_ADDDWCMSADDITIONALPROVIDER = 17053,
  CRMS_CLOSEDEVENT_ADDDWCMSADDITIONALWORKER = 17054,
  CRMS_CLOSEDEVENT_ADDDWCMSADDITIONALPROVIDER = 17055,
  CRMS_ADDDWCMSREQUESTORPROVIDER = 17056,
  CRMS_TOOLBAR_ADDINVESTIGATION = 17057,
  CRMS_TOOLS_CHANGEDETAILS = 17058,
  CUSTOMERSERVICE_MENU_ITSUPPORT = 18001,
  CUSTOMERSERVICE_ITSUPPORT_MENU_VIEW_UNALLOCATED_EVENTS = 18002,
  CUSTOMERSERVICE_ITSUPPORT_MENU_VIEW_ALL_EVENTS = 18003,
  CUSTOMERSERVICE_ITSUPPORT_MENU_VIEW_UNRESOLVED_EVENTS = 18004,
  CUSTOMERSERVICE_MENU_PAYROLL = 18101,
  CUSTOMERSERVICE_PAYROLL_MENU_VIEW_ALL_EVENTS = 18102,
  CUSTOMERSERVICE_PAYROLL_MENU_VIEW_UNRESOLVED_EVENTS = 18103,
  CUSTOMERSERVICE_PAYROLL_MENU_VIEW_DRAFTS = 18104,
  CUSTOMERSERVICE_PAYROLL_MENU_VIEW_MYUNRESOLVED_EVENTS = 18105,
  CUSTOMERSERVICE_PAYROLL_MENU_VIEW_ALLMY_EVENTS = 18106,
  CUSTOMERSERVICE_PAYROLL_MENU_VIEW_MYDRAFTS = 18107,
  EMS_FORM_BOOKING_CONFIRM_EVENT = 19001,
  EMS_FORM_BOOKING_SUBMIT_EVENT = 19002,
  EMS_FORM_BOOKING_DECLINE_EVENT = 19003,
  EMS_FORM_BOOKING_VIEW_INVOICE = 19004,
  EMS_FORM_FACILITY_SUBSCRIBE_TO_CALENDAR = 19005,
  EMS_FORM_FACILITY_ACTIVE = 19006,
  EMS_FORM_FACILITY_INACTIVE = 19007,
  EMS_FORM_CALENDAR_ADD = 19008,
  EMS_FORM_BOOKING_CHANGE_FACILITY = 19009,
  EMS_FORM_FACILITY_ACCESSCONTROL_ADMINISTRATION = 19010,
  EMS_FORM_BOOKING_ADD_FACILITY = 19011,
  AMS_FORM_APPOINTMENT_CHANGEDATE = 20001,
  AMS_FORM_APPOINTMENT_CONFIRM = 20002,
  AMS_FORM_APPOINTMENT_DECLINE = 20003,
  AMS_FORM_DEPARTMENT_ACTIVATE = 20004,
  AMS_FORM_DEPARTMENT_INACTIVATE = 20005,
  AMS_FORM_APPOINTMENT_ACCEPT = 20006,
  TM_COURSEOUTLINE_ACTIVATE = 21001,
  TM_COURSEOUTLINE_DEACTIVATE = 21002,
  TM_COURSEOUTLINE_REQUEST_TRAINING = 21003,
  TM_MENU_COURSEOUTLINE_ATTENDEES = 21004,
  TM_MENU_VIEW_COURSE_OUTLINE = 21005,
  TM_MENU_VIEW_COURSE_SCHEDULE = 21006,
  TM_MENU_VIEW_TRAINING_REQUEST = 21007,
  TM_MENU_VIEW_DEVELOPMENT_PLAN = 21008,
  TM_MENU_VIEW_EVALUATION = 21009,
  TM_MENU_VIEW_HISTORICAL = 21010,
  TM_MENU_VIEW_SYSTEM_ADMIN = 21011,
  TM_MENU_VIEW_MY_DEVELOPMENT_PLANS = 21012,
  TM_MENU_VIEW_MY_TRAINING_EVALUATIONS = 21013,
  TM_MENU_VIEW_MY_TRAINING_REQUESTS = 21014,
  TM_COURSEOUTLINE_SCHEDULE_TRAINING = 21015,
  TM_MENU_COURSEOUTLINE_COMPETENCY = 21016,
  TM_MENU_COURSEOUTLINE_SEND_INVITES = 21017,
  TM_COURSESCHEDULE_ACTIVATE = 21018,
  TM_COURSESCHEDULE_DEACTIVATE = 21019,
  TM_COURSESCHEDULE_REQUEST_TRAINING = 21020,
  TM_MENU_COURSESCHEDULE_SEND_INVITES = 21021,
  TM_MENU_COURSESCHEDULE_ATTENDEES = 21022,
  TM_MENU_DEVELOPMENTPLAN_ITEM = 21023,
  TM_VIEW_MY_OPENTRAINING_REQUESTS = 21024,
  TM_VIEW_OPEN_REQUESTS_BY_TRAINEE = 21025,
  TM_VIEW_OPEN_REQUESTS_BY_STATUS = 21026,
  TM_VIEW_OPEN_REQUESTS_BY_ORG = 21027,
  TM_VIEW_OPEN_REQUESTS_BY_COURSE = 21028,
  TM_VIEW_ACTION_REQIRED_REQUESTS = 21029,
  TM_VIEW_ALL_REQUESTS_BY_TRAINEE = 21030,
  TM_VIEW_MY_TRAININGMENU = 21031,
  TM_MENU_VIEW_DEVELOPMENT_PLAN_ALLPLANS = 21032,
  TM_MENU_VIEW_DEVELOPMENT_PLAN_ALLBYTRAINEE = 21033,
  TM_MENU_VIEW_DEVELOPMENT_PLAN_BYPERIOD = 21034,
  TM_VIEW_OPEN_ADDHOC_REQUESTS_BY_TRAINEE = 21035,
  TM_MENU_CREATE_ADDNOC_TRAINING_REQUEST = 21036,
  TM_MENU_VIEW_SKILL_AND_COMPLIANCE = 21037,
  TM_COURSEACTIVATEBUTTON_ACTIVATE = 21038,
  TM_MENU_VIEW_All_SKILL_AND_COMPLIANCE = 21039,
  TM_MENU_VIEW_ACTIVE_COURSEOUTLINE = 21040,
  TM_MENU_VIEW_ACTIVE_COURSESCHEDULE = 21041,
  TM_MENU_VIEW_DEPARTMENT_REQUESTS = 21042,
  TM_MENU_VIEW_DEPARTMENT_ADHOC_REQUESTS = 21043,
  TM_MENU_VIEW_DEPARTMENT_ACTIONREQUIRED_REQUESTS = 21044,
  TM_MENU_VIEW_OPEN_REQUESTS_BYTRAINEE = 21045,
  TM_MENU_VIEW_OPEN_REQUESTS_BYSTATUS = 21046,
  TM_MENU_VIEW_OPEN_REQUESTS_BY_ORGMGR = 21047,
  TM_MENU_VIEW_OPEN_REQUESTS_BYCOURSE = 21048,
  TM_MENU_VIEW_OPEN_REQUESTS_BY_ADHOCREQ = 21049,
  TM_MENU_VIEW_ALL_REQUESTS_BYTRAINEE = 21050,
  TM_MENU_VIEW_ACTION_REQ = 21051,
  TM_MENU_VIEW_EVALUATION_GROUPBYNAME = 21052,
  TM_MENU_VIEW_MY_TRAINING_EVALUATIONSGROUPBYNAME = 21053,
  TM_MENU_VIEW_DEPARTMENT_ALLREQUESTS = 21054,
  TM_COURSESCHEDULE_CANCEL = 21055,
  TMCOURSECOMPETENCY_DELETE = 21056,
  TMATTENDEES_DELETE = 21057,
  TMWAITINGLIST_DELETE = 21058,
  TMTRAININGREQUEST_DELETE = 21059,
  PROJECT_FORM_REGISTER = 22001,
  RAD_REGISTERREGISTRATION = 23001,
  RAD_RENEWREGISTRATION = 23002,
  RAD_DELETE_SOURCE = 23003,
  RAD_TOOLBAR_ADD_AUTHORISATION = 23004,
  RAD_DELETE_AUTHORISATION = 23005,
  RAD_DELETE_PREREQUISITES = 23006,
  RAD_DELETE_SITE = 23007,
  RAD_SUSPENDLICENCE = 23008,
  RAD_CANCELLICENCE = 23009,
  RAD_DEACTIVATELICENCE = 23010,
  RAD_WITHDRAWAPPLICATION = 23011,
  RAD_TOOLBAR_ADD_VARIATION = 23012,
  RAD_TOOLBAR_PUBLISH = 23013,
  RAD_MENU_FINANCE = 23014,
  RAD_MENU_KEYWORDS = 23016,
  RAD_MENU_SYSTEMSETTINGS = 23017,
  RAD_MENU_RADIONUCLIDES = 23018,
  RAD_MENU_FEECONFIGURATION = 23019,
  RAD_TOOLBAR_REPLACEBUSINESSRULE = 23020,
  RAD_FORM_DEACTIVATE = 23021,
  RAD_FORM_REACTIVATE = 23022,
  RAD_TOOLBAR_CLONE = 23023,
  RAD_TOOLBAR_INCIDENT = 23024,
  RAD_TOOLBAR_RESEARCHPROJECT = 23025,
  RAD_DELETE_PATHWAY = 23026,
  RAD_DELETE_AUTHORISATIONSOURCE = 23027,
  CRSCENTREACCESS_MENU_CENTRE = 30014,
  CRSCENTREACCESS_MENU_VIEW_CBC_SERVICE = 30015,
  CRSCENTREACCESS_MENU_VIEW_3YO_SERVICE = 30016,
  CRSCENTREACCESS_MENU_VIEW_4YO_SERVICE = 30017,
  CSM_FORM_MARKASNONLICENSED = 31001,
  CSM_LIST_MARKASNONLICENSED = 31002,
  CSM_POISONS_FORM_MARKASACTIVE = 31003,
  CSM_POISONS_FORM_MARKASINACTIVE = 31004,
  LLP_DEACTIVATE = 32001,
  LLP_ISSUERENEWALFEE = 32002,
  LLP_ISSUERENEWALNOTICE = 32003,
  LLP_RESETRENEWALFEEFLAG = 32004,
  LLP_BATCHRENEW = 32005,
  SSMS_MENU_VIEW_SYSTEM_SETTING = 34001,
  SSMS_MENU_VIEW_OPERATIONS = 34002,
  SSMS_MENU_VIEW_MAP = 34003,
  SSMS_MENU_VIEW_ALLCHANGEREQUEST = 34004,
  SSMS_MENU_VIEW_ACTIVE_CHANGEREQUEST = 34005,
  SSMS_LEARNING_VIEW_HELP_LINK = 34006,
  SSMS_PARMSIMPORT = 34007,
  DWCMS_CRIMINALHISTORY_VISIBILITY = 35001,
  DWCMS_FORM_WORKFLOW_BUTTONS = 35002,
  DWCMS_MENU_WORKERS = 35003,
  DWCMS_MENU_REGISTRATIONS = 35004,
  DWCMS_MENU_APPLICATIONS = 35005,
  DWCMS_MENU_COMPLAINTS = 35006,
  DWCMS_MENU_ENQUIRIES = 35007,
  DWCMS_MENU_FINANCE = 35008,
  DWCMS_MENU_CONDITIONLIBRARY = 35009,
  DWCMS_MENU_ADMINISTRATION = 35010,
  DWCMS_MENU_RECYCLEBIN = 35011,
  DWCMS_MENU_ALLDISABILITYWORKERS = 35012,
  DWCMS_MENU_ACTIVEDISABILITYWORKERS = 35013,
  DWCMS_MENU_UNREGISTEREDDISABILITYWORKERS = 35014,
  DWCMS_MENU_DUPLICATEREGISTERWORKERS = 35015,
  DWCMS_MENU_ALLAPPLICATIONS = 35016,
  DWCMS_MENU_NEWAPPLICATIONS = 35017,
  DWCMS_MENU_APPLICATIONBYSTATUS = 35018,
  DWCMS_MENU_APPLICATIONBYTYPE = 35019,
  DWCMS_MENU_ALLREGISTERS = 35020,
  DWCMS_MENU_REGISTERBYSTATUS = 35021,
  DWCMS_MENU_ACTIVEREGISTERS = 35022,
  DWCMS_MENU_EXPIREDREGISTERS = 35023,
  DWCMS_MENU_COMPLAINTSBYDATE = 35024,
  DWCMS_MENU_COMPLAINTSBYTYPE = 35025,
  DWCMS_MENU_COMPLAINTSBYSTATUS = 35026,
  DWCMS_MENU_COMPLAINTSBYCATEGORY = 35027,
  DWCMS_MENU_COMPLAINTSBYOFFICER = 35028,
  DWCMS_MENU_COMPLAINTSBYDATEUNRELATED = 35029,
  DWCMS_MENU_ENQUIRIESBYDATE = 35030,
  DWCMS_MENU_ENQUIRIESBYSTATUS = 35031,
  DWCMS_MENU_ENQUIRIESBYCATEGORY = 35032,
  DWCMS_MENU_ENQUIRIESBYOFFICER = 35033,
  DWCMS_MENU_FINANCEFEESOUTSTANDING = 35034,
  DWCMS_MENU_FINANCEFEESPAID = 35035,
  DWCMS_MENU_FINANCEFEESALL = 35036,
  DWCMS_MENU_FINANCEFEESNOTSENTTOFINANCE = 35037,
  DWCMS_MENU_FINANCEFEESSENTTOFINANCE = 35038,
  DWCMS_MENU_FINANCEBANKFILEINBOX = 35039,
  DWCMS_MENU_FINANCEBANKFILEPROCESSED = 35040,
  DWCMS_MENU_FINANCEBANKFILEPROCESSEXCEPTIONS = 35041,
  DWCMS_MENU_CONDITIONDEFINITIONS = 35042,
  DWCMS_MENU_CONDITIONDEFINITIONSDRAFT = 35043,
  DWCMS_MENU_CONDITIONDEFINITIONBYSTATUS = 35044,
  DWCMS_MENU_SYSADMINREGISTRATIONTYPES = 35045,
  DWCMS_MENU_SYSADMINDIVISIONS = 35046,
  DWCMS_MENU_SYSADMINSERVICEPROVIDERS = 35047,
  DWCMS_MENU_SYSADMINEDUCATIONPROVIDERS = 35048,
  DWCMS_MENU_SYSADMINSETTINGS = 35049,
  DWCMS_MENU_SYSADMINFEECONFIGURATION = 35050,
  DWCMS_MENU_RECYCLEBINDISABILITYWORKERS = 35051,
  DWCMS_MENU_RECYCLEBINREGISTRATIONS = 35052,
  DWCMS_MENU_RECYCLEBINREGISTRATIONAPPLICATIONS = 35053,
  DWCMS_MENU_RECYCLEBINCOMPLAINTS = 35054,
  DWCMS_MENU_RECYCLEBINENQUIRIES = 35055,
  DWCMS_ADD_UNDERTAKING = 35056,
  DWCMS_MENU_WORKERUNDERTAKINGS = 35057,
  DWCMS_MENU_WORKERPROHIBITIONS = 35058,
  DWCMS_NATIONALPOLICECHECK_VISIBILITY = 35059,
  DWCMS_MENU_PROVIDERSMANAGEMENT = 35060,
  DWCMS_NEWAPPLICATION_EXTENDDUEDATE = 35061,
  DWCMS_MENU_ALLREQUESTS = 35062,
  DWCMS_MENU_REQUESTSBYSTATUS = 35063,
  DWCMS_MENU_REQUESTSBYCATEGORY = 35064,
  DWCMS_MENU_REGISTRATIONCONDITIONS = 35065,
  DWCMS_NEWAPPLICATION_PUBLISHTOWEB = 35066,
  DWCMS_REGISTRATION_PUBLISHTOWEB = 35067,
  DWCMS_APPLICATION_UPDATESTATUS = 35068,
  LM_LANDHOLDING_TOOLBAR_ADD = 36001,
  LM_EVENT_TOOLBAR_ADD = 36002,
  LM_TOOLBAR_ADD_OWNER = 36003,
  LM_TOOLBAR_ADD_RELATED = 36004,
  LM_TOOLBAR_ADD_EVENT = 36005,
  LM_TOOLBAR_ADD_ENQUIRY = 36006,
  LM_RGISTER_TOOLBAR_ADD = 36007,
  LM_LANDHOLDING_FORM_WORKFLOW_BUTTON_INACTIVE = 36008,
  LM_EVENT_FORM_WORKFLOW_BUTTON_ATREJECTED = 36009,
  LM_EVENT_FORM_WORKFLOW_BUTTON_ATAPPROVED = 36010,
  LM_EVENT_FORM_WORKFLOW_BUTTON_TLAPPROVED = 36011,
  LM_EVENT_FORM_WORKFLOW_BUTTON_TLREJECTED = 36012,
  LM_EVENT_FORM_WORKFLOW_BUTTON_SURRENDER = 36013,
  LM_EVENT_FORM_WORKFLOW_BUTTON_AMEND = 36014,
  LM_EVENT_FORM_WORKFLOW_BUTTON_ISSUENOTIFICATION = 36015,
  LM_EVENT_FORM_WORKFLOW_BUTTON_ISSUECERTIFICATION = 36016,
  LM_APPRASIAL_TAB_ASSESSMENTTEAMONLY = 36017,
  LM_APPRASIAL_TAB_CODEVARIATIONCHECKLIST = 36018,
  LM_APPRASIAL_READONLY = 36019,
  LM_EVENT_FORM_WORKFLOW_BUTTON_TRANSFER = 36020,
  LM_MENU_APPLICATION = 36021,
  LM_MENU_ENQUIRY = 36022,
  LM_MENU_EVENTS = 36023,
  LM_MENU_SYSTEMADMIN = 36024,
  DIRECTDEBIT_FORM_SETSUSPEND = 39001,
  DIRECTDEBIT_FORM_SETCANCEL = 39002,
  DIRECTDEBIT_FORM_SETTERMINATE = 39003,
  DIRECTDEBIT_FORM_SETCOMPLETE = 39004,
  DIRECTDEBIT_FORM_SESSION_EXPORTBANKFILE = 39005,
  DIRECTDEBIT_FORM_SESSION_SENDTORECEIPTING = 39006,
  DIRECTDEBIT_FORM_SESSIONOPEN = 39007,
}

export enum FormIdentifier {
  SystemInitialise = 0,
  FakeForm = 1,
  SA_Form_AddSiteMember = 1000,
  SA_List_ClientsAdmin = 1001,
  SA_Form_SiteUserDetails = 1002,
  SA_Form_Address = 1003,
  EA_Form_EnterpriseUserNew = 1500,
  EA_Form_EnterpriseUser = 1501,
  PA_Form_SiteUserNew = 2000,
  PA_Form_SiteUser = 2001,
  PA_Form_SiteUserDetails = 2002,
  PA_Form_LoginMethod = 2003,
  PA_Form_SecurityTemplateNew = 2004,
  PA_View_SecurityTemplates = 2005,
  PA_Form_SecurityTemplate = 2006,
  PA_Form_AssignSecurityTemplates = 2007,
  PA_Form_AssignSecurityRoles = 2008,
  PA_Form_Clients = 2009,
  Core_View_Keywords = 3000,
  Core_Form_Keywords = 3001,
  Core_Pick_Keyword = 3002,
  Core_List_Keywords = 3003,
  Core_List_Keyword_SampleCategory = 3004,
  Core_List_Keyword_SampleLocation = 3005,
  Core_List_Keyword_SampleResult = 3006,
  Core_Sort_Keywords = 3007,
  Core_Preview_Keywords = 3008,
  Core_List_ActionTypes = 3010,
  Core_Form_ActionType = 3011,
  Core_List_AllActions = 3012,
  Core_List_AllOSActions = 3013,
  Core_List_MyActions = 3014,
  Core_List_MyOSActions = 3015,
  Core_Form_Action = 3016,
  Core_Dialog_RescheduleAction = 3017,
  Core_Dialog_CloseAction = 3018,
  Core_Dialog_OpenAction = 3019,
  Core_Dialog_JournalDetails = 3025,
  Core_Form_Comment = 3026,
  Core_Form_CommentSummary = 3027,
  Core_Form_CommentSummaryPrint = 3028,
  Core_List_DocumentTemplates = 3030,
  Core_Form_DocumentTemplate = 3031,
  Core_Form_Attachment = 3032,
  Core_Form_DialogDownloadAttachment = 3033,
  Core_Dialog_VMASAddressSelect = 3035,
  Core_Form_ActionPlan = 3036,
  Core_Form_VCATReview = 3037,
  Core_Dialog_DataToolsAddressSelect = 3038,
  Core_List_SampleTypes = 3040,
  Core_Form_SampleType = 3041,
  Core_List_Samples = 3042,
  Core_Form_Sample = 3043,
  Core_Form_AdvertisingType = 3044,
  Core_List_AdvertisingType = 3045,
  Core_Form_Advertising = 3046,
  Core_Form_FurtherInformationRequest = 3047,
  Core_Form_Appeal = 3048,
  Core_Form_AppealHearing = 3049,
  Core_List_InspectionTypes = 3050,
  Core_Form_InspectionType = 3051,
  Core_Dialog_PickInspectionType = 3052,
  Core_Form_Inspection = 3053,
  Core_Dialog_PickRelatedRecord = 3054,
  Core_List_FeeTypes = 3060,
  Core_Form_FeeType = 3061,
  Core_Dialog_PickFeeType = 3062,
  Core_Dialog_ResetEffectiveDate = 3063,
  Core_Dialog_Date = 3064,
  Core_Dialog_DateExtended = 3065,
  Core_Dialog_Fee_ChangeInvoice = 3066,
  Core_List_ComplaintTypes = 3070,
  Core_Form_ComplaintType = 3071,
  Core_Dialog_PickComplaintType = 3072,
  Core_Form_Complaint = 3073,
  Core_Form_ComplaintClosure = 3074,
  Core_Form_PrintComplaint = 3075,
  Core_Form_Notice = 3080,
  Core_List_NoticeTypes = 3081,
  Core_Form_NoticeType = 3082,
  Core_Dialog_PickNoticeType = 3083,
  Core_Dialog_Notice_SendToThirdParty = 3084,
  Core_Dialog_PickMultiTenancy = 3085,
  Core_Dialog_ImageGallery = 3086,
  Core_Form_Prosecution = 3090,
  Core_Form_ProsecutionCharge = 3091,
  Core_Dialog_PickActionType = 3092,
  Core_Form_Recall = 3093,
  Core_Dialog_BatchRecall = 3094,
  Core_Dialog_PickActionTypeReferral = 3095,
  Core_Dialog_PickActionTypeMeeting = 3096,
  Core_Dialog_PublishSetup = 3097,
  Core_Dialog_PublishAttachments = 3098,
  Core_Dialog_PublishFees = 3099,
  Core_Form_Log = 3100,
  Core_Form_Fees = 3101,
  Core_Alert = 3102,
  Core_OverrideReason = 3103,
  Core_Form_Refund = 3104,
  Core_Dialog_PickFeeAndInvoice = 3105,
  Core_Dialog_ValuePicker = 3106,
  Core_Dialog_LogDetails = 3107,
  Core_Form_Contact = 3110,
  Core_Form_ContactRelationship = 3111,
  Core_List_PickOfficer = 3112,
  Core_List_PickContact = 3113,
  Core_Form_SelectContact = 3114,
  Core_Form_SelectContact_Single = 3115,
  Core_Form_FinanceFileImport = 3116,
  Core_View_Contacts = 3117,
  Core_Form_FileImport = 3118,
  Core_Form_FileUpdate = 3119,
  Core_Form_Address = 3120,
  Core_Display_Address = 3121,
  Core_List_PostcodeLookup = 3122,
  Core_List_PropertyLookup = 3123,
  Core_List_PropertyLookup_Flat = 3124,
  Core_PredictiveSearch = 3125,
  Core_Form_PropertyAddress = 3126,
  Core_Form_BusinessUnits = 3130,
  Core_List_BusinessUnits = 3131,
  Core_List_MailMergeDocuments = 3140,
  Core_Form_ContactDetail = 3141,
  Core_Form_DownloadMailMerge = 3142,
  Core_List_FeeType = 3150,
  Core_Form_Payment = 3151,
  Core_List_HearingLocation = 3160,
  Core_List_Magistrate = 3161,
  Core_List_Verdict = 3162,
  Core_List_NoticeType = 3163,
  Core_Form_TemperatureRecorded = 3170,
  Core_Form_FSPObserved = 3171,
  Core_Form_AFSA = 3180,
  Core_Form_ChangeRegNo = 3181,
  Core_Dialog_AFSAComment = 3182,
  Core_Dialog_PickAFSAComment = 3183,
  Core_List_AFSAComment = 3184,
  Core_Form_AFSAComment = 3185,
  Core_Form_SampleFoodResults = 3190,
  Core_Form_SampleWaterResults = 3191,
  Core_Form_SamplePoolResults_Chlorine = 3192,
  Core_Form_SamplePoolResults_Bromine = 3193,
  Core_Form_SamplePoolResults_UV = 3194,
  Core_Form_SecurityTemplate = 3200,
  Core_View_SecurityTemplate = 3201,
  Core_List_SecurityTemplate = 3202,
  Core_Form_ChangePass = 3203,
  Core_View_CorporateAuthorisation = 3204,
  Core_List_CorporateAuthorisation = 3205,
  Core_Form_CorporateAuthorisation = 3206,
  Core_Form_HomePageShortcuts = 3208,
  Core_View_HomePageShortcuts = 3209,
  Core_List_HomePageShortcuts = 3210,
  Core_Form_HomePageViews = 3211,
  Core_View_HomePageViews = 3212,
  Core_Dialog_Communications = 3250,
  Core_View_CommunicationQueue = 3251,
  Core_Form_CommunicationQueue = 3252,
  Core_View_CommunicationTemplate = 3253,
  Core_Form_CommunicationTemplate = 3254,
  Core_Dialog_PickActionType_ServiceStandard = 3255,
  Integrated_View_ReportList = 3500,
  Integrated_Form_SharedReport = 3501,
  Integrated_Form_BatchShareReport = 3502,
  Integrated_Dialog_ReportList = 3503,
  Integrated_Dialog_ImportReportList = 3504,
  Product_View_ReportList = 3700,
  Product_Form_Reportviewer = 3701,
  Core_View_HomePage = 3702,
  Core_Form_HomePage_Search = 3703,
  Core_View_NotSentToFinance = 3704,
  Core_Dialog_FinanceFileImport = 3705,
  Core_View_SentToFinance = 3706,
  Core_Dialog_Checklist = 3707,
  Core_Dialog_TRIMRecordTypes = 3708,
  Core_Dialog_TRIMContainers = 3709,
  Core_Dialog_TRIMAddRecord = 3710,
  Core_Dialog_infoXpertRecordTypes = 3711,
  Core_Dialog_infoXpertContainers = 3712,
  Core_Dialog_NCItemResult = 3713,
  Core_Form_NCItem = 3714,
  Core_List_NCItem = 3715,
  Core_Dialog_TRIMClassifications = 3716,
  Core_List_SampleSurveys = 3717,
  Core_Form_SampleSurvey = 3718,
  Core_Form_SampleTest = 3719,
  Core_Dialog_SampleTypes = 3720,
  Core_Dialog_SampleTest = 3721,
  Core_Dialog_RegisterInspection = 3722,
  Core_List_HomePageFunctions = 3723,
  Core_List_HomePageViews = 3724,
  Core_Dialog_RegisterSample = 3725,
  Core_Dialog_TRIMLocations = 3726,
  Core_List_RiskUsers = 3727,
  Core_Dialog_RegisterDescription = 3728,
  Core_Dialog_ActionsBatchUpdate = 3729,
  Core_Dialog_ChangeText = 3730,
  Core_Form_Subsidy = 3731,
  Core_View_Appointments = 3800,
  Core_View_AppointmentTypes = 3801,
  Core_View_AppointmentAvailabilities = 3802,
  Core_View_AppointmentShopCalendars = 3803,
  Core_View_MyDraftAppointments = 3804,
  Core_View_MyScheduledAppointments = 3805,
  Core_View_MyCancelledAppointments = 3806,
  Core_View_MyCompletedAppointments = 3807,
  Core_View_AllDraftAppointments = 3808,
  Core_View_AllScheduledAppointments = 3809,
  Core_View_AllCancelledAppointments = 3810,
  Core_View_AllCompletedAppointments = 3811,
  Core_Form_ManageAppointment = 3825,
  Core_Form_AppointmentCalendar = 3826,
  Core_Form_ManageAppointmentType = 3827,
  Core_Form_ManageAppointmentAvailability = 3828,
  Core_Form_ManageAppointmentShopCalendar = 3829,
  Core_Form_SystemSettings = 3851,
  Core_Form_Condition = 3852,
  Core_Form_ConditionReference = 3853,
  Core_Dialog_ProvideReason = 3854,
  Core_List_ConditionReference = 3855,
  Core_Dialog_DateFromTo = 3856,
  Core_Dialog_ePlanning = 3857,
  Core_List_PickAsset = 3858,
  Core_Dialog_AssetDetails = 3859,
  Core_Dialog_Objective_SelectParentContainer = 3860,
  Core_List_PickAssetSite = 3861,
  Core_Dialog_MergeContacts = 3862,
  Core_Form_ContactAdministration = 3863,
  Core_Dialog_Signoff_Approval = 3864,
  Core_Dialog_Confirm = 3865,
  Core_List_Attachments = 3866,
  Core_Dialog_RenameAttachment = 3867,
  Core_Dialog_NCRiskAssess = 3868,
  Core_Dialog_InspectionChecklist = 3869,
  Core_Documents_Merge = 3870,
  Core_Dialog_Projects = 3871,
  Core_Form_InspectionPlanner = 3872,
  Core_Dialog_NCItemResult_Animals = 3873,
  Core_Dialog_ManageDocument = 3874,
  Core_List_Documents = 3875,
  Core_List_WorkFlows = 3876,
  Core_Form_WorkFlow = 3877,
  Core_Dialog_WorkFlowProcess = 3878,
  Core_List_TabTable = 3879,
  Core_Form_ManageCheckListQuestion = 3880,
  Core_Dialog_PickDebtor = 3881,
  Core_Dialog_BatchInspection = 3882,
  Core_Dialog_ABNChecker = 3883,
  Core_Form_Undertaking = 3884,
  Core_Dialog_PickNCItemResults = 3885,
  Core_Dialog_PickLegislationTypes = 3886,
  Core_Infringement_Dialog_WorkFlowProcess = 3887,
  Core_Form_SearchWarrant = 3888,
  Core_Dialog_PickSearchWarrants = 3889,
  Core_Dialog_NationalPoliceCheck = 3890,
  Core_Dialog_DriverLicence = 3891,
  CorporateAdmin_View_SiteUsers = 4000,
  CorporateAdmin_Form_SiteUser = 4001,
  CorporateAdmin_Form_SiteUserSecurity = 4002,
  CorporateAdmin_View_SuburbsGeneric = 4003,
  CorporateAdmin_Form_SuburbGeneric = 4004,
  CorporateAdmin_View_SuburbsLicenceManager = 4005,
  CorporateAdmin_Form_SuburbLicenceManager = 4006,
  CorporateAdmin_Dialog_SetPassword = 4007,
  CorporateAdmin_View_FeeSetup = 4008,
  CorporateAdmin_Form_FeeSetup = 4009,
  CorporateAdmin_Form_MangeSecurityTemplate = 4010,
  CorporateAdmin_Form_MangeActions = 4011,
  CorporateAdmin_Form_ImportSuburbsGeneric = 4012,
  CorporateAdmin_Form_GlobalSettings = 4013,
  CorporateAdmin_Form_StreatraderSettings = 4014,
  CorporateAdmin_View_PublicHolidays = 4015,
  CorporateAdmin_Form_PublicHolidays = 4016,
  CorporateAdmin_Form_OrgStructure = 4017,
  CorporateAdmin_View_OrgStructure = 4018,
  CorporateAdmin_List_OrgStructure = 4019,
  CorporateAdmin_View_SiteUsersByOrgStructure = 4020,
  CorporateAdmin_View_SiteUsersBySecurityGroup = 4021,
  CorporateAdmin_Form_Roster = 4022,
  CorporateAdmin_View_PropertyAlert = 4023,
  CorporateAdmin_Form_PropertyAlert = 4024,
  CorporateAdmin_View_SiteUsersBySecurityTemplate = 4025,
  CorporateAdmin_Form_MyProfile = 4026,
  Core_Form_ManageDynamicQuestionList = 4027,
  Core_Dialog_DynamicQuestionList = 4028,
  Core_Dialog_SelectDynamicQuestionList = 4029,
  Core_Dialog_DynamicQuestionResponse = 4030,
  Core_Dialog_HRFComment = 4031,
  Core_Form_AddressArea = 5000,
  Core_Form_MailMergeDocuments = 5001,
  Core_Dialog_PickCouncil = 5002,
  Core_Dialog_PickAddress = 5003,
  Core_Dialog_PickFromNAR = 5004,
  Core_Dialog_PickStreet = 5005,
  Core_Dialog_PickStreetSuburb = 5006,
  CorporateAdmin_View_SysLogs = 5007,
  CorporateAdmin_Form_SysDiag = 5008,
  Telephone_Activity = 5009,
  CorporateAdmin_View_UnprocessedFees = 5010,
  CorporateAdmin_View_ProcessedFees = 5011,
  CorporateAdmin_View_RecycleBin = 5012,
  CorporateAdmin_View_PaymentRegister = 5012,
  Core_Dialog_SiteUser = 5013,
  Core_Dialog_PickFromGoldenUser = 5014,
  CorporateAdmin_Form_Councils = 5015,
  CorporateAdmin_View_Councils = 5016,
  Core_List_MailMergeWordTemplates = 5017,
  Core_List_StateRoads = 5018,
  Core_Form_StateRoads = 5019,
  Core_Dialoge_PickStateRoads = 5020,
  Core_Dialog_SharePointDocumentLibraries = 5021,
  Core_Dialog_SharePointContentTypes = 5022,
  Core_Dialog_Credentials = 5023,
  CorporateAdmin_View_JobQueue = 5024,
  Core_Dialog_Keyword_BatchUpdate = 5025,
  Core_Dialog_NC_CompletionChecklist = 5026,
  Core_Dialog_PickSuburb = 5027,
  CorporateAdmin_View_Regions = 5028,
  CorporateAdmin_Form_Region = 5029,
  Core_Form_PPRAppeal = 5030,
  Core_Dialog_BlobStore = 5031,
  CorporateAdmin_View_Divisions = 5032,
  CorporateAdmin_Form_Division = 5033,
  Core_Dialog_PickInspections = 5034,
  Core_Form_Interview = 5035,
  Core_List_InterviewTypes = 5036,
  Core_Form_InterviewType = 5037,
  Core_Dialog_PickInterviewType = 5038,
  Core_List_PickContact_ProcessExternal = 5039,
  Core_Dialog_MapshareAddressSelect = 5040,
  CorporateAdmin_View_WinLogs = 5041,
  Core_List_HelpDocuments = 5042,
  Core_Form_HelpDocument = 5043,
  Core_Dialog_HelpDocuments = 5044,
  CorporateAdmin_View_JobQueue_Failed = 5045,
  Core_Dialog_PortalContactStatus = 5046,
  Core_Dialog_RegisterStatus = 5047,
  Core_Dialog_JobQueueHelp = 5048,
  Core_Dialog_NSWPointAddressSelect = 5049,
  Core_List_PickContactRelationshipType = 5050,
  Core_Form_Status = 5051,
  Core_Form_Investigation = 5052,
  Core_Dialog_PickInvestigations = 5053,
  Core_Dialog_ContactExtension = 5054,
  CorporateAdmin_View_ContactExportLog = 5055,
  Core_View_ChangedContactsQueue = 5056,
  Core_Dialog_ContactCompare = 5057,
  Core_Dialog_DiscountFee = 5058,
  Core_Dialog_InfoXpertFileNumber = 5059,
  Core_Dialog_NC_CloseReason = 5060,
  Core_Dialog_AppealComplete = 5061,
  CorporateAdmin_View_AgentLogs = 5062,
  Core_Dialog_DownloadFile = 5063,
  CorporateAdmin_View_CouncilAddresses = 5064,
  Core_Dialog_PickNCItemResultsByOrganisation = 5065,
  Core_Form_PaymentPlan = 5066,
  Core_Dialog_Education = 5067,
  Core_Form_EducationProvider = 5068,
  Core_Dialog_PickEducationProvider = 5069,
  Core_Form_Education = 5070,
  Core_Dialog_Allegation = 5071,
  Core_View_EducationProviders = 5072,
  Core_Dialog_NCItemResult_Species_LLS = 5073,
  Core_List_Species = 5074,
  Core_Form_Species = 5075,
  Core_Form_ManageDynamicQuestion = 5076,
  Core_Dialog_ImportIP = 5077,
  Core_List_ImportIPFromMap = 5078,
  Core_List_InterestedParties = 5079,
  Core_Bulk_Form_Action = 5080,
  Core_Dialog_Close = 5081,
  Core_Dialog_PickBondType = 5082,
  CorporateAdmin_View_NotSentToFinance = 5083,
  CorporateAdmin_View_SentToFinance = 5084,
  CorporateAdmin_View_Debtors_WithoutDebtorNumber = 5085,
  CorporateAdmin_View_Debtors_NotSentToFinance = 5086,
  CorporateAdmin_View_Debtors_SentToFinance = 5087,
  CorporateAdmin_View_NotSentToCashLink = 5088,
  CorporateAdmin_View_SentToCashLink = 5089,
  Core_Form_Invoice = 5090,
  CorporateAdmin_View_Invoices = 5091,
  Core_Form_ManageLocationRegister = 5092,
  Core_View_LocationRegister = 5093,
  Core_Dialog_PickFromACOSiteUser = 5094,
  Core_List_SubsidyTypes = 5095,
  Core_List_FeeSetup = 5096,
  Core_Form_FeeSetup = 5097,
  Core_Form_QualificationApproval = 5098,
  Core_Dialog_PickEducation = 5099,
  CorporateAdmin_View_InvoiceNotSentToFinance = 5100,
  CorporateAdmin_View_InvoiceSentToFinance = 5101,
  Core_Form_PreviewDocument = 5102,
  CRS_Form_Centre = 20000,
  CRS_Form_Registration = 20001,
  CRS_Form_RegistrationCentreBasedCare = 20002,
  CRS_Form_RegistrationHomeBasedCare = 20003,
  CRS_Form_Registration3yoKindergarten = 20004,
  CRS_Form_Registration4yoKindergarten = 20005,
  CRS_List_GroupCentreBasedCare = 20006,
  CRS_List_GroupHomeBasedCare = 20007,
  CRS_List_Group3yoKindergarten = 20008,
  CRS_List_Group4yoKindergarten = 20009,
  CRS_Form_Group = 20010,
  CRS_Form_Session = 20011,
  CRS_Form_Room = 20012,
  CRS_Form_Characteristics = 20013,
  CRS_Dialog_Comment = 20014,
  CRS_Form_Allocation = 20015,
  CRS_Form_AllocationSummary = 20016,
  CRS_Form_WeightAssignment = 20017,
  CRS_Form_AllocationSave = 20018,
  CRS_Dialog_RegistrationChangeDate = 20019,
  CRS_View_Allocations = 20020,
  CRS_Dialog_ListMailMergeDocuments = 20021,
  CRS_Dialog_DownloadMailMerge = 20022,
  CRS_Form_CategoryWeightAssignment = 20023,
  CRS_View_Registration = 20024,
  CRS_View_AllByPriority = 20025,
  CRS_View_AllByStatus = 20026,
  CRS_View_AllPlaced = 20027,
  CRS_View_AllByPreferences = 20028,
  CRS_View_AllOffers = 20029,
  CRS_View_Centres = 20030,
  CRS_HBCCentreReferred = 20031,
  CRS_View_WeightAssignment = 20032,
  CRS_View_CategoryWeightAssignment = 20033,
  CRS_Search = 20034,
  CRS_Reports = 20035,
  CRS_Form_Question = 20036,
  CRS_Dialog_DownloadMailMergeTemplate = 20037,
  CRS_Dialog_RandomiseChildrenInService = 20038,
  CRS_Dialog_FindDuplicateRegistrations = 20039,
  CRS_View_CentreVacancies = 20040,
  CRS_Form_SystemSettings = 20041,
  CRS_View_LegacyRegistration = 20042,
  CRS_Form_LegacyRegistration = 20043,
  CRS_Dialog_PickCentreGroups = 20044,
  CRS_Dialog_PickPreferredCentres = 20045,
  CRS_Dialog_PickHBCCentreGroups = 20046,
  CRS_View_InfoWorkflow = 20047,
  CRS_Form_WaitingList = 20048,
  CRS_Dialog_Pick3yoPreferredCentres = 20049,
  CRS_Dialog_Pick4yoPreferredCentres = 20050,
  CRS_Dialog_POAWeightCalculation = 20051,
  CRS_Dialog_AddRemoveSiblings = 20052,
  CRS_View_CurrenltyPlaced = 20053,
  CRS_View_OutStandingFees = 20054,
  CRS_View_PaymentPlan = 20055,
  CRS_Dialog_CalculateTermFee = 20056,
  CRS_View_SubsidyCards = 20057,
  CRS_Dialog_ImportNameNumber = 20058,
  CRS_View_AllocationByService = 20059,
  CRS_View_AllocationByYear = 20060,
  CRS_View_AllocationRoundByYear = 20061,
  CRS_View_AllocationRoundByService = 20062,
  CRS_View_ExportDebtors = 20063,
  CRS_View_NotExportedDebtors = 20064,
  CRS_View_ImportPortalApplications = 20065,
  CRS_View_RegistrationComments = 20066,
  CRS_View_RegistrationDocuments = 20067,
  CRS_View_RegistrationActions = 20068,
  CRS_View_RegistrationFees = 20069,
  CRS_Form_ProcessChangeOf4yoService = 20070,
  CRS_Form_ProcessChangeOf3yoService = 20071,
  CRS_Form_ProcessChangeOfCBCService = 20072,
  CRS_Form_ProcessChangeOfHBCService = 20073,
  CRS_Form_ProcessChangeOfRegistration = 20074,
  CRS_Dialog_PickExistingContact = 20075,
  CRS_Form_ProcessOfferResponse = 20076,
  CRS_Form_ProcessChangeOfProfile = 20077,
  CRS_Form_ServiceRequest4yo = 20078,
  CRS_Form_ServiceRequest3yo = 20079,
  CRS_Form_ServiceRequestCBC = 20080,
  CRS_Form_ServiceRequestHBC = 20081,
  CRS_View_ZeroInvoice = 20082,
  CRS_Dialog_PickAllocationPriority = 20083,
  CRS_Form_QuestionGroup = 20084,
  CRS_View_QuestionGroups = 20085,
  CRS_View_PortalPayments = 20086,
  CRS_View_PortalAccountManagement = 20087,
  CRS_Dialog_RegistrationSummary = 20088,
  CRS_SearchParent = 20089,
  CRS_Dialog_SelectDebtorNumber = 20090,
  CRS_Dialog_ChangeDebtorNumber = 20091,
  CRS_Form_Fees = 20092,
  CRS_Form_Refund = 20093,
  CRS_Dialog_CalculateIndividualTermFee = 20094,
  CRS_View_ZeroFees4yo = 20095,
  CRS_Dialog_ResetFees4yo = 20096,
  CRS_Dialog_ResetBulkFees4yo = 20097,
  CRS_Dialog_Transfer = 20098,
  CRS_Dialog_ResetFees = 20099,
  CRS_View_SignedPortalApplications = 20100,
  CRS_Dialog_OfferResponse = 20101,
  CRS_View_NotExportedServices = 20102,
  MCH_View_Registrations = 22501,
  MCH_View_Centres = 22502,
  MCH_Form_Centre = 22503,
  MCH_Form_Registration = 22504,
  MCH_Form_Appointment = 22505,
  MCH_View_AllCancelledAppt = 22506,
  MCH_View_AllCompletedAppt = 22507,
  MCH_View_AllDraftAppt = 22508,
  MCH_View_AllScheduledAppt = 22509,
  MCH_View_PersonalCancelledAppt = 22510,
  MCH_View_PersonalCompletedAppt = 22511,
  MCH_View_PersonalDraftAppt = 22512,
  MCH_View_PersonalScheduledAppt = 22513,
  MCH_View_Appointments = 22514,
  MCH_Dialog_Contact = 22515,
  MCH_Form_ManageAppointmentAvailability = 22516,
  MCH_Form_ManageAppointmentType = 22517,
  MCH_View_CentreCalendar = 22518,
  MCH_View_NurseCalendar = 22519,
  MCH_View_AppointmentType = 22520,
  MCH_Form_SystemSettings = 22521,
  MCH_Form_AppointmentCalendar = 22522,
  WasteWater_Form_System = 30000,
  WasteWater_Form_SystemType = 30001,
  WasteWater_List_System = 30002,
  WasteWater_List_SystemType = 30003,
  WasteWater_Form_SystemSettings = 30004,
  WasteWater_List_Notices = 30005,
  WasteWater_List_Complaints = 30006,
  WasteWater_List_Inspections = 30007,
  WasteWater_List_NCItem = 30008,
  WasteWater_List_Fees = 30009,
  WasteWater_List_Conditions = 30010,
  WasteWater_Dialog_SystemTypes = 30011,
  WasteWater_Dialog_AssessmentDetails = 30012,
  WasteWater_Dialog_PermitDetails = 30013,
  WasteWater_Dialog_SystemDetails = 30014,
  WasteWater_Form_Product = 30015,
  WasteWater_Form_Scheme = 30016,
  WasteWater_Form_Installation = 30017,
  WasteWater_List_Samples = 30018,
  WasteWater_Dialog_ListInstallation = 30019,
  WasteWater_Dialog_ListSchemes = 30020,
  WasteWater_Dialog_ListProducts = 30021,
  WasteWater_Dialog_PermitConditions = 30022,
  WasteWater_Form_StatClock = 30023,
  WasteWater_Dialog_ExtensionofTime = 30024,
  WasteWater_Dialog_ListSystems = 30025,
  WasteWater_List_InvoicesNotSentToFinance = 30026,
  WasteWater_List_InvoicesSentToFinance = 30027,
  HealthManager_Form_PremisesType = 40001,
  HealthManager_List_PremisesType = 40002,
  HealthManager_Form_Premises = 40003,
  HealthManager_List_Premises = 40004,
  HealthManager_Form_SystemSettings = 40005,
  HealthManager_List_Notices = 40006,
  HealthManager_List_Complaints = 40007,
  HealthManager_List_Inspections = 40008,
  HealthManager_List_Recalls = 40009,
  HealthManager_List_Fees = 40010,
  HealthManager_List_Samples = 40011,
  HealthManager_Dialog_PremisesTypes = 40012,
  HealthManager_Form_PremisesClassification = 40013,
  HealthManager_List_PremisesClassification = 40014,
  HealthManager_Form_RiskClassification = 40015,
  HealthManager_List_RiskClassification = 40016,
  HealthManager_Dialog_ClosePremises = 40017,
  HealthManager_Dialog_AdditionalDetails = 40018,
  HealthManager_Dialog_FoodVehicle = 40019,
  HealthManager_Dialog_FoodStall = 40020,
  HealthManager_Dialog_Conditions = 40021,
  HealthManager_Dialog_Pool = 40022,
  HealthManager_Dialog_FoodPremises = 40023,
  HealthManager_Dialog_TobaccoPremises = 40024,
  HealthManager_Form_FSP = 40025,
  HealthManager_Form_FSPAudit = 40026,
  HealthManager_Form_FSRLog = 40027,
  HealthManager_Form_FSRLog_ViewFile = 40028,
  HealthManager_List_FSRLog = 40029,
  HealthManager_Form_FSRConsole = 40030,
  HealthManager_Dialog_FoodHygieneAssessment = 40031,
  HealthManager_Dialog_FoodHygieneInspection = 40032,
  HealthManager_Dialog_HealthHygieneAssessment = 40033,
  HealthManager_Dialog_PAAssessment = 40034,
  HealthManager_Dialog_Portal = 40035,
  HealthManager_Dialog_BatchUpdateType = 40036,
  HealthManager_Dialog_FoodSafetyRating = 40037,
  HealthManager_Dialog_PoolAssessment = 40038,
  HealthManager_Print_Premises = 40039,
  HealthManager_List_Conditions = 40040,
  HealthManager_Form_VolumeScore = 40041,
  HealthManager_List_VolumeScore = 40042,
  HealthManager_Form_BusinessActivity = 40043,
  HealthManager_List_BusinessActivity = 40044,
  HealthManager_Dialog_BusinessActivityCalculator = 40045,
  HealthManager_Dialog_TradeWasteSiteVisitChecklist = 40046,
  HealthManager_Dialog_FoodPremisesChecklist = 40047,
  HealthManager_Dialog_FoodPremisesStonnington = 40048,
  HealthManager_Dialog_FoodSafetyAssessment = 40049,
  HealthManager_Dialog_FoodSafetyAssessmentRiskOnly = 40050,
  HealthManager_Dialog_PAAssessmentV2 = 40054,
  HealthManager_Dialog_HealthCare = 40051,
  HealthManager_Dialog_RenewalFormReceived = 40052,
  HealthManager_Dialog_ApplicationDetails = 40053,
  HealthManager_Dialog_FoodSafetyRatingV2 = 40055,
  HealthManager_Dialog_FoodSafty_Checklist_Banyule = 40056,
  HealthManager_Dialog_FoodPremisesAssessment = 40057,
  HealthManager_Dialog_FoodSafetyFollowUp_RiskAssessment = 40058,
  HealthManager_Dialog_Scoring = 40059,
  HealthManager_Dialog_FoodSafetyAssessment_SurfCoast = 40060,
  HealthManager_Dialog_FoodSafety_Willoughby = 40061,
  HealthManager_Dialog_Temporary_FoodStall_Safety_Assessment = 40062,
  HealthManager_Dialog_HealthPremisesAssessment_Willoughby = 40063,
  HealthManager_Dialog_FoodPremisesChecklist_Bayside = 40064,
  HealthManager_Dialog_FoodPremisesChecklist_GoldCoast = 40065,
  HealthManager_Dialog_TobaccoRetailerInspectionChecklist_GoldCoast = 40066,
  HealthManager_Dialog_DrinkingWater = 40067,
  HealthManager_List_PremisesDrinkingWater = 40068,
  HealthManager_List_Finance = 40069,
  HealthManager_Dialog_TobaccoAssessment_Darebin = 40070,
  HealthManager_Dialog_PublicHealthWellbeingAssessment_Darebin = 40071,
  HealthManager_Dialog_PrescribedAssessment_Darebin = 40072,
  HealthManager_Dialog_FoodPremisesAssessment_NSW = 40073,
  HealthManager_List_NotExportedDebtors = 40074,
  HealthManager_List_ExportedDebtors = 40075,
  HealthManager_Dialog_SAHealthFoodAudit = 40076,
  HealthManager_Dialog_SAHealth_Import_FBNData = 40077,
  HealthManager_Dialog_TemporaryFoodStallInspection_ACTHealth = 40078,
  HealthManager_Dialog_FoodPremisesFitOutInspection_ACTHealth = 40079,
  HealthManager_Dialog_TobaccoOutdoorDiningAndDrinkingAssessment_MV = 40080,
  HealthManager_Dialog_TobaccoActRetailerComplianceAssessment_MV = 40081,
  HealthManager_Dialog_HealthHygieneAssessmentV2 = 40082,
  HealthManager_Dialog_FoodPremisesChecklist_Bayside_V2 = 40083,
  HealthManager_Dialog_FoodSafetyRatingV3 = 40084,
  HealthManager_List_InvoicesNotSentToFinance = 40085,
  HealthManager_List_InvoicesSentToFinance = 40086,
  HealthManager_Dialog_FoodHygieneAssessmentWhiteHorseV2 = 40087,
  DisRes_Form_Permit = 45000,
  DisRes_Form_PermitType = 45001,
  DisRes_Form_ImportResidentAreaParkingDefinition = 45002,
  DisRes_Form_ResidentAreaParkingDefinition = 45003,
  DisRes_Form_ImportStaffAreaParkingDefinition = 45004,
  DisRes_Form_StaffAreaParkingDefinition = 45005,
  DisRes_Form_SystemSettings = 45006,
  DisRes_Form_PropertyRestrictions = 45007,
  DisRes_Form_PropertyExceptions = 45008,
  DisRes_List_PermitType = 45009,
  DisRes_Dialog_PermitAreaManualEntry = 45010,
  DisRes_List_ResidentAllowedPermitArea = 45011,
  DisRes_List_StaffAllowedPermitArea = 45012,
  DisRes_List_Status = 45013,
  DisRes_Dialog_CancelPermit = 45014,
  DisRes_Dialog_PermitPayment = 45015,
  DisRes_Dialog_PermitDisabledQuestion = 45016,
  DisRes_Dialog_IconHelp = 45017,
  DisRes_Dialog_PermitReissue = 45018,
  DisRes_Dialog_ReverseReason = 45019,
  DisRes_Dialog_DownloadExportFile = 45020,
  DisRes_Dialog_PermitQuote = 45021,
  DisRes_Dialog_SetIssueDate = 45022,
  DisRes_Dialog_PrintPermit = 45023,
  DisRes_View_AdminExportData = 45024,
  DisRes_View_AllDisabledPermit = 45025,
  DisRes_View_AllStaff = 45026,
  DisRes_View_Inspections = 45027,
  DisRes_View_Override = 45028,
  DisRes_View_NotIssuedPermit = 45029,
  DisRes_View_NotPrintedPermit = 45030,
  DisRes_View_AllPermits = 45031,
  DisRes_View_Workstats = 45032,
  DisRes_View_RenewPermits = 45033,
  DisRes_View_PermitTypes = 45034,
  DisRes_View_ResidentAreaParkingDefinitions = 45035,
  DisRes_View_StaffAreaParkingDefinitions = 45036,
  DisRes_View_ParkingRestrictions = 45037,
  DisRes_View_ParkingExceptions = 45038,
  DisRes_View_AdminRenewals = 45039,
  DisRes_View_RenewalRequirePermit = 45040,
  DisRes_View_RenewalFirstNotice = 45041,
  DisRes_View_StaffRenewal = 45042,
  DisRes_View_Fees = 45043,
  DisRes_View_NotIssuedPaidPermit = 45044,
  DisRes_Dialog_PermitDetails = 45045,
  DisRes_View_PermitCancelled = 45046,
  DisRes_View_PermitExpired = 45047,
  AMS_View_Departments = 46000,
  AMS_Form_Department = 46001,
  AMS_Form_AppointmentType = 46002,
  AMS_View_AllAppointments = 46003,
  AMS_Form_Appointment = 46004,
  AMS_Form_DepartmentAppointmentPlan = 46005,
  AMS_Form_DepartmentAppointmentCalendar = 46006,
  AMS_View_FutureAppointments = 46007,
  AMS_View_NotYetApprovedAppointments = 46008,
  AMS_View_NewAppointments = 46009,
  AMS_View_AllAppointments_Calendar = 46010,
  EMS_View_Facilities = 47000,
  EMS_Form_Facilities = 47001,
  EMS_View_AllEventBookings = 47002,
  EMS_Form_EventBooking = 47003,
  EMS_View_FutureEventBookings = 47004,
  EMS_View_NotYetApprovedEventBookings = 47005,
  EMS_View_NewEventBookings = 47006,
  EMS_View_AllEventBookings_Calendar = 47007,
  EMS_Form_FacilityBookingPlan = 47008,
  EMS_Form_FacilityBookingCalendar = 47009,
  EMS_Dialog_FacilityPhoto = 47010,
  EMS_Form_SystemSettings = 47011,
  EMS_Form_BookingInvoice = 47012,
  EMS_Form_RepeatBookingCalendar = 47013,
  EMS_Dialog_BookingDate = 47014,
  EMS_View_EventTypes = 47015,
  EMS_Form_EventType = 47016,
  EMS_Dialog_EventTypeList = 47017,
  EMS_List_Fees = 47018,
  EMS_Form_GroupEventBooking = 47019,
  EMS_Form_MasterFacility = 47020,
  EMS_View_MasterFacilities = 47021,
  EMS_Dialog_FacilityBooking = 47022,
  EMS_Form_FacilityFeature = 47023,
  EMS_View_FacilityFeatures = 47024,
  EMS_Dialog_FacilityBookingDetails = 47025,
  EMS_Form_Estimate = 47026,
  EMS_Form_FacilityBookingFeeEstimate = 47027,
  EMS_Form_BookingFeeEstimate = 47028,
  EMS_View_DaftEventBookings = 47029,
  EMS_View_Finance = 47030,
  EMS_View_CRMSDraftEventBookings = 47031,
  EMS_List_InvoicesNotSentToFinance = 47032,
  EMS_List_InvoicesSentToFinance = 47033,
  Menu_CRS_Vertical = 52007,
  Menu_CUSTOMERSERVICE_Vertical = 52008,
  Menu_COLDCHAIN_Vertical = 52009,
  Menu_ITSUPPORT_Vertical = 52010,
  Menu_CORPADMIN_Vertical = 52011,
  Menu_ACTIONS_Vertical = 52012,
  Menu_HARDRUBBISH_Vertical = 52013,
  Menu_EMS_Vertical = 52014,
  Menu_AMS_Vertical = 52015,
  Menu_PHONEMESSAGE_Vertical = 52016,
  Menu_PAYROLL_Vertical = 52017,
  Menu_CRSCENTREACCESS_Vertical = 52018,
  Menu_FLEET_Vertical = 52019,
  Dashboard = 53001,
  CUSTOMERSERVICE_View_Event = 55001,
  CUSTOMERSERVICE_View_ServiceStandard = 55002,
  CUSTOMERSERVICE_View_ServiceStandardCategory = 55003,
  CUSTOMERSERVICE_View_ColdChain_ServiceProviders = 55004,
  CUSTOMERSERVICE_View_LookupTable = 55005,
  CUSTOMERSERVICE_View_ColdChain_Vaccines = 55006,
  CUSTOMERSERVICE_View_ColdChain_TemperatureDurationRanges = 55007,
  CUSTOMERSERVICE_View_ColdChain_VaccineActions = 55008,
  CUSTOMERSERVICE_View_BulletinBoard_Released = 55009,
  CUSTOMERSERVICE_View_UnresolvedEvents = 55010,
  CUSTOMERSERVICE_View_ColdChain_GPDivisions = 55011,
  CUSTOMERSERVICE_View_ColdChain_PortalBreaches = 55012,
  CUSTOMERSERVICE_View_ColdChain_Events = 55013,
  CUSTOMERSERVICE_View_ColdChain_UnresolvedEvents = 55014,
  CUSTOMERSERVICE_View_ColdChain_MasterEvents = 55015,
  CUSTOMERSERVICE_View_UnacknowledgedEvents = 55016,
  CUSTOMERSERVICE_View_CorrespondenceRequiredEvents = 55017,
  CUSTOMERSERVICE_View_MyUnresolvedEvents = 55018,
  CUSTOMERSERVICE_View_CSAdmin = 55019,
  CUSTOMERSERVICE_View_LocationRegister = 55020,
  CUSTOMERSERVICE_View_UnresolvedEventsBySpecialInterest = 55021,
  CUSTOMERSERVICE_View_ITSupport_UnallocatedEvents = 55022,
  CUSTOMERSERVICE_View_ITSupport_AllEvents = 55023,
  CUSTOMERSERVICE_View_ITSupport_UnresolvedEvents = 55024,
  CUSTOMERSERVICE_View_Drafts = 55025,
  CUSTOMERSERVICE_View_UnresolvedResentEvents = 55026,
  CUSTOMERSERVICE_View_UnresolvedSafetyEvents = 55027,
  CUSTOMERSERVICE_View_UnresolvedVIPEvents = 55028,
  CUSTOMERSERVICE_View_UnresolvedRiskEvents = 55029,
  CUSTOMERSERVICE_View_KBAlerts = 55030,
  CUSTOMERSERVICE_View_BulletinBoard_Unreleased = 55031,
  CUSTOMERSERVICE_View_UnresolvedAssetEvents = 55032,
  CUSTOMERSERVICE_View_AllAssetEvents = 55033,
  CUSTOMERSERVICE_View_HardRubbishCollectionsLookup = 55034,
  CUSTOMERSERVICE_View_UnresolvedHardRubbishEvents = 55035,
  CUSTOMERSERVICE_View_AllHardRubbishEvents = 55036,
  CUSTOMERSERVICE_View_NotCollectedHardRubbishEvents = 55037,
  CUSTOMERSERVICE_View_BulletinBoard_DueForReview = 55038,
  CUSTOMERSERVICE_View_BulletinBoard_Expired = 55039,
  CUSTOMERSERVICE_View_BulletinBoard_UnderReview = 55040,
  CUSTOMERSERVICE_View_BulletinBoard_Reviewed = 55041,
  CUSTOMERSERVICE_View_BulletinBoard_Drafts = 55042,
  CUSTOMERSERVICE_View_Roster = 55043,
  CUSTOMERSERVICE_View_UnresolvedAfterHoursServiceEvents = 55044,
  CUSTOMERSERVICE_View_AllAfterHoursServiceEvents = 55045,
  CUSTOMERSERVICE_View_UnresolvedCouncillorEvents = 55046,
  CUSTOMERSERVICE_View_AllCouncillorEvents = 55047,
  CUSTOMERSERVICE_View_BulletinBoard_NoServiceStandard = 55048,
  CUSTOMERSERVICE_View_UnresolvedQualityCheckEvents = 55049,
  CUSTOMERSERVICE_View_AllQualityCheckEvents = 55050,
  CUSTOMERSERVICE_View_MyCoordinatorUnresolvedEvents = 55051,
  CUSTOMERSERVICE_View_MyCoordinatorAllEvents = 55052,
  CUSTOMERSERVICE_View_AllIncidents = 55053,
  CUSTOMERSERVICE_View_UnresolvedIncidents = 55054,
  CUSTOMERSERVICE_View_UnresolvedEventsByAssignedToOrgStructure = 55055,
  CUSTOMERSERVICE_View_DeactivatedServiceStandards = 55056,
  CUSTOMERSERVICE_View_BulletinBoard_ReleasedByOrg3 = 55057,
  CUSTOMERSERVICE_View_UnresolvedEventsByAsset = 55058,
  CUSTOMERSERVICE_View_AllEventsByAsset = 55059,
  CUSTOMERSERVICE_View_AllPhoneMessages = 55060,
  CUSTOMERSERVICE_View_OpenPhoneMessages = 55061,
  CUSTOMERSERVICE_View_UnresolvedExternalUserEvents = 55062,
  CUSTOMERSERVICE_View_AllExternalUserEvents = 55063,
  CUSTOMERSERVICE_View_UnresolvedMyCouncillorEvents = 55064,
  CUSTOMERSERVICE_View_Payroll_AllEvents = 55065,
  CUSTOMERSERVICE_View_Payroll_UnresolvedEvents = 55066,
  CUSTOMERSERVICE_View_Payroll_Drafts = 55067,
  CUSTOMERSERVICE_View_Payroll_MyUnresolvedEvents = 55068,
  CUSTOMERSERVICE_View_Payroll_AllMyEvents = 55069,
  CUSTOMERSERVICE_View_Payroll_MyDrafts = 55070,
  CUSTOMERSERVICE_View_Inspections = 55071,
  CUSTOMERSERVICE_View_KBTopics = 55072,
  CUSTOMERSERVICE_View_AllSafetyEvents = 55073,
  CUSTOMERSERVICE_View_AllRiskEvents = 55074,
  CUSTOMERSERVICE_View_AllVIPEvents = 55075,
  CUSTOMERSERVICE_View_UnresolvedEventsByRecipient = 55076,
  CUSTOMERSERVICE_View_Notices = 55077,
  CUSTOMERSERVICE_View_MyUnresolvedEvents_DWCMS = 55078,
  CUSTOMERSERVICE_View_UnresolvedEvents_DWCMS = 55079,
  CUSTOMERSERVICE_View_Event_DWCMS = 55080,
  CUSTOMERSERVICE_View_InvestigationsByDate = 55081,
  CUSTOMERSERVICE_View_SendToWorkOrderSystem = 55082,
  CUSTOMERSERVICE_View_LastUpdatedByWorkOrderSystem = 55083,
  CUSTOMERSERVICE_View_RejectedByWorkOrderSystem = 55084,
  CUSTOMERSERVICE_View_CompletedByWorkOrderSystem = 55085,
  CUSTOMERSERVICE_View_InvestigationsByReferenceNumber = 55086,
  CUSTOMERSERVICE_View_InvestigationsByInvestigator = 55087,
  CUSTOMERSERVICE_View_InvestigationsBySource = 55088,
  CUSTOMERSERVICE_View_InvestigationsByStatus = 55089,
  CUSTOMERSERVICE_View_OpenInvestigationsByDate = 55090,
  CUSTOMERSERVICE_View_OpenInvestigationsByReferenceNumber = 55091,
  CUSTOMERSERVICE_View_OpenInvestigationsByInvestigator = 55092,
  CUSTOMERSERVICE_View_OpenInvestigationsBySource = 55093,
  CUSTOMERSERVICE_View_OpenInvestigationsByStatus = 55094,
  CUSTOMERSERVICE_View_KBStats = 55095,
  CUSTOMERSERVICE_Form_Event = 55101,
  CUSTOMERSERVICE_Form_ServiceStandard = 55102,
  CUSTOMERSERVICE_Form_SystemSettings = 55103,
  CUSTOMERSERVICE_Form_ServiceStandardCategory = 55104,
  CUSTOMERSERVICE_Form_ServiceStandardSubCategory = 55105,
  CUSTOMERSERVICE_Form_ColdChain_ServiceProvider = 55106,
  CUSTOMERSERVICE_Form_LookupTable = 55107,
  CUSTOMERSERVICE_Form_ColdChain_Vaccine = 55108,
  CUSTOMERSERVICE_Form_ColdChain_TemperatureDurationRange = 55109,
  CUSTOMERSERVICE_Form_ColdChain_VaccineAction = 55110,
  CUSTOMERSERVICE_Form_BulletinBoard = 55111,
  CUSTOMERSERVICE_Form_ColdChain_GPDivision = 55112,
  CUSTOMERSERVICE_Form_ColdChain_SystemSettings = 55113,
  CUSTOMERSERVICE_Form_ServiceStandardBatchUpdates = 55114,
  CUSTOMERSERVICE_Form_LocationRegister = 55115,
  CUSTOMERSERVICE_Form_EventBatchUpdates = 55116,
  CUSTOMERSERVICE_Form_ITSupport_NewEvent = 55117,
  CUSTOMERSERVICE_Form_ITSupport_EventSubmitted = 55118,
  CUSTOMERSERVICE_Form_KBAlert = 55119,
  CUSTOMERSERVICE_Form_HardRubbishCollectionsLookup = 55120,
  CUSTOMERSERVICE_Form_Roster = 55121,
  CUSTOMERSERVICE_Form_SimpleEvent = 55122,
  CUSTOMERSERVICE_Form_PhoneMessage = 55123,
  CUSTOMERSERVICE_Form_KBTopic = 55124,
  DWCMS_CUSTOMERSERVICE_Form_Event = 55125,
  CUSTOMERSERVICE_Form_Issue = 55126,
  CUSTOMERSERVICE_List_ServiceStandard = 55201,
  CUSTOMERSERVICE_List_ContactWithPreview = 55202,
  CUSTOMERSERVICE_List_ColdChain_ServiceProvider = 55203,
  CUSTOMERSERVICE_List_Event = 55204,
  CUSTOMERSERVICE_List_Contact = 55205,
  CUSTOMERSERVICE_List_ColdChain_GPDivision = 55206,
  CUSTOMERSERVICE_List_EMTContact = 55207,
  CUSTOMERSERVICE_List_LookupValue = 55208,
  CUSTOMERSERVICE_List_LocationRegister = 55209,
  CUSTOMERSERVICE_List_RecentEvent = 55210,
  CUSTOMERSERVICE_List_KBPage = 55211,
  CUSTOMERSERVICE_List_HardRubbishCollection = 55212,
  CUSTOMERSERVICE_Mobile_Events = 55301,
  CUSTOMERSERVICE_Mobile_CloseEvent = 55302,
  CUSTOMERSERVICE_Dialog_EventBatchClose = 55401,
  CUSTOMERSERVICE_Dialog_EventBatchAcknowledge = 55402,
  CUSTOMERSERVICE_Dialog_AssetDetails = 55403,
  CUSTOMERSERVICE_Dialog_NewRequestItemsToCopy = 55404,
  CUSTOMERSERVICE_Dialog_EventBatchServiceDirection = 55405,
  CUSTOMERSERVICE_Dialog_EventAllocatePerson = 55406,
  CUSTOMERSERVICE_Dialog_EventClose = 55407,
  CUSTOMERSERVICE_Dialog_EventReschedule = 55408,
  CUSTOMERSERVICE_Dialog_EventUpdateStatus = 55409,
  CUSTOMERSERVICE_Dialog_EventReopen = 55410,
  CUSTOMERSERVICE_Dialog_BulletinBoard = 55411,
  CUSTOMERSERVICE_Dialog_PrintEvent = 55412,
  CUSTOMERSERVICE_Dialog_EventResponses = 55413,
  CUSTOMERSERVICE_Dialog_EventSafety = 55414,
  CUSTOMERSERVICE_Dialog_KnowledgeBase_AddTopic = 55415,
  CUSTOMERSERVICE_Dialog_KnowledgeBaseAlert_AddTerm = 55416,
  CUSTOMERSERVICE_Dialog_EventRecategorisationComment = 55417,
  CUSTOMERSERVICE_Dialog_EventMessage = 55418,
  CUSTOMERSERVICE_Dialog_HardRubbishMessagePrompt = 55419,
  CUSTOMERSERVICE_Dialog_EventQualityCheck = 55420,
  CUSTOMERSERVICE_Dialog_EventSpecialComment = 55421,
  CUSTOMERSERVICE_Dialog_AnimalAttackDetail = 55422,
  CUSTOMERSERVICE_Dialog_ImportServiceStandardsFromFile = 55423,
  CUSTOMERSERVICE_Dialog_PLCSequence = 55424,
  CUSTOMERSERVICE_Dialog_PLCFactor = 55425,
  CUSTOMERSERVICE_Dialog_PLCAction = 55426,
  CUSTOMERSERVICE_Dialog_RiskRankingTable = 55427,
  CUSTOMERSERVICE_Dialog_ComplaintsHandlingDetail = 55428,
  CUSTOMERSERVICE_Dialog_ImportEventsFromFile = 55429,
  CUSTOMERSERVICE_Dialog_KnowledgeBase_BatchUpdate = 55430,
  CUSTOMERSERVICE_Dialog_EventCloseSummary = 55431,
  CUSTOMERSERVICE_Dialog_EventChangeDetails = 55432,
  DevApps_Form_Application = 56001,
  DevApps_Form_ApplicationCategory = 56002,
  DevApps_List_Application = 56003,
  DevApps_List_ApplicationCategory = 56004,
  DevApps_List_FurtherInfoRequests = 56005,
  DevApps_List_Advertising = 56006,
  DevApps_List_Notices = 56007,
  DevApps_List_Complaints = 56008,
  DevApps_List_Inspections = 56009,
  DevApps_Form_SystemSettings = 56010,
  DevApps_Dialog_AllocateToPlanner = 56011,
  DevApps_Dialog_PlannerAssessment = 56012,
  DevApps_Dialog_ApplicationBuildingDetails = 56013,
  DevApps_List_ConditionReference = 56014,
  DevApps_Form_ConditionReference = 56015,
  DevApps_Form_ApplicationCondition = 56016,
  DevApps_Dialog_ApplicationCondition = 56017,
  DevApps_List_InterestedParties = 56018,
  DevApps_Dialog_IssueBuildingPermit = 56019,
  DevApps_Dialog_IssueBuildingCertificate = 56020,
  DevApps_Dialog_Issue = 56021,
  DevApps_Form_CalculatedFeeSetup = 56022,
  DevApps_List_CalculatedFeeSetup = 56023,
  DevApps_Dialog_CalculatedFee = 56024,
  DevApps_List_Appeal = 56025,
  DevApps_List_Fees = 56026,
  DevApps_List_UnrelatedComplaints = 56027,
  DevApps_List_InterestedPartiesFromApp = 56028,
  TP_Form_Application = 57001,
  TP_Form_ApplicationCategory = 57002,
  TP_List_Application = 57003,
  TP_List_ApplicationCategory = 57004,
  TP_List_FurtherInfoRequests = 57005,
  TP_List_Advertising = 57006,
  TP_List_Notices = 57007,
  TP_List_Complaints = 57008,
  TP_List_Inspections = 57009,
  TP_Form_SystemSettings = 57010,
  TP_Dialog_AllocateToPlanner = 57011,
  TP_Dialog_PlannerAssessment = 57012,
  TP_Dialog_ApplicationBuildingDetails = 57013,
  TP_List_ConditionReference = 57014,
  TP_Form_ConditionReference = 57015,
  TP_Form_ApplicationCondition = 57016,
  TP_Dialog_ApplicationCondition = 57017,
  TP_List_InterestedParties = 57018,
  TP_Dialog_IssueBuildingPermit = 57019,
  TP_Dialog_IssueBuildingCertificate = 57020,
  TP_Dialog_Issue = 57021,
  TP_Form_CalculatedFeeSetup = 57022,
  TP_List_CalculatedFeeSetup = 57023,
  TP_Dialog_CalculatedFee = 57024,
  TP_List_Appeal = 57025,
  TP_List_Fees = 57026,
  TP_Dialog_PlanningPortal = 57027,
  TP_Dialog_ApplicationDescription = 57028,
  TP_Dialog_ApplicationDetails = 57029,
  TP_Dialog_AppealComplete = 57030,
  TP_Dialog_ApplicationNumber = 57031,
  TP_Dialog_ApplicationStatus = 57032,
  TP_Form_Subdivision = 57033,
  TP_Dialog_PlanningApplications = 57034,
  TP_Dialog_EOT = 57035,
  TP_Dialog_FinalDecision = 57036,
  TP_View_InteretstedPartiesByAppID = 57037,
  TP_Dialog_AppealCompleteUndo = 57038,
  TP_Dialog_ImportIP = 57039,
  TP_Dialog_LDChecklist = 57040,
  TP_Dialog_MPL = 57041,
  TP_Form_FeeSchedule = 57042,
  TP_List_FeeSchedule = 57043,
  TP_Main_Menu = 57044,
  TP_Form_PSAApplication = 57045,
  TP_Dialog_PSAAllocateToPlanner = 57046,
  TP_Dialog_PSAPlannerAssessment = 57047,
  TP_Dialog_PSAApplicationDetails = 57048,
  TP_Dialog_PSACounciDecision = 57049,
  TP_Dialog_ApplicationReason = 57050,
  TP_List_PSAInterestedParties = 57051,
  TP_List_PSAExhibition = 57052,
  TP_List_ImportIPFromMap = 57053,
  TP_Dialog_PSAMinisterDecision = 57054,
  TP_Dialog_ChangeDecision = 57055,
  TP_SecondaryConsent_Dialog_Issue = 57056,
  TP_Form_OtherApplication = 57057,
  TP_Dialog_ReadyForDecision = 57058,
  TP_Form_PPRApplication = 57059,
  TP_List_PPRApplication = 57060,
  TP_Form_PPRSystemSettings = 57061,
  TP_Form_PPRResponsibleAuthority = 57062,
  TP_Form_PPRPlanningRegion = 57063,
  TP_List_PPRPlanningRegion = 57064,
  TP_Dialog_PPRPlanningRegion = 57065,
  TP_Dialog_PPRPlannerAssessment = 57066,
  TP_Dialog_PPRApplicationDecision = 57067,
  TP_Dialog_PPRPickAddress = 57089,
  TP_Dialog_PlannerPrelimAssessment = 57068,
  TP_Dialog_PPARSCheck = 57069,
  TP_Dialog_PPRSubDivisionDetails = 57070,
  TP_Dialog_PPRPlansToComplyDecisionDetails = 57071,
  TP_Dialog_PPRPlansToComplyDecision = 57072,
  TP_Dialog_PPRAppealComplete = 57073,
  TP_Dialog_PPRAppealCompleteUndo = 57074,
  TP_Dialog_PPREnquirySendResponse = 57075,
  TP_Dialog_ConflictOfInterest = 57076,
  TP_List_DebtorSummaryOutstanding = 57077,
  TP_List_DebtorSummaryAll = 57078,
  TP_Dialog_TestFees = 57079,
  TP_Dialog_Contact = 57080,
  TP_Form_ClauseReference = 57081,
  TP_Form_PSARApplication = 57082,
  TP_Dialog_PickPSAApplicantType = 57083,
  TP_Dialog_PSARProponentDecision = 57084,
  TP_Form_SubdivisionReferrals = 57085,
  TP_Dialog_SubDivisionReferralsAssessment = 57086,
  TP_Dialog_SubDivisionReferralResponce = 57087,
  TP_Dialog_SubDivisionReferralRecommendation = 57088,
  TP_Dialog_AssessmentDetails = 57090,
  TP_Form_PNFApplication = 57091,
  TP_Dialog_PNFApplicationDescription = 57092,
  TP_Dialog_PNFAllocateToOfficer = 57093,
  TP_Dialog_PNFOfficerAssessment = 57094,
  TP_Dialog_PNFApplicationNumber = 57095,
  TP_Dialog_PNFApplicationStatus = 57096,
  TP_Form_PNFApplicationCategory = 57097,
  TP_List_PNFApplicationCategory = 57098,
  TP_PSAR_HearingCalender = 57099,
  TP_List_PortalJournals = 57100,
  TP_View_ApplicationCondition = 57101,
  TP_Dialog_PNFWorkFlowAction = 57102,
  TP_List_PNFDueDiligence = 57103,
  TP_Form_PNFDueDiligence = 57104,
  TP_List_PNFOperation = 57105,
  TP_Form_PNFOperation = 57106,
  TP_Form_PNFTS_Data = 57107,
  TP_List_PNFTS_Data = 57108,
  TP_Dialog_PNFApplicationEdit = 57109,
  TP_Dialog_PNFApplicationClosure = 57110,
  TP_Dialog_PNFPickLots = 57111,
  TP_Dialog_PNFPickPlanNumer = 57112,
  TP_List_PNFApplication = 57113,
  TP_List_PNFEPAApplication = 57114,
  TP_Form_PNFSystemSettings = 57115,
  TP_Dialog_PNFChangeOwnerShip = 57116,
  TP_Form_EPlanning_ApplicationCategory = 57117,
  TP_Form_EPlanning_NatureOfWork = 57118,
  TP_List_EPlanning_ApplicationCategory = 57119,
  TP_List_EPlanning_NatureOfWork = 57120,
  TP_Form_EPlanning_DocumentChecklist = 57121,
  TP_List_EPlanning_DocumentChecklist = 57122,
  PNF_Main_Menu = 57123,
  TP_Form_PNFTS_Search = 57124,
  TP_List_Actions = 57125,
  TP_List_InvoicesNotSentToFinance = 57126,
  TP_List_InvoicesSentToFinance = 57127,
  TP_Dialog_PPARSDetails = 57128,
  TP_Dialog_CouncilCommentary = 57129,
  Building_Form_Application = 58001,
  Building_Form_ApplicationCategory = 58002,
  Building_List_Application = 58003,
  Building_List_ApplicationCategory = 58004,
  Building_List_FurtherInfoRequests = 58005,
  Building_List_Advertising = 58006,
  Building_List_Notices = 58007,
  Building_List_Complaints = 58008,
  Building_List_Inspections = 58009,
  Building_Form_SystemSettings = 58010,
  Building_Dialog_AllocateToPlanner = 58011,
  Building_Dialog_PlannerAssessment = 58012,
  Building_Dialog_ApplicationBuildingDetails = 58013,
  Building_List_ConditionReference = 58014,
  Building_Form_ConditionReference = 58015,
  Building_Form_ApplicationCondition = 58016,
  Building_Dialog_ApplicationCondition = 58017,
  Building_List_InterestedParties = 58018,
  Building_Dialog_IssueBuildingPermit = 58019,
  Building_Dialog_IssueBuildingCertificate = 58020,
  Building_Dialog_Issue = 58021,
  Building_Form_CalculatedFeeSetup = 58022,
  Building_List_CalculatedFeeSetup = 58023,
  Building_Dialog_CalculatedFee = 58024,
  Building_List_Appeal = 58025,
  Building_List_Fees = 58026,
  Building_Dialog_CIL = 58027,
  Building_Dialog_PermitType = 58028,
  Building_List_PermitType = 58029,
  Building_Form_PermitType = 58030,
  Building_Dialog_ApplicationType = 58031,
  Building_Dialog_PlumbingApplicationDetails = 58032,
  Building_List_StreetProtection = 58033,
  Building_Dialog_AssessmentCouncilConsent = 58034,
  Building_Dialog_ImportIP = 58035,
  Building_List_ImportIPFromMap = 58036,
  Building_View_InteretstedPartiesByAppID = 58037,
  Building_Copy_Application = 58038,
  Building_Dialog_RydeAssessmentPages = 58039,
  Building_Dialog_PickBuildingWorks = 58040,
  Building_Dialog_ApplicationPermitNumber = 58041,
  Building_List_Interviews = 58042,
  Building_PrescribedEvent = 58043,
  Building_Dialog_ApplicationWorksCommenceByDate = 58044,
  Building_Dialog_ApplicationWorksCompleteByDate = 58045,
  Building_Dialog_IssueNoticeOfCompletion = 58046,
  Building_Dialog_ApplicationBAMSReferenceNumber = 58047,
  Building_Dialog_ApplicationBuildingDetailsLite = 58048,
  Building_Dialog_PlumbingApplicationDetailsLite = 58049,
  Building_View_ApplicationCondition = 58050,
  Building_Form_StatClock = 58051,
  Building_Dialog_PNFAssessmentPages = 58052,
  Building_List_InvoicesNotSentToFinance = 58053,
  Building_List_InvoicesSentToFinance = 58054,
  Register_PlanningEnquiry = 59001,
  Register_CouncilConsent = 59002,
  Register_Demolition = 59003,
  Register_EssentialSafety = 59004,
  Register_POPE = 59005,
  Register_PlansToComply = 59006,
  Register_PlanningCertificate = 59007,
  Register_SecondaryConsent = 59008,
  Register_NonCompliance = 59009,
  Register_List_PickApplication = 59010,
  Register_CoolingTower = 59011,
  Register_SwimmingPool = 59012,
  Register_StreetProtection = 59013,
  Register_Form_SystemSettings = 59014,
  Register_List_Notices = 59015,
  Register_List_Complaints = 59016,
  Register_List_Inspections = 59017,
  Register_List_Fees = 59018,
  Register_List_Conditions = 59019,
  Register_List_StreetProtection = 59020,
  Register_MultiTenancy = 59021,
  Register_List_MultiTenancy = 59022,
  Register_Easement = 59023,
  Register_Stormwater = 59024,
  Register_PoolSpasBuilding = 59025,
  Register_Enforcement = 59026,
  Register_SitingApproval = 59027,
  Register_BuildingEnquiry = 59028,
  Register_Pretreatment = 59029,
  Register_Dialog_ApplicationStatus = 59030,
  Register_ExtensionOfTime = 59031,
  Building_Dialog_ExtensionOfTime = 59032,
  PPRRegister_PlanningEnquiry = 59033,
  Register_BuildingCertificate = 59034,
  Register_CourtCase = 59035,
  Register_HealthEnquiry = 59036,
  LLPermits_Form_PermitType = 60001,
  LLPermits_List_PermitType = 60002,
  LLPermits_Form_Permit = 60003,
  LLPermits_List_Permit = 60004,
  LLPermits_Form_SystemSettings = 60005,
  LLPermits_List_Notices = 60006,
  LLPermits_List_Complaints = 60007,
  LLPermits_List_Inspections = 60008,
  LLPermits_List_NCItem = 60009,
  LLPermits_List_Fees = 60010,
  LLPermits_List_Conditions = 60011,
  LLPermits_Dialog_PermitTypes = 60012,
  LLPermits_List_PermitRegion = 60013,
  LLPermits_Dialog_PermitDetails = 60014,
  Permits_Form_Permit = 60015,
  Permits_Form_PermitType = 60016,
  Permits_Dialog_EligibilityStatement = 60017,
  LLPermits_Dialog_Workflow = 60018,
  LLPermits_List_Reasons = 60019,
  LLPermits_Form_Calculated_Fee_Setup = 60020,
  Permits_List_Permit = 60021,
  Permits_Dialog_AmendPermit = 60022,
  Permits_List_Tenders = 60023,
  Permits_Form_Tender = 60024,
  Permits_Dialog_PickWorkFlowAction = 60025,
  Permits_List_InvoicesNotSentToFinance = 60026,
  Permits_List_InvoicesSentToFinance = 60027,
  Animals_Form_AnimalType = 70001,
  Animals_List_AnimalType = 70002,
  Animals_Form_Registration = 70003,
  Animals_List_Registration = 70004,
  Animals_Form_SystemSettings = 70005,
  Animals_List_Notices = 70006,
  Animals_List_Complaints = 70007,
  Animals_List_Inspections = 70008,
  Animals_List_Fees = 70010,
  Animals_Dialog_AnimalTypes = 70012,
  Animals_Dialog_Animals = 70013,
  Animals_Dialog_eAnimals = 70014,
  Animals_List_PoundRegister = 70015,
  Animals_Form_PoundRegister = 70016,
  Animals_Form_QuickRegistration = 70017,
  Animals_Dialog_QuickRegistration = 70018,
  Animals_List_InspectionPlanner = 70019,
  Animals_Dialog_RegistrationFeeRule = 70020,
  Animals_Dialog_RegistrationFeeAdditionalChargeRule = 70021,
  Animals_Dialog_TestFees = 70022,
  Animals_Dialog_NonRegistrationFeeRule = 70023,
  Animals_Dialog_ListAnimals = 70024,
  Animals_Form_Kennel = 70025,
  Animals_List_Kennel = 70026,
  Animals_Dialog_Registration = 70027,
  Animals_Dialog_QuickRegistrationUpdate = 70028,
  Animals_Dialog_Kennels = 70029,
  Animals_List_InvoicesNotSentToFinance = 70030,
  Animals_List_InvoicesSentToFinance = 70031,
  CSM_Form_Licence = 71001,
  CSM_List_Licence = 71002,
  CSM_Form_LicenceCategory = 71003,
  CSM_List_LicenceCategory = 71004,
  CSM_Form_SystemSettings = 71005,
  CSM_List_Fees = 71010,
  CSM_List_Inspections = 71011,
  CSM_Dialog_LicenceCategories = 71012,
  CSM_List_Conditions = 71013,
  CSM_List_RelatedLicence = 71014,
  CSM_Form_LicenceCategoryMptg = 71015,
  CSM_Form_LicenceMptg = 71016,
  CSM_Dialog_ApplicationDetails = 71017,
  CSM_Dialog_SubstanceDetails = 71018,
  CSM_List_Renewals = 71019,
  CSM_List_Complaints = 71020,
  CSM_List_Notices = 71021,
  CSM_List_ACOs = 71022,
  CSM_Form_PoisonRegister = 71023,
  CSM_Form_PestObservation = 71024,
  CSM_List_PestObservation = 71025,
  CSM_List_PoisonRegister = 71026,
  CSM_Form_Indemnity = 71027,
  CSM_List_PestIndemnity = 71028,
  CSM_List_ProductType = 71029,
  CSM_Form_ProductType = 71030,
  CSM_Form_RiskAssessment = 71031,
  CSM_List_RiskAssessment = 71032,
  CSM_List_PestSpeciesType = 71033,
  CSM_Form_PestSpeciesType = 71034,
  CSM_Dialog_PestSpeciesType = 71035,
  CSM_Form_PestAnimalProgram = 71036,
  CSM_Dialog_RiskAssessmentList = 71037,
  CSM_Dialog_PoisonRegisterList = 71038,
  CSM_Form_IssuePoisonACO = 71039,
  CSM_List_IssuePoisonRegister = 71040,
  CSM_List_ProgramRegister = 71041,
  CSM_Dialog_ProgramRegisterList = 71042,
  CSM_Dialog_ConfirmIssuePoison = 71043,
  CSM_Dialog_IssuePoison = 71044,
  CSM_Form_PestAnimalActivity = 71045,
  CSM_Dialog_PestObservationList = 71046,
  CSM_List_PestAnimalActivity = 71047,
  CSM_Form_Poisons_FieldDataSheet = 71047,
  CSM_Form_Poisons_TransferOut = 71048,
  CSM_Form_Poisons_TransferIn = 71049,
  CSM_Form_Poisons_Stocktake = 71050,
  CSM_Form_Poisons_AuditStocktake = 71051,
  CSM_List_AerialBaiting = 71052,
  CSM_Form_AerialBaiting = 71053,
  CSM_Form_Poisons_IndemnitySignature = 71054,
  CSM_Dialog_CancelIssuePoison = 71055,
  CSM_List_PoisonsTransactions = 71056,
  CSM_List_PoisonsWaitingForApprovalTransactions = 71057,
  CRS_Centre_Access_View_CBCRegistrationWaitListed = 73013,
  CRS_Centre_Access_View_CBCRegistrationOffers = 73014,
  CRS_Centre_Access_View_CBCRegistrationPlaced = 73015,
  CRS_Centre_Access_View_3yoRegistrationWaitListed = 73016,
  CRS_Centre_Access_View_3yoRegistrationOffers = 73017,
  CRS_Centre_Access_View_3yoRegistrationPlaced = 73018,
  CRS_Centre_Access_View_4yoRegistrationWaitListed = 73019,
  CRS_Centre_Access_View_4yoRegistrationOffers = 73020,
  CRS_Centre_Access_View_4yoRegistrationPlaced = 73021,
  CRS_Centre_Access_Form_Registration = 73022,
  CRS_Centre_Access_Form_CBCRegistration = 73023,
  CRS_Centre_Access_Form_3yoRegistration = 73024,
  CRS_Centre_Access_Form_4yoRegistration = 73025,
  CRS_Centre_Access_Dialog_Comment = 73026,
  CRS_Centre_Access_CBC_Service = 73101,
  CRS_Centre_Access_3YO_Service = 73102,
  CRS_Centre_Access_4YO_Service = 73103,
  CRS_Centre_Access_Dialog_RegistrationChangeDate = 73104,
  TrainingManager_View_CourseTypes = 74001,
  TrainingManager_View_CourseProviders = 74002,
  TrainingManager_View_Competencies = 74003,
  TrainingManager_View_DevelopmentPlans = 74004,
  TrainingManager_View_TrainingRequests = 74005,
  TrainingManager_View_CourseOutlines = 74006,
  TrainingManager_View_EvaluationsAll = 74007,
  TrainingManager_View_MyTrainingRequests = 74008,
  TrainingManager_View_MyTrainingEvaluations = 74009,
  TrainingManager_View_MyDevelopmentPlans = 74010,
  TrainingManager_View_CourseSchedules = 74011,
  TrainingManager_View_SkillAndCompliance = 74012,
  TrainingManager_View_RecordTypes = 74013,
  TrainingManager_Form_CourseType = 74201,
  TrainingManager_Form_Competency = 74202,
  TrainingManager_Form_DevelopmentPlan = 74203,
  TrainingManager_Form_DevelopmentPlanItem = 74204,
  TrainingManager_Form_TrainingRequest = 74205,
  TrainingManager_Form_CourseOutline = 74206,
  TrainingManager_Form_CourseSchedule = 74207,
  TrainingManager_Form_SystemSettings = 74208,
  TrainingManager_Form_TrainingEvaluation = 74209,
  Menu_TrainingManager_Vertical = 74210,
  TrainingManager_Form_RecordType = 74211,
  TrainingManager_Form_SkillAndCompliance = 74212,
  TrainingManager_Form_CalenderTrainingRequest = 74213,
  TM_View_AllEventBookings_Calendar = 74214,
  TrainingManager_DynamicForm_TrainingEvaluation = 74215,
  TrainingManager_List_CourseTypes = 74401,
  TrainingManager_List_Competencies = 74402,
  TrainingManager_List_CourseProviders = 74403,
  TrainingManager_List_CourseOutlines = 74404,
  TrainingManager_List_TrainingRequests = 74405,
  TrainingManager_List_CourseSchedules = 74406,
  TrainingManager_List_DevelopmentPlanItems = 74407,
  TrainingManager_List_RecordTypes = 74408,
  TrainingManager_Dialog_AddComments = 74601,
  TrainingManager_Dialog_TextEditor = 74602,
  TrainingManager_Dialog_SuperAdmin = 74603,
  TrainingManager_Dialog_WaitingList = 74604,
  CorePortal_Dialog_ManagePortalLinks = 75001,
  CorePortal_Dialog_PickPortalLinks = 75002,
  NSPR_List_Register = 76001,
  NSPR_Form_Register = 76002,
  NSPR_List_OrganisationOutlet = 76003,
  NSPR_Form_OrganisationOutlet = 76004,
  NSPR_Dialog_OrganisationOutlets = 76005,
  NSPR_Form_SystemSettings = 76006,
  NSPR_Dialog_Lodge = 76007,
  NSPR_Dialog_ApplicationDetails = 76008,
  NSPR_Dialog_Training = 76009,
  NSPR_Dialog_PreviousAddresses = 76010,
  NSPR_Dialog_PreviousRegistrations = 76011,
  NSPR_Dialog_Renew = 76012,
  NSPR_Dialog_Register = 76013,
  Project_List_Projects = 77001,
  Project_Form_SystemSettings = 77002,
  Project_Form_Project = 77003,
  Project_Form_ProjectByNumber = 77004,
  Project_Form_ProjectBySystem = 77005,
  Radiation_List_Licences = 78001,
  Radiation_Form_SystemSettings = 78002,
  Radiation_List_Sources = 78003,
  Radiation_List_Places = 78004,
  Radiation_List_Accreditation = 78005,
  Radiation_List_Renewals = 78006,
  Radiation_List_Inspections = 78007,
  Radiation_List_GovtReports = 78008,
  Radiation_List_LetterSent = 78009,
  Radiation_List_ExternalReports = 78010,
  Radiation_List_Historic = 78011,
  Radiation_List_SmartForms = 78012,
  Radiation_Form_Licence = 78013,
  Radiation_Form_Source = 78014,
  Radiation_Dialog_ApplicationDetails = 78015,
  Radiation_Form_Accreditation = 78016,
  Radiation_Form_Place = 78017,
  Radiation_Dialog_SourceDetails = 78018,
  Radiation_Dialog_Conditions = 78019,
  Radiation_Dialog_List_Places = 78020,
  Radiation_List_Complaints = 78021,
  Radiation_List_Fees = 78022,
  Radiation_Dialog_List_Sources = 78023,
  Radiation_Form_Approval = 78024,
  Radiation_Form_UseLicence = 78025,
  Radiation_Dialog_Authorisation = 78026,
  Radiation_List_ConditionReference = 78027,
  Radiation_Form_ConditionReference = 78028,
  Radiation_List_AuthorisationBusinessRule = 78029,
  Dialog_Form_Workflow = 78030,
  Radiation_List_ManagementLicences = 78031,
  Radiation_List_FacilityConstructionLicences = 78032,
  Radiation_Form_AuthorisationBusinessRule = 78033,
  Radiation_List_RequirementDefinitions = 78034,
  Radiation_Form_RequirementDefinition = 78035,
  Radiation_List_OccupationBusinessRule = 78036,
  Radiation_Form_OccupationBusinessRule = 78037,
  Radiation_Dialog_CompareVariation_UseLicence = 78038,
  Radiation_Dialog_CompareVariation_Approval = 78039,
  Radiation_List_OnlineRegister = 78040,
  Radiation_List_OnlineNameVariations = 78041,
  Radiation_Form_ManageNameVariation = 78042,
  Radiation_List_RenewalPendingApproval = 78043,
  Radiation_Dialog_Suspension = 78044,
  Radiation_Form_ManagementLicence = 78045,
  Radiation_Dialog_ReplaceAuthorisationBusinessRules = 78046,
  Radiation_Form_Authorisation = 78047,
  Radiation_Form_Site = 78048,
  Radiation_Form_AuthorisationSource = 78049,
  Radiation_Form_FeeConfiguration = 78050,
  Radiation_Dialog_LicenceFeeRules = 78051,
  Radiation_Dialog_List_Sites = 78052,
  Radiation_View_Hierarchy = 78053,
  Radiation_List_MakeModelBusinessRule = 78054,
  Radiation_Form_MakeModelBusinessRule = 78055,
  Radiation_Dialog_CompareVariation_ManagementLicence = 78056,
  Radiation_List_Radionuclides = 78057,
  Radiation_Form_Radionuclide = 78058,
  Radiation_Dialog_ChangeDetails = 78059,
  Radiation_Dialog_AcquisitionDisposal = 78060,
  Radiation_Dialog_MngtLicenceFeesDetails = 78061,
  Radiation_Dialog_AuthorisationSource = 78062,
  Radiation_Dialog_ComplianceTest = 78063,
  Radiation_View_AssessorLicence_RecycleBin = 78064,
  Radiation_View_UseLicence_RecycleBin = 78065,
  Radiation_View_TesterLicence_RecycleBin = 78066,
  Radiation_View_ManagementLicence_RecycleBin = 78067,
  Radiation_List_UseLicences = 78068,
  Radiation_List_TesterLicences = 78069,
  Radiation_List_AssessorLicences = 78070,
  Radiation_List_Keywords = 78071,
  Radiation_Main_Menu = 78072,
  Radiation_Dialog_List_Licences = 78073,
  Radiation_Form_ManageIncidents = 78074,
  Radiation_List_ManagementIncidents = 78075,
  Radiation_List_ManagementResearchProjects = 78076,
  Radiation_Form_ManageResearchProjects = 78077,
  Radiation_Form_ManageRefund = 78078,
  Radiation_Form_Occupation = 78079,
  Radiation_Dialog_OccupationTypeOfDealings = 78080,
  Radiation_Form_SmartForm = 78081,
  Radiation_Dialog_AuthorisationDisposalPathway = 78082,
  Radiation_Form_DisposalPathway = 78083,
  Radiation_Dialog_List_Authorisations = 78084,
  Radiation_Form_DisposalNotice = 78085,
  Radiation_Dialog_List_Equipments = 78086,
  Radiation_Form_Relocation = 78087,
  Radiation_Dialog_List_DisposalFacilitators = 78088,
  Radiation_Form_Disposal = 78089,
  Radiation_Form_Transfer = 78090,
  Radiation_Dialog_List_Register = 78091,
  Radiation_Dialog_List_Radionuclide = 78092,
  Radiation_List_TransferManagementLicences = 78093,
  Radiation_List_DisposalFacilitators = 78094,
  Radiation_Dialog_List_Conditions = 78095,
  Radiation_View_CommunicationFromConditions = 78096,
  Radiation_Dialog_UploadSmartForms = 78097,
  Radiation_List_Compliances = 78098,
  SSMS_Form_ChangeRequest = 79001,
  SSMS_Form_SiteUser = 79002,
  SSMS_Form_SignPlotter = 79003,
  SSMS_List_ChangeRequests = 79004,
  SSMS_View_SiteUser = 79005,
  SSMS_View_ListSigns = 79006,
  SSMS_MemberAdmin_Dialog_ImportSignRepository = 79007,
  SSMS_Form_SystemSettings = 79008,
  SSMS_Form_SpeedSignDetails = 79009,
  SSMS_Dialog_Reassign = 79010,
  SSMS_Form_Map = 79011,
  SSMS_List_SafetyCameras = 79012,
  SSMS_List_LGARegions = 79013,
  SSMS_Form_LGARegion = 79014,
  SSMS_List_VicRoadsOffice = 79015,
  SSMS_Dialog_PickLGA = 79016,
  SSMS_List_Keywords = 79017,
  SSMS_List_LGASuburbs = 79018,
  SSMS_Form_Suburbs = 79019,
  SSMS_Dialog_RecvWorkSchedule = 79020,
  SSMS_List_Regions = 79021,
  SSMS_Form_Region = 79022,
  SSMS_Dialog_PickRegion = 79023,
  SSMS_Form_Document = 79024,
  SSMS_List_Documents = 79025,
  Menu_SSMS_Vertical = 79026,
  SSMS_Learning_Portal = 79027,
  SRU_Form_IndependentReviewBody = 80001,
  SRU_View_IndependentReviewBody = 80002,
  SRU_List_IndependentReviewBody = 80003,
  SRU_Form_Application_NewRegistration = 80004,
  SRU_View_Application = 80005,
  SRU_Form_Audit = 80006,
  SRU_View_Audit = 80007,
  SRU_Form_Condition = 80008,
  SRU_View_Condition = 80009,
  SRU_Form_Issue = 80010,
  SRU_View_Issue = 80011,
  SRU_Form_Location = 80012,
  SRU_List_Location = 80013,
  SRU_View_Location = 80014,
  SRU_Form_Organisation = 80015,
  SRU_View_Organisation = 80016,
  SRU_Form_Registration = 80017,
  SRU_View_Registration = 80018,
  SRU_Form_Application_ChangeOfDetails = 80019,
  SRU_Dialog_ComplianceDetails = 80020,
  SRU_Form_SystemSettings = 80021,
  SRU_List_Organisation = 80022,
  SRU_View_Organisation_RecycleBin = 80023,
  SRU_View_IndependentReviewBody_RecycleBin = 80024,
  SRU_Dialog_Subcontractors = 80025,
  SRU_View_Location_RecycleBin = 80026,
  SRU_List_Division = 80027,
  SRU_Form_Application_NewCondition = 80028,
  SRU_Form_Application_RegistrationRevocation = 80029,
  SRU_Dialog_NotifyDivision = 80030,
  SRU_Form_Application_NewRegistration_SRS = 80031,
  SRU_Form_Registration_SRS = 80032,
  SRU_Dialog_UpdateRegistrationDates = 80033,
  SRU_Dialog_ShortRevoke = 80034,
  SRU_List_Seizures = 80035,
  SRU_Form_Seizure = 80036,
  SRU_List_Notices = 80037,
  SRU_List_Statements = 80038,
  SRU_List_Undertakings = 80039,
  SRU_Dialog_EnforcementOptions = 80040,
  SRU_Form_Statement = 80041,
  SRU_List_Amendments = 80042,
  SRU_Form_Amendment = 80043,
  SRU_Form_Sanction = 80044,
  SRU_List_ActionPlans = 80045,
  SRU_List_Complaints = 80046,
  SRU_List_ReportableIncidents = 80047,
  SRU_List_Inspections = 80048,
  SRU_List_Risks = 80050,
  SRU_List_SAVVIExpenditures = 80051,
  SRU_List_SAVVIFundingPools = 80052,
  SRU_List_SAVVIPartnerships = 80053,
  SRU_List_ReportableTransactions = 80054,
  SRU_Form_ReportableIncident = 80055,
  SRU_Form_ReportableTransaction = 80056,
  SRU_Form_AppointAdmin = 80057,
  SRU_Form_SAVVIExpenditure = 80058,
  SRU_Form_SAVVIFundingPool = 80059,
  SRU_Form_SAVVIPartnership = 80060,
  SRU_Form_SuspendAdmissions = 80061,
  SRU_Form_Infringement = 80062,
  SRU_Form_EnforcementPlan = 80064,
  SRU_List_Enforcements = 80065,
  SRU_Dialog_TCRAssessment = 80066,
  SRU_Dialog_TCRComment = 80067,
  SRU_List_InspectionScheduleByOfficer = 80068,
  SRU_List_InspectionScheduleGeneric = 80069,
  SRU_List_InspectionScheduleCalendar = 80070,
  SRU_List_Facilities = 80071,
  SRU_Form_Risk = 80072,
  SRU_Dialog_ListRegistrations = 80073,
  SRU_Form_Application_ChangeOfDetails_SRS = 80074,
  SRU_Dialog_PickEnforcementPlans = 80075,
  SRU_Dialog_IssueClosure = 80076,
  SRU_Dialog_PickReportableIncidents = 80077,
  SRU_Dialog_PickIssues = 80078,
  SRU_Dialog_PickSeizures = 80079,
  SRU_Form_ComplianceNotice = 80080,
  SRU_Dialog_Registration_Closure = 80081,
  DWCMS_Main_Menu = 81001,
  DWCMS_Form_NewWorker = 81002,
  DWCMS_Form_WorkerDetails = 81003,
  DWCMS_Form_Registration_New = 81004,
  DWCMS_View_DWAll = 81005,
  DWCMS_View_RegistrationAll = 81006,
  DWCMS_Form_RegistrationDetails = 81007,
  DWCMS_Form_ApplicationDetails = 81008,
  DWCMS_Form_NewApplication = 81009,
  DWCMS_Dialog_PickDisabilityWorker = 81010,
  DWCMS_Dialog_RegistrationType = 81011,
  DWCMS_Dialog_Insurance = 81012,
  DWCMS_Dialog_Impairment = 81013,
  DWCMS_Dialog_Education = 81014,
  DWCMS_Dialog_Employment = 81015,
  DWCMS_Dialog_CriminalHistory = 81016,
  DWCMS_Dialog_LimitedSPQualification = 81017,
  DWCMS_Dialog_DisciplinaryAction = 81018,
  DWCMS_Form_RegistrationType = 81019,
  DWCMS_View_RegistrationType = 81020,
  DWCMS_Dialog_Workflow = 81021,
  DWCMS_Dialog_IdentityDetails = 81022,
  DWCMS_Form_Division = 81023,
  DWCMS_Dialog_RegistrationDivision = 81024,
  DWCMS_View_ServiceProviders = 81026,
  DWCMS_Form_ServiceProvider = 81027,
  DWCMS_View_EducationProviders = 81028,
  DWCMS_Form_CheckInfo = 81030,
  DWCMS_Form_SystemSettings = 81031,
  DWCMS_Dialog_PreviousAddress = 81032,
  DWCMS_Form_InformationSharing = 81034,
  DWCMS_View_Divisions = 81035,
  DWCMS_View_Applications = 81036,
  DWCMS_View_ActiveApplications = 81037,
  DWCMS_Dialog_MergeContacts = 81038,
  DWCMS_View_Contacts = 81039,
  DWCMS_Form_Undertaking = 81040,
  DWCMS_View_Undertakings = 81041,
  DWCMS_Form_Prohibition = 81042,
  DWCMS_View_Prohibitions = 81043,
  DWCMS_Dialog_PickProhibitionSource = 81044,
  DWCMS_Dialog_ServiceProvider = 81046,
  DWCMS_Form_Employment = 81047,
  Dialog_Form_OtherNames = 81048,
  DWCMS_Dialog_PickUndertakingSource = 81049,
  DWCMS_Dialog_Registration_ChangeDetails = 81050,
  DWCMS_Dialog_ServiceVicInput = 81051,
  DWCMS_ProfessionalRegistration = 81052,
  DWCMS_ProfessionalCompetence = 81053,
  DWCMS_Dialog_PrimaryPlaceOfPractice = 81054,
  DWCMS_Dialog_PickServiceProvider = 81055,
  DWCMS_Form_CriminalHistory = 81056,
  DWCMS_Dialog_ExtendDueDate = 81057,
  DWCMS_Form_Request = 81058,
  DWCMS_Dialog_CriminalHistoryDetails = 81059,
  DWCMS_Form_Condition = 81060,
  DWCMS_View_Requests = 81061,
  DWCMS_View_Conditions = 81062,
  DWCMS_List_Keywords = 81063,
  DWCMS_List_Fees = 81064,
  DWCMS_View_Enquiries = 81065,
  DWCMS_Form_Enquiry = 81066,
  DWCMS_Dialog_PublicRegisterFlags = 81067,
  DWCMS_Dialog_PublicRegisterDetails = 81068,
  DWCMS_View_Worker_RecycleBin = 81069,
  DWCMS_View_Application_RecycleBin = 81070,
  DWCMS_View_Registration_RecycleBin = 81071,
  DWCMS_Dialog_ExtendBatchDueDate = 81072,
  DWCMS_View_DWPublicRegister = 81073,
  DWCMS_Form_Caution = 81074,
  DWCMS_Form_Reprimand = 81075,
  DWCMS_Dialog_PickRegistration = 81076,
  DWCMS_Dialog_UpdateApplicationStatus = 81077,
  DWCMS_Dialog_BatchUpdateRegistrationIndicator = 81078,
  DWCMS_Form_SuspendWorker = 81079,
  DWCMS_View_SuspendWorkers = 81080,
  Infringements_List_Ticket = 82001,
  Infringements_Form_Ticket = 82002,
  Core_Dialog_StatusList = 82003,
  Infringements_List_Category = 82004,
  Infringements_Form_Category = 82005,
  Infringements_List_Offence = 82006,
  Infringements_Form_Offence = 82007,
  Infringements_List_Status = 82008,
  Infringements_Dialog_Category = 82009,
  Infringements_List_DebtorSummaryAll = 82010,
  Infringements_List_DebtorSummaryOutstanding = 82011,
  Infringements_List_Fees = 82012,
  Infringements_Dialog_CalculatedFee = 82013,
  Infringements_Dialog_ChangeTicketStatus = 82014,
  Infringements_List_TicketByType1 = 82015,
  Infringements_List_Tickets_ReadyToExport1 = 82016,
  Infringements_List_Tickets_ReadyToImport1 = 82016,
  Infringements_Dialog_UploadImportFile = 82017,
  Infringements_Dialog_OnHoldComment = 82018,
  Infringements_List_TicketByType2 = 82019,
  Infringements_List_Tickets_ReadyToExport2 = 82020,
  Infringements_List_Tickets_ReadyToImport2 = 82021,
  Infringements_List_FinalDemandTickets = 82022,
  Infringements_List_Appeal = 82023,
  Infringements_Dialog_List_Infringements = 82024,
  Infringements_List_Complaints = 82025,
  Infringements_List_PaymentPlans = 82026,
  Infringements_Dialog_BatchTicketStatusChange = 82027,
  Infringements_List_Ticket_ExternalSystem = 82028,
  Infringements_List_Ticket_ExternalSystemLog = 82029,
  Infringements_Dialog_FailedLogs = 82030,
  Infringements_List_Court = 82031,
  Infringements_List_Prosecutions = 82032,
  CEM_List_Application = 83001,
  CEM_Form_Application = 83002,
  CEM_Form_SystemSettings = 83003,
  CEM_Form_CemeteryRegistration = 83004,
  CEM_List_CemeteryRegistration = 83005,
  CEM_List_Finance = 83006,
  FLEET_View_Vehicles = 84001,
  FLEET_Form_Vehicles = 84002,
  FLEET_Form_EventBooking = 84003,
  FLEET_View_FutureEventBookings = 84004,
  FLEET_View_NotYetApprovedEventBookings = 84005,
  FLEET_View_NewEventBookings = 84006,
  FLEET_View_AllEventBookings_Calendar = 84007,
  FLEET_Form_VehicleBookingPlan = 84008,
  FLEET_Form_VehicleBookingCalendar = 84009,
  FLEET_Dialog_VehiclePhoto = 84010,
  FLEET_Form_SystemSettings = 84011,
  FLEET_Form_BookingInvoice = 84012,
  FLEET_Form_RepeatBookingCalendar = 84013,
  FLEET_Dialog_BookingDate = 84014,
  FLEET_View_EventTypes = 84015,
  FLEET_Form_EventType = 84016,
  FLEET_Dialog_EventTypeList = 84017,
  FLEET_List_Fees = 84018,
  FLEET_Form_GroupEventBooking = 84019,
  FLEET_Form_FleetClassification = 84020,
  FLEET_View_FleetClassification = 84021,
  FLEET_Dialog_VehicleBooking = 84022,
  FLEET_Form_VehicleFeature = 84023,
  FLEET_View_VehicleFeatures = 84024,
  FLEET_Dialog_VehicleBookingDetails = 84025,
  FLEET_Form_Estimate = 84026,
  FLEET_Form_VehicleBookingFeeEstimate = 84027,
  FLEET_Form_BookingFeeEstimate = 84028,
  FLEET_View_DaftEventBookings = 84029,
  FLEET_View_Finance = 84030,
  FLEET_View_CRMSDraftEventBookings = 84031,
  FLEET_View_AllEventBookings = 84032,
  FLEET_Dialog_CompleteBooking = 84033,
  LandManagement_Main_Menu = 85001,
  LandManagement_View_Application = 85002,
  LandManagement_View_ApplicationCategories = 85003,
  LandManagement_Form_SystemSettings = 85004,
  LandManagement_Form_Application = 85005,
  LandManagement_Form_ApplicationCategory = 85006,
  LandManagement_Dialog_OfficerAssessment = 85007,
  LandManagement_List_ApplicationCategory = 85008,
  LandManagement_Form_Certification = 85009,
  LandManagement_Form_Notification = 85010,
  LandManagement_Dialog_Apprailsal = 85011,
  LandManagement_View_Events = 85012,
  LandManagement_Form_Enquiry = 85013,
  LandManagement_Dialog_Reasons = 85014,
  LandManagement_FieldDataSheetAssessment = 85015,
  LandManagement_INSPastureExpansionFieldDataSheetAssessment = 85016,
  LandManagement_View_Enquiries = 85017,
  LandManagement_Dialog_ApplicationNumber = 85018,
  LandManagement_Dialog_ApplicationStatus = 85019,
  LandManagement_Dialog_EventStatus = 85020,
  LandManagement_View_AppraisalQuestion = 85021,
  LandManagement_Dialog_WorkFlowAction = 85022,
  LandManagement_Dialog_RegisterStatus = 85023,
  LandManagement_Dialog_RegisterNumber = 85024,
  LandManagement_Dialog_PickLots = 85025,
  DirectDebit_Dialog_StatusCode = 87001,
  DirectDebit_Dialog_Group = 87002,
  DirectDebit_Dialog_ModuleItemType = 87003,
  DirectDebit_Dialog_BankAccount = 87004,
  DirectDebit_Dialog_Authorisation = 87005,
  DirectDebit_Dialog_Account = 87006,
  DirectDebit_Dialog_Session = 87007,
}

import { getContact } from "@app/core/contacts/_id/api";
import { FormIdentifier } from "@app/products/crms/model";
import {
  getDirectDebitAccountById,
  getDirectDebitAccountLOVs,
} from "@app/products/direct-debit/accounts/[id]/api";
import { TitleDDAccountManagePage } from "@app/products/direct-debit/accounts/[id]/constant";
import { ExistManageDirectDebitAccount } from "@app/products/direct-debit/accounts/[id]/existed/_index";
import { NewManageDirectDebitAccount } from "@app/products/direct-debit/accounts/[id]/new/_index";
import { useDirectDebitStore } from "@app/products/direct-debit/accounts/[id]/store";
import {
  changeStatusNotification,
  checkDisabledStatus,
} from "@app/products/direct-debit/accounts/[id]/util";
import {
  DirectDebitAccount,
  DirectDebitAccountLOVs,
} from "@app/products/direct-debit/accounts/model";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import {
  fetchApiByAlias,
  useFlexibleFetchData,
} from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useIsNew } from "@common/hooks/useIsNew";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageDirectDebitAccount = () => {
  //#region STORE ========/
  const history = useHistory();
  const { setDataForm, resetDataForm } = useFlexibleFormStore();
  const { pushNotificationPortal, setNotificationsCurrentPage } =
    useNotificationPortalStore();
  const { setAccountContact, setInitAccountContact, resetStore } =
    useDirectDebitStore();
  const { isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.DirectDebit_Dialog_Account,
    productType: PRODUCT_TYPE_NUMBER.DirectDebit,
  });
  useEffectOnce(() => {
    return () => {
      resetDataForm();
      resetStore();
    };
  });
  //#endregion STORE =====/

  //#region HOOK ========/
  const { cancelToken } = useCancelRequest();
  const isNew = useIsNew();
  //#endregion HOOK =====/

  //#region Variable ========/
  let { id } = useParams<{ id: string }>();
  const accountId = useMemo(() => {
    return isNew ? "0" : id;
  }, [isNew, id]);
  //#endregion Variable =====/

  const manageDirectDebitAccountSlider = useFlexibleFetchData({
    slides: [
      {
        fetch: [
          () => getDirectDebitAccountById(+accountId, cancelToken()),
          () => getDirectDebitAccountLOVs(cancelToken()),
        ],
        handleSuccess: async ({ dataFromApi }) => {
          const response: [
            APIResponse<DirectDebitAccount>,
            APIResponse<DirectDebitAccountLOVs>
          ] = dataFromApi;
          const ddAccountFormData = response[0]?.data;
          const ddAccountLOVs = response[1]?.data;
          if (checkDisabledStatus(ddAccountFormData?.AccountStatus_ENUM)) {
            setNotificationsCurrentPage([]);
            pushNotificationPortal({
              title: `You are viewing a ${ddAccountFormData.AccountStatus_Name?.toLowerCase()} account. You are not allowed to perform any Workflow functions or make changes to this record`,
              type: "info",
              autoClose: false,
            });
          }
          //#region get Contact for Name and right-side bar ====/
          if (ddAccountFormData?.Contact_ID) {
            const contactResponse = await getContact(
              ddAccountFormData?.Contact_ID
            );
            if (isSuccessResponse(contactResponse) && contactResponse.data) {
              setAccountContact(contactResponse.data);
              setInitAccountContact(contactResponse.data);
            } else {
              pushNotificationPortal({
                title: `Loading name failed.`,
                type: "error",
                autoClose: false,
              });
            }
          }
          //#endregion get Contact for Name and right-side bar =/

          //#region save data to store ========/
          setDataForm({
            GeneralForm: ddAccountFormData,
            GeneralFormLovs: ddAccountLOVs,
          });
          fetchApiByAlias("getItemTypeLOVsSlider");
          //#endregion save data to store =====/
        },
      },
    ],
  });

  const changeStatusSlider = useFlexibleFetchData({
    alias: "changeStatusSlider",
    slides: [
      {
        fetch: () => getDirectDebitAccountById(+id, cancelToken()),
        handleSuccess: ({ dataFromApi }) => {
          const response: APIResponse<DirectDebitAccount> = dataFromApi;
          const ddAccountFormData = response.data;
          //#region save data to store ========/
          setDataForm({
            GeneralForm: ddAccountFormData,
          });
          //#endregion save data to store =====/
          setNotificationsCurrentPage([]);
          if (checkDisabledStatus(ddAccountFormData?.AccountStatus_ENUM)) {
            pushNotificationPortal({
              title: `You are viewing a ${ddAccountFormData.AccountStatus_Name?.toLowerCase()} account. You are not allowed to perform any Workflow functions or make changes to this record`,
              type: "info",
              autoClose: false,
            });
          }
          if (response?.data) {
            pushNotificationPortal({
              title: `${changeStatusNotification(
                ddAccountFormData.AccountStatus_ENUM
              )} successfully.`,
              type: "success",
            });
          } else {
            pushNotificationPortal({
              title: `${changeStatusNotification(
                ddAccountFormData.AccountStatus_ENUM
              )} failed.`,
              type: "error",
              autoClose: false,
            });
          }
        },
      },
    ],
  });

  useEffect(() => {
    manageDirectDebitAccountSlider.fetchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);

  return (
    <>
      <Loading
        isLoading={
          manageDirectDebitAccountSlider.isFetching ||
          changeStatusSlider.isFetching ||
          isLoadingPermission
        }
        isFullScreen
      />
      <FormNavigation title={TitleDDAccountManagePage} />
      {manageDirectDebitAccountSlider.errorComponent ? (
        manageDirectDebitAccountSlider.errorComponent
      ) : isNew ? (
        <NewManageDirectDebitAccount />
      ) : (
        <ExistManageDirectDebitAccount />
      )}
    </>
  );
};

export default observer(ManageDirectDebitAccount);

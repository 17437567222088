import { DirectDebitAccountFormElement } from "@app/products/direct-debit/accounts/[id]/components/general/form-element/_index";
import { checkDisabledStatus } from "@app/products/direct-debit/accounts/[id]/util";
import { DirectDebitAccount } from "@app/products/direct-debit/accounts/model";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const DirectDebitAccountForm = observer(() => {
  //#region STORE ========/
  const { dataForm, middlewareSubmitForm, setOnSubmitFlexible } =
    useFlexibleFormStore();
  const { pushNotificationPortal } = useNotificationPortalStore();
  //#endregion STORE =====/

  //#region METHOD ========/
  const handleOnSubmit = (event: FormSubmitClickEvent) => {
    const { whenNotModified, whenNotValid } = middlewareSubmitForm(event);
    whenNotValid(() => {
      pushNotificationPortal({
        title: "Please enter all required information.",
        type: "warning",
      });
    });
    whenNotModified(() => {
      pushNotificationPortal({
        title: "The form is not modified.",
        type: "warning",
      });
    });
  };
  //#endregion METHOD =====/

  //#region INITIAL_VALUES ========/
  const directDebitAccount = dataForm?.GeneralForm as DirectDebitAccount;
  const isDisabled = useMemo(() => {
    return checkDisabledStatus(directDebitAccount?.AccountStatus_ENUM);
  }, [directDebitAccount?.AccountStatus_ENUM]);

  //#endregion INITIAL_VALUES =====/

  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleOnSubmit}
        initialValues={directDebitAccount}
        key={JSON.stringify(directDebitAccount)}
        render={(formRenderProps: FormRenderProps) => {
          setOnSubmitFlexible({
            formName: "GeneralForm",
            eventSubmit: formRenderProps.onSubmit,
          });
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <DirectDebitAccountFormElement
                  formRenderProps={formRenderProps}
                  isDisabled={isDisabled}
                />
              }
            />
          );
        }}
      />
    </div>
  );
});

import { DirectDebitSessionLovs } from "@app/products/direct-debit/sessions/components/dialogs/create-session/model";

import { DirectDebitSessionHeader } from "@app/products/direct-debit/sessions/model";
import { DirectDebitBankAccount } from "@app/products/direct-debit/system-admin/model";
import { directDebitBankAccountPickerOptions } from "@app/products/direct-debit/system-admin/status-codes/[id]/components/general/components/form-element/util";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { DATE_FORMAT } from "@common/constants/common-format";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCLabel } from "@components/cc-label/_index";
import { CCMultiSelectDropdown } from "@components/cc-multiple-selection-dropdown/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

const nameOf = nameOfFactory<DirectDebitSessionHeader>();
export const DDSessionTransferDetailsStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(({ formRenderProps }: IFormStepElement) => {
  const { valueGetter, onChange } = formRenderProps;

  const { dataForm } = useFlexibleFormStore();
  const ddSessionLOVs = dataForm?.GeneralFormLovs as DirectDebitSessionLovs;

  const [directDebitBankDescription, setDirectDebitBankDescription] =
    useState("");

  const getValueField = (nameOfField: keyof DirectDebitSessionHeader) =>
    valueGetter(nameOf(nameOfField));
  const onChangeField = (nameOfField: string, value: any) => {
    onChange(nameOf(nameOfField as keyof DirectDebitSessionHeader), {
      value: value,
    });
  };

  const handleChangeBankAccount = async (value: DirectDebitBankAccount) => {
    onChangeField(
      "Direct_Debit_Bank_Account_Id",
      value?.Direct_Debit_Bank_Account_Id
    );
    setDirectDebitBankDescription(value?.Description);
  };

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-2">
        <div className="cc-field">
          <CCLabel title="Bank account" isMandatory />
          <Field
            component={InputPickerSearch}
            name={nameOf("Direct_Debit_Bank_Account_Id")}
            value={directDebitBankDescription}
            options={directDebitBankAccountPickerOptions}
            onChange={handleChangeBankAccount}
            validator={requiredValidator}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="Transfer date" isMandatory />
          <Field
            component={CCDatePicker}
            name={nameOf("Transfer_Date")}
            value={getValueField(nameOf("Transfer_Date"))}
            format={DATE_FORMAT.DATE_CONTROL}
            validator={requiredValidator}
          />
        </div>
      </div>
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <CCLabel title="Include groups" isMandatory />
          <Field
            name={"IncludeGroupIdsTemp"}
            textField={"Value"}
            dataItemKey={"Key"}
            data={ddSessionLOVs?.IncludeGroups || []}
            component={CCMultiSelectDropdown}
            validator={requiredValidator}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="Include payment frequencies" isMandatory />
          <Field
            name={"IncludePaymentFrequenciesTemp"}
            textField={"Value"}
            dataItemKey={"Key"}
            data={ddSessionLOVs?.PaymentFrequencies ?? []}
            component={CCMultiSelectDropdown}
            validator={requiredValidator}
          />
        </div>
      </div>
    </section>
  );
});

import {
  BubbleUpIdentifier,
  RecordSourceIdentifier,
} from "@app/products/waste-water/[id]/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { DTOBaseClass_Standard } from "@common/models/baseClassStandard";
import { Keyword } from "@common/models/keyword";
import { UploadFileInfo } from "@progress/kendo-react-upload";

/**
 * Represents a document data.
 */
export interface IDocuments {
  ID: number;
  DocumentID: string;
  DynamicTitle?: string;
  RecordedDate?: Date;
  FileName?: string;
  RecordedBy?: string;
  Title?: string;
  Tag?: string;
  Status?: DOCUMENTSTATUS | null;
  Size?: number;
  FileNumber?: string;
  Category?: string;
  RecordType?: string;
  IsImage?: string;
  NavigateURL?: string;
  OpenURL?: string;
  WeblinkTitle?: string;
}

// TODO: Remove if un-use

export interface Attachment extends DTOBaseClass_Standard {
  Attachment_ID?: number;
  Tag?: string;
  Title?: string;
  FileEntry?: FileStreamEntry;
  TRIMUri?: string;
  RecordType?: string;
  Legacy_RecordSourceID?: number | null;
  Legacy_RecordSourceTypeID?: number | null;
  Category_KWD?: number | null;
  Category?: Keyword;
  DocumentTemplate_ID?: number | null;
  SortOrder?: number;
  JournalPOI_ENUM?: JournalPOI;
  ExternalReferenceId?: string | null;
  BubbleUps?: BubbleUpIdentifier[];
  AddToRecords?: boolean;
  UseParentFileNumber?: boolean;
  FileNumber?: string;
  Records_Notes?: string;
  Records_ParentRecordId?: string;
  CustomFieldList?: KeyValuePair<string, string>[];
  AvailableFieldsList?: KeyValuePair<string, string>[];
  PreferredAuthor_ID?: number | null;
  PreferredAuthor_DisplayName?: string;
  PreferredAuthor_UserName?: string;
  Manager_DisplayName?: string;
  Manager_UserName?: string;
  Offline_Ref?: string;
  FormattedAuthorName?: string;
  AssessmentNumber?: string;
  Status_ENUM?: DOCUMENTSTATUS | null;
  Status_Name?: string;
  DocumentClassification_ENUM?: DocumentClassification | null;
  DocumentClassification_Name?: string;
  ExternalDocumentType_KWD?: number | null;
  ExternalDocument?: boolean | null;
  SaveLocalCopy?: boolean;
  SubjectIndex?: string;
  FileFolderNumber?: string;
  ExternalDocument_ID?: string;
  SaveTriggers?: AttachmentUpdateTriggers[];
}

export interface IManageDocument extends Attachment {
  _option?: {
    FileUpload?: UploadFileInfo[];
    Title?: string;
  };
}

export interface Attachment_Display {
  Attachment_ID?: number;
  Tag?: string;
  Title?: string;
  Category_KWD?: number | null;
  Category?: string;
  Application_ID?: number;
  PublishedAttachmentInfo?: PublishedAttachmentInfo;
}

export interface AttachmentPublishInfo {
  Attachments_Paged?: PublishedAttachment[];
  TotalMatches?: number;
}

export interface PublishedAttachment {
  PublishDate?: Date;
  FileName?: string;
  ContentType?: string;
  FileSize?: number;
}

export interface PublishedAttachmentInfo extends PublishedAttachment {
  Flag_PublishToExternalService?: boolean;
  Flag_PublishToPublicRegister?: boolean;
}

export interface PublishSetup {
  RecordIDs?: string[];
  PublishDate?: Date;
  ForPublicDisplay?: boolean;
  PublishToPrivate?: boolean;
  Source?: RecordSourceIdentifier;
  Source_Child?: RecordSourceIdentifier;
  SingleUser_ContactID?: number | null;
  Submitter_Contact_RIDs?: number[];
}

export interface KeyValuePair<TKey, TValue> {
  Key?: TKey;
  Value?: TValue;
}

export interface FileStreamEntry {
  FileName?: string;
  ContentType?: string;
  FileSize?: number;
  FileAttachment?: string;
  Base64FileAttachment?: string;
  IsLightweight?: boolean;
}

export interface Svc_AddToRecordsRequest {
  AttachmentID: number;
  RecordID: number;
  RecordType: RECORDTYPE;
}

export enum JournalPOI {
  SystemInitialise = 0,
  ContactNameChange = 1500,
  ContactAddressChange = 1501,
  ContactPhoneNumberChanged = 1502,
  ContactVerificationChanged = 1503,
  ContactJobDetailsChanged = 1504,
  ObjectiveFolderWebLinkAdded = 1505,
  CRS_HBCCentreReferred = 1550,
  Core_FC = 1600,
  Core_LFC = 1601,
  Core_FCAddress = 1602,
  Core_StatClockStarted = 1603,
  Core_StatClockStopped = 1604,
  Core_EmailAdded = 1605,
  Core_CommentAdded = 1606,
  Core_ActionAdded = 1607,
  Core_DocumentAdded = 1608,
  Core_WebLinkAdded = 1609,
  Core_CommunicationSent = 1610,
  Core_CommunicationFailed = 1611,
  Core_ActionCreated = 1612,
  Core_ActionModified = 1613,
  Streatrader_RecordAccessed = 1900,
  Streatrader_NonParticipatingEdit = 1901,
  Streatrader_CouncilAdminOverride = 1902,
  CustomerService_EmailAdded = 3001,
  CustomerService_CommentAdded = 3002,
  CustomerService_ActionAdded = 3003,
  CustomerService_DocumentAdded = 3004,
  CustomerService_EventClosed = 3005,
  CustomerService_EventScheduled = 3006,
  CustomerService_EventReopened = 3007,
  CustomerService_WebLinkAdded = 3008,
  CustomerService_Categorised = 3009,
  CustomerService_NewRequest = 3010,
  CustomerService_LocationAddressModified = 3011,
  CustomerService_ReferredToActionOfficer = 3012,
  CustomerService_ReferredToCoordinator = 3013,
  CustomerService_EmailToSpecialInterest = 3014,
  CustomerService_RequestorReceipt = 3015,
  CustomerService_RequestorFinalNotification = 3016,
  CustomerService_RequestorRescheduledNotification = 3017,
  CustomerService_ReferredToRiskIssueOfficer = 3018,
  CustomerService_SafetyReason = 3019,
  CustomerService_ReferredToRecordsOfficer = 3020,
  CustomerService_EventPrinted = 3021,
  CustomerService_KB_SentForApproval = 3022,
  CustomerService_NotifiedAfterHoursEmail = 3023,
  CustomerService_NotifiedCouncillor = 3024,
  CustomerService_HardRubbish_AdminOverride = 3025,
  CustomerService_SafetyRemovedReason = 3026,
  CustomerService_Reassigned = 3027,
  CustomerService_NotifyExternalRequetor = 3028,
  CustomerService_NotifyOnBehalfOf = 3029,
  CustomerService_EmailToResponsibleOfficer = 3030,
  CustomerService_SMSRequestorOnClosure = 3031,
  TrainingManager_IssueForApproval = 4001,
  TrainingManager_ApprovedByApprover1 = 4002,
  TrainingManager_DeclinedByApprover1_NotifiedRequestor = 4003,
  TrainingManager_DeclinedByApprover1_NotifiedAdministrator = 4004,
  TrainingManager_ApprovedByApprover2 = 4005,
  TrainingManager_DeclinedByApprover2_NotifiedRequestor = 4006,
  TrainingManager_DeclinedByApprover2_NotifiedAdministrator = 4007,
  TrainingManager_AllocationConfirmed_NotifiedRequestor = 4008,
  TrainingManager_AllocationConfirmed_NotifiedAdministrator = 4009,
  TrainingManager_AllocationConfirmed_NotifiedOrgManager = 4010,
  TrainingManager_AllocationDeclined_NotifiedRequestor = 4011,
  TrainingManager_AllocationConfirmationRequired_NotifiedRequestor = 4012,
  TrainingManager_SubmitRequest = 4100,
  Portal_LinkedRecordsChanged = 5001,
  TP_Portal_Download = 5002,
  TP_Portal_SentEmail = 5003,
  TP_ePlanningDocFI = 6001,
  TP_ePlanningObjections = 6002,
  TP_ePlanningApplication = 6003,
  TP_ePlanningSC = 6004,
  TP_ePlanningEOT = 6005,
  TP_ePlanningAmendment = 6006,
  TP_ePlanningPlansToComply = 6007,
  TP_ePlanningCertification = 6008,
  TP_ePlanningRevision = 6009,
  TP_NSW_ePlanningLodgement = 6010,
  HM_AdministrationFeeCreated = 7001,
  HM_PortalUpdatedPremises = 7002,
  HM_PortalRenewedPremises = 7003,
  HM_PortalFlaggedReview = 7004,
  HM_PortalRecordedPayment = 7005,
  SRU_Application_NewRegistration_ReviewerSubmit_NotifiedOfficer = 8001,
  SRU_Application_NewRegistration_OfficerRecommendForApproval_NotifiedManager = 8002,
  SRU_Application_NewRegistration_OfficerRecommendForRefusal_NotifiedManager = 8003,
  SRU_Application_NewRegistration_ManagerEndorseApproval_NotifiedDelegate = 8004,
  SRU_Application_NewRegistration_ManagerEndorseRefusal_NotifiedDelegate = 8005,
  SRU_Application_NewRegistration_ManagerSendBack_NotifiedOfficer = 8006,
  SRU_Application_NewRegistration_DelegateSendBack_NotifiedOfficer = 8007,
  SRU_Application_NewRegistration_OfficerSentBackRecommendForApproval_NotifiedManager = 8008,
  SRU_Application_NewRegistration_OfficerSentBackRecommendForRefusal_NotifiedManager = 8009,
  SRU_Application_NewRegistration_DelegateProposeRefusal_NotifiedOfficer = 8010,
  SRU_Application_NewRegistration_DelegateProposeRefusal_NotifiedApplicant = 8011,
  SRU_Application_NewRegistration_ApplicantResponded_NotifiedPanelMember = 8012,
  SRU_Application_NewRegistration_PanelRecommendForApproval_NotifiedDelegate = 8013,
  SRU_Application_NewRegistration_PanelRecommendForRefusal_NotifiedDelegate = 8014,
  SRU_Application_NewRegistration_DelegateApproved_NotifiedOfficer = 8015,
  SRU_Application_NewRegistration_DelegateRefused_NotifiedOfficer = 8016,
  SRU_Application_NewRegistration_DelegateApproved_NotifiedApplicant = 8017,
  SRU_Application_NewRegistration_DelegateRefused_NotifiedApplicant = 8018,
  SRU_Application_NewRegistration_Cancelled_NotifiedOfficer = 8019,
  SRU_Application_ChangeOfDetails_ReviewerSubmit_NotifiedOfficer = 8020,
  SRU_Application_ChangeOfDetails_OfficerApproved_NotifiedApplicant = 8021,
  SRU_Application_ChangeOfDetails_OfficerRecommendForApproval_NotifiedManager = 8022,
  SRU_Application_ChangeOfDetails_ManagerEndorseForApproval_NotifiedDelegate = 8023,
  SRU_Application_ChangeOfDetails_ManagerSendBack_NotifiedOfficer = 8024,
  SRU_Application_ChangeOfDetails_DelegateSendBack_NotifiedOfficer = 8025,
  SRU_Application_ChangeOfDetails_OfficerSentBackRecommendForApproval_NotifiedManager = 8026,
  SRU_Application_ChangeOfDetails_DelegateApproved_NotifiedOfficer = 8027,
  SRU_Application_ChangeOfDetails_DelegateApproved_NotifiedApplicant = 8028,
  SRU_Application_ChangeOfDetails_OfficerCancelled_NotifiedApplicant = 8029,
  SRU_Issue_InitialNotification_ToOrganisation = 8033,
  SRU_Issue_OfficerRecommendForApproval_NotifiedManager = 8034,
  SRU_Issue_OfficerRecommendForRefusal_NotifiedManager = 8035,
  SRU_Issue_ManagerSendBack_NotifiedOfficer = 8036,
  SRU_Issue_Renotification_ToOrganisation = 8037,
  SRU_Issue_ManagerEndorseApproval_NotifiedOfficer = 8038,
  SRU_Issue_ManagerEndorseApproval_NotifiedDelegate = 8039,
  SRU_Issue_DelegateApproved_NotifiedOfficer = 8040,
  SRU_Issue_DelegateSendBack_NotifiedOfficer = 8041,
  SRU_Issue_CompletedNotification_ToOrganisation = 8042,
  SRU_Issue_OfficerSentBackRecommendForApproval_NotifyManager = 8043,
  SRU_Organisation_NotificationOfPlannedDate_ToFundedDivisions = 8044,
  SRU_Application_NewCondition_OfficerRecommendForApproval_NotifiedManager = 8050,
  SRU_Application_NewCondition_ManagerEndorseApproval_NotifiedDelegate = 8051,
  SRU_Application_NewCondition_ManagerSendBack_NotifiedOfficer = 8052,
  SRU_Application_NewCondition_DelegateSendBack_NotifiedOfficer = 8053,
  SRU_Application_NewCondition_OfficerSentBackRecommendForApproval_NotifiedManager = 8054,
  SRU_Application_NewCondition_DelegateProposeApproval_NotifiedOfficer = 8055,
  SRU_Application_NewCondition_DelegateProposeApproval_NotifiedOrganisation = 8056,
  SRU_Application_NewCondition_OrganisationResponseNotReceived_NotifiedDelegate = 8057,
  SRU_Application_NewCondition_OrganisationResponseSatisfactory_NotifiedDelegate = 8058,
  SRU_Application_NewCondition_OrganisationResponseUnsatisfactory_NotifiedDelegate = 8059,
  SRU_Application_NewCondition_DelegateApproved_NotifiedOfficer = 8060,
  SRU_Application_NewCondition_DelegateCancelled_NotifiedOfficer = 8061,
  SRU_Application_NewCondition_DelegateApproved_NotifiedOrganisation = 8062,
  SRU_Application_NewCondition_DelegateRefused_NotifiedOrganisation = 8063,
  SRU_Application_NewCondition_Cancelled_NotifiedOfficer = 8064,
  SRU_Application_RegistrationRevocation_OfficerRecommendForApproval_NotifiedManager = 8070,
  SRU_Application_RegistrationRevocation_ManagerEndorseApproval_NotifiedDelegate = 8071,
  SRU_Application_RegistrationRevocation_ManagerSendBack_NotifiedOfficer = 8072,
  SRU_Application_RegistrationRevocation_DelegateSendBack_NotifiedOfficer = 8073,
  SRU_Application_RegistrationRevocation_OfficerSentBackRecommendForApproval_NotifiedManager = 8074,
  SRU_Application_RegistrationRevocation_DelegateProposeApproval_NotifiedOfficer = 8075,
  SRU_Application_RegistrationRevocation_DelegateProposeApproval_NotifiedOrganisation = 8076,
  SRU_Application_RegistrationRevocation_OrganisationResponseNotReceived_NotifiedDelegate = 8077,
  SRU_Application_RegistrationRevocation_OrganisationResponseSatisfactory_NotifiedDelegate = 8078,
  SRU_Application_RegistrationRevocation_OrganisationResponseUnsatisfactory_NotifiedDelegate = 8079,
  SRU_Application_RegistrationRevocation_DelegateApproved_NotifiedOfficer = 8080,
  SRU_Application_RegistrationRevocation_DelegateCancelled_NotifiedOfficer = 8081,
  SRU_Application_RegistrationRevocation_DelegateApproved_NotifiedOrganisation = 8082,
  SRU_Application_RegistrationRevocation_DelegateRefused_NotifiedOrganisation = 8083,
  SRU_Application_RegistrationRevocation_Cancelled_NotifiedOfficer = 8084,
  SRU_Organisation_CertificationSuspended_NotifyDelegate = 8091,
  SRU_Application_EnforcementPlan_OfficerRecommendForApproval_NotifiedManager = 8092,
  SRU_Application_EnforcementPlan_ManagerEndorseApproval_NotifiedDelegate = 8093,
  SRU_Application_EnforcementPlan_ManagerSendBack_NotifiedOfficer = 8094,
  SRU_Application_EnforcementPlan_DelegateSendBack_NotifiedOfficer = 8095,
  SRU_Application_EnforcementPlan_OfficerSentBackRecommendForApproval_NotifiedManager = 8096,
  SRU_Application_EnforcementPlan_DelegateProposeApproval_NotifiedOfficer = 8097,
  SRU_Application_EnforcementPlan_DelegateProposeApproval_NotifiedOrganisation = 8098,
  SRU_Application_EnforcementPlan_OrganisationResponseNotReceived_NotifiedDelegate = 8099,
  SRU_Application_EnforcementPlan_OrganisationResponseSatisfactory_NotifiedDelegate = 8100,
  SRU_Application_EnforcementPlan_OrganisationResponseUnsatisfactory_NotifiedDelegate = 8101,
  SRU_Application_EnforcementPlan_DelegateApproved_NotifiedOfficer = 8102,
  SRU_Application_EnforcementPlan_DelegateCancelled_NotifiedOfficer = 8103,
  SRU_Application_EnforcementPlan_DelegateApproved_NotifiedOrganisation = 8104,
  SRU_Application_EnforcementPlan_DelegateRefused_NotifiedOrganisation = 8105,
  SRU_Application_EnforcementPlan_Cancelled_NotifiedOfficer = 8106,
  SRU_Application_ChangeOfDetails_SRS_ReviewerSubmit_NotifiedOfficer = 8107,
  SRU_Application_ChangeOfDetails_SRS_OfficerApproved_NotifiedApplicant = 8108,
  SRU_Application_ChangeOfDetails_SRS_OfficerRecommendForApproval_NotifiedManager = 8109,
  SRU_Application_ChangeOfDetails_SRS_ManagerEndorseForApproval_NotifiedDelegate = 8110,
  SRU_Application_ChangeOfDetails_SRS_ManagerSendBack_NotifiedOfficer = 8111,
  SRU_Application_ChangeOfDetails_SRS_DelegateSendBack_NotifiedOfficer = 8112,
  SRU_Application_ChangeOfDetails_SRS_OfficerSentBackRecommendForApproval_NotifiedManager = 8113,
  SRU_Application_ChangeOfDetails_SRS_DelegateApproved_NotifiedOfficer = 8114,
  SRU_Application_ChangeOfDetails_SRS_DelegateApproved_NotifiedApplicant = 8115,
  SRU_Application_ChangeOfDetails_SRS_OfficerCancelled_NotifiedApplicant = 8116,
  SRU_Application_ChangeOfDetails_SRS_DelegateRefused_NotifiedOfficer = 8117,
  SRU_Application_ChangeOfDetails_SRS_DelegateRefused_NotifiedApplicant = 8118,
  SRU_Application_ChangeOfDetails_SRS_ManagerRefused_NotifiedOfficer = 8119,
  SRU_Application_ChangeOfDetails_SRS_ManagerRefused_NotifiedApplicant = 8120,
  SRU_Application_ReportableIncident_OfficerRecommendForApproval_NotifiedManager = 8130,
  SRU_Application_ReportableIncident_ManagerEndorseForApproval_NotifiedDelegate = 8131,
  SRU_Application_ReportableIncident_ManagerSendBack_NotifiedOfficer = 8132,
  SRU_Application_ReportableIncident_DelegateSendBack_NotifiedOfficer = 8133,
  SRU_Application_ReportableIncident_OfficerSentBackRecommendForApproval_NotifiedManager = 8134,
  SRU_Application_ReportableIncident_DelegateApproved_NotifiedOfficer = 8135,
  DWCMS_Application_NewRegistration_DueDateExtended = 8501,
  DWCMS_Application_NewRegistration_Withdrawn = 8502,
  DWCMS_Application_NewRegistration_FIReceived = 8503,
  DWCMS_Application_NewRegistration_FIRequested = 8504,
  DWCMS_Application_PublishedToWeb = 8505,
  DWCMS_Application_NewRegistration_DueDateNoChange = 8506,
  DWCMS_Application_Withdrawn_Rollback = 8507,
}

export enum DocumentClassification {
  Unclassified = "Unclassified",
  TP_PSAR_ExhibitionDocs = "TP_PSAR_ExhibitionDocs",
  TP_PSAR_HearingDocs = "TP_PSAR_HearingDocs",
  TP_PSAR_WorkingDocs = "TP_PSAR_WorkingDocs",
}

export enum DocumentClassificationNumber {
  Unclassified = 0,
  TP_PSAR_ExhibitionDocs = 1,
  TP_PSAR_HearingDocs = 2,
  TP_PSAR_WorkingDocs = 3,
}

export enum AttachmentUpdateTriggers {
  UpdateTitle,
  UpdatePOI,
}

export interface Svc_Attachment {
  ParentID: number;
  ParentRecordType: RECORDTYPE;
  AncestorID?: number | null;
  AncestorRecordType?: RECORDTYPE | null;
  AttachmentList: Attachment[];
  AddDateTimeToFileName: boolean | null;
  BubbleUps?: BubbleUpIdentifier[];
}
export interface WebLink extends DTOBaseClass_Standard {
  WebLink_ID?: number;
  WebLinkURI?: string;
  Tag?: string;
  WeblinkCategory_KWD?: number | null;
  BubbleUps?: BubbleUpIdentifier[];
}

export interface IImage {
  ID?: number;
  RecordType?: number;
  Title?: string;
  ImageFile?: string;
  Sort?: number;
}

export enum eCheckType {
  FILE = "1",
  WEB_LINK = "2",
  IMAGE = "1",
}

export enum MAILMERGEDOCUMENTUSAGE {
  SystemInitialise = 0,
  CustomDocumentTemplates = 51,
  CORE_LiveData = 52,
  HM_RegistrationCertificate = 101,
  HM_RenewalNotice = 102,
  HM_RegistrationCertificate_NoNotifications = 103,
  HM_RegistrationCertificate_OnlyNotifications = 104,
  HM_OutstandingFeesLetter = 180,
  HM_OutstandingRenewalFeesLetter = 181,
  HM_Invoice = 191,
  HM_Receipt = 192,
  HM_Premises = 101,
  CRS_CBCRegistration = 301,
  CRS_3yoRegistration = 302,
  CRS_4yoRegistration = 303,
  CRS_HBCRegistration = 304,
  CRS_Registration = 305,
  CRS_AcknowledgementService = 306,
  CRS_SwitchFromCentreToGroup3YO = 307,
  CRS_SwitchFromCentreToGroup4YO = 308,
  CRS_POA_3YO = 309,
  CRS_POA_4YO = 310,
  DISRES_PermitLabel_SpecialPurpose_Paper = 401,
  DISRES_PermitLabel_SpecificVehicle_Paper = 402,
  DISRES_PermitLabel_Staff_Paper = 403,
  DISRES_PermitLabel_Variable_Paper = 404,
  DISRES_PermitLabel_Tradesman_Paper = 405,
  DISRES_PermitLabel_TemporaryStaff_Paper = 406,
  DISRES_PermitLabel_Visitor_Paper = 407,
  DISRES_PermitLabel_ContractManagedGeneral_Paper = 408,
  DISRES_PermitLabel_TemporaryResident_Paper = 409,
  DISRES_PermitLabel_Disabled_Paper = 410,
  DISRES_PermitLabel_DisabledOrg_Paper = 411,
  DISRES_PermitLabel_SpecialPurpose_Sticky = 412,
  DISRES_PermitLabel_SpecificVehicle_Sticky = 413,
  DISRES_PermitLabel_Staff_Sticky = 414,
  DISRES_PermitLabel_Variable_Sticky = 415,
  DISRES_PermitLabel_Tradesman_Sticky = 416,
  DISRES_PermitLabel_TemporaryStaff_Sticky = 417,
  DISRES_PermitLabel_Visitor_Sticky = 418,
  DISRES_PermitLabel_ContractManaged_SpecificVehicle_Sticky = 419,
  DISRES_PermitLabel_TemporaryResident_Sticky = 420,
  DISRES_PermitLabel_Disabled_Sticky = 421,
  DISRES_PermitLabel_DisabledOrg_Sticky = 422,
  DISRES_PermitLabel_DisabledTemporary_Paper = 423,
  DISRES_PermitLabel_Municipal_Paper = 424,
  DISRES_AddressLabel = 425,
  DISRES_PermitLabel_DisabledTemporary_Sticky = 426,
  DISRES_PermitLabel_Municipal_Sticky = 427,
  DISRES_PermitLabel_ContractManaged_SpecificVehicle_Paper = 428,
  DISRES_PermitLabel_ContractManagedGeneral_Sticky = 429,
  CM_PES = 501,
  CM_LicenceCertificate = 502,
  CM_TOL = 503,
  CM_LicenceRenewalQuote = 504,
  CM_ExhumationOrder = 505,
  CM_RenewalNotice = 506,
  CM_ExpiryNotice1 = 507,
  CM_ExpiryNotice2 = 508,
  CM_SurrenderLicenceDocument = 509,
  CM_SurrenderLicenceCompletionNotice = 510,
  CM_MemorialWorksOrder = 511,
  CM_MemorialWorksOrderPermit = 512,
  CM_CrematedRemainsRemovalOrderDocument = 513,
  CM_CremationOrderPrintLabel = 514,
  CM_BookingReceipt_BurialInterment = 515,
  CM_BookingReceipt_ChapelService = 516,
  CM_BookingReceipt_CremationOrder = 517,
  CM_BookingReceipt_CremationMemorial = 518,
  CM_BookingReceipt_Exhumation = 519,
  CM_BookingConfirmation_BurialInterment = 520,
  CM_BookingConfirmation_ChapelService = 521,
  CM_BookingConfirmation_CremationOrder = 522,
  CM_BookingConfirmation_CremationMemorial = 523,
  CM_BookingConfirmation_Exhumation = 524,
  CM_ConsultationQuote = 525,
  CM_ConsultationConfirmation = 526,
  CM_POSTransactionReceipt = 527,
  CM_MemorialServiceReceipt_ChapelService = 528,
  CM_MemorialServiceConfirmation_ChapelService = 529,
  CM_CRIO_AttendanceConfirmation = 530,
  CM_CRSO_PrintLabels = 531,
  CM_BookingConfirmation_TrustManagement = 532,
  CM_BookingInvoice_FuneralDirector = 533,
  CM_Booking_BurialCertificate = 534,
  CM_Booking_CremationCertificate = 535,
  CM_CrematedRemains_PrintLabels = 536,
  SRS_Certificate = 601,
  CUSTOMERSERVICE_Event = 701,
  CUSTOMERSERVICE_Event_FinalNotice = 702,
  CUSTOMERSERVICE_ColdChainBreach_Advice = 703,
  CUSTOMERSERVICE_Event_Acknowledgement = 704,
  TOWNPLANNING_Application = 801,
  TOWNPLANNING_Application_Advertising = 802,
  TOWNPLANNING_Application_Appeals = 803,
  TOWNPLANNING_Application_Fees = 804,
  TOWNPLANNING_Application_FurtherInformationRequests = 805,
  TOWNPLANNING_Application_Inspections = 806,
  TOWNPLANNING_Application_Notices = 807,
  TOWNPLANNING_Application_WithRegions = 808,
  TOWNPLANNING_Subdivision = 809,
  BUILDING_CertificateOfCompletion = 851,
  BUILDING_Application_FurtherInformationRequests = 852,
  LAMP_ConditionPreview = 901,
  LAMP_Application = 902,
  LAMP_Authorisation = 903,
  LAMP_Application_Advertising = 904,
  LAMP_Application_FurtherInformationRequests = 905,
  LAMP_Application_Region = 906,
  LAMP_Authorisation_Region = 907,
  LAMP_Invoice_WithDetails = 910,
  LAMP_Invoice_NoDetails = 911,
  LAMP_AuthorisationCertificate = 912,
  LAMP_AuthorisationDraftCertificate = 913,
  Register_CouncilConsent = 1101,
  Register_Demolition = 1102,
  Register_ESBuilding = 1103,
  Register_NonCompliance = 1104,
  Register_PlanningCertficate = 1105,
  Register_PlanningEnquiry = 1106,
  Register_PlansToComply = 1107,
  Register_POPE = 1108,
  Register_SecondaryConsent = 1109,
  Register_EOT = 1110,
  Register_FurtherInformationRequests = 1111,
  Register_BuildingCertificate = 1112,
  Register_Contacts = 1113,
  Register_BuildingPoolSpa = 1114,
  MCH_Appointment = 1201,
  MCH_Registration = 1202,
  AMS_Appointment = 1251,
  EMS_EventBooking = 1301,
  EMS_EventBooking_WithRegion = 1302,
  TrainingManager_CourseOutline = 1351,
  TrainingManager_CourseSchedule = 1352,
  TrainingManager_TrainingRequest = 1353,
  TrainingManager_CourseOutline_Doc2 = 1354,
  TrainingManager_CourseSchedule_Doc2 = 1355,
  TrainingManager_TrainingRequest_Doc2 = 1356,
  TrainingManager_CourseOutline_Doc3 = 1357,
  TrainingManager_CourseSchedule_Doc3 = 1358,
  TrainingManager_TrainingRequest_Doc3 = 1359,
  DWCMS_RegistrationCertificate = 1401,
  DWCMS_Application = 1402,
  DWCMS_Worker = 1403,
  DWCMS_Registration = 1404,
}

export enum DOCUMENTSTATUS {
  SystemInitialise = "SystemInitialise",
  Draft = "Draft",
  Final = "Final",
}

export enum DOCUMENT_STATUS_NUNBER {
  SystemInitialise = 0,
  Draft = 1,
  Final = 2,
}

import { AccountHistoryTab } from "@app/products/direct-debit/accounts/[id]/components/slidebar/history/_index";
import { colDirectDebitAccount } from "@app/products/direct-debit/accounts/config";
import { DirectDebitAccount } from "@app/products/direct-debit/accounts/model";
import { DDAccountsBookmark } from "@app/products/direct-debit/accounts/util";
import { DDContactsTab } from "@app/products/direct-debit/components/sidebar/contact";
import {
  DIRECT_DEBIT_ACCOUNTS,
  getDirectDebitAccountsOdataByMenuType,
} from "@app/products/direct-debit/constant";
import { DirectDebitMenu } from "@app/products/direct-debit/model";
import { directDebitRoute } from "@app/products/direct-debit/route";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { CCActionBarNavDropdownNew } from "@components/cc-list-view-action-bar/components/nav-dropdown-new/_index";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";

const nameOf = nameOfFactory<DirectDebitAccount>();

export default () => {
  useCCListViewActionBar({
    title: directDebitRoute.name,
    leftComponents: [<CCActionBarNavDropdownNew />],
    centerComponents: [<CCNavButton title="New" />],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={DIRECT_DEBIT_ACCOUNTS}
        productType={PRODUCT_TYPE.DirectDebit}
        recordType={RECORDTYPE.Receipting_DirectDebit_Account}
        detail={DDAccountsBookmark.getBookmarkListViewDisplayName}
        displayName={DDAccountsBookmark.getBookmarkListViewDetailRecord}
        listViewDetail={() =>
          DDAccountsBookmark.getBookmarkListViewDetail("By Account")
        }
        listViewDisplayName={DDAccountsBookmark.getBookmarkListViewDisplayName}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Contact",
        component: <DDContactsTab />,
      },
      {
        title: "History",
        component: <AccountHistoryTab />,
      },
    ],
  });

  return (
    <CCProductListView
      columnFields={colDirectDebitAccount}
      dataUrl={getDirectDebitAccountsOdataByMenuType(
        DirectDebitMenu.AccountsByAccount
      )}
      primaryField={nameOf("Account_ID")}
      state={{
        sort: [{ field: nameOf("Account_ID"), dir: "desc" }],
      }}
    />
  );
};

import { IntegrationModuleItemType } from "@app/products/direct-debit/system-admin/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IntegrationModuleItemType>();

export const colModuleItemTypes: IColumnFields[] = [
  {
    field: nameOf("Item_Type_ID"),
    title: "Item Type Id",
    locked: true,
    // linkTo: (dataItem: IntegrationModuleItemType) => {
    //   return (
    //     `${DIRECT_DEBIT_MODULE_ITEM_TYPES}/` + dataItem.Item_Type_ID
    //   );
    // },
  },
  {
    field: nameOf("Name"),
    title: "Name",
  },
  {
    field: nameOf("Integration_Module_Type_Name"),
    title: "Module Type",
  },
  {
    field: nameOf("Register_ID"),
    title: "Register Id",
  },
];

import { DirectDebitMenu } from "@app/products/direct-debit/model";
import { INotificationPortalContentExcludeId } from "@components/cc-notification-portal/components/notification-portal-content/model";

export const DIRECT_DEBIT_ACCOUNTS = "/direct-debit/accounts";
export const DIRECT_DEBIT_AUTHORISATIONS = "/direct-debit/authorisations";
export const DIRECT_DEBIT_SESSIONS = "/direct-debit/sessions";
export const DIRECT_DEBIT_SYSTEM_ADMIN = "/direct-debit/system-admin";
// system admin list view
export const DIRECT_DEBIT_BANK_ACCOUNTS =
  DIRECT_DEBIT_SYSTEM_ADMIN + "/bank-accounts";
export const DIRECT_DEBIT_GROUPS = DIRECT_DEBIT_SYSTEM_ADMIN + "/groups";
export const DIRECT_DEBIT_MODULE_ITEM_TYPES =
  DIRECT_DEBIT_SYSTEM_ADMIN + "/module-item-types";
export const DIRECT_DEBIT_STATUS_CODES =
  DIRECT_DEBIT_SYSTEM_ADMIN + "/status-codes";

export const getDirectDebitAccountsOdataByMenuType = (
  menuType: DirectDebitMenu
) => {
  return `odata/receipting/internal/directdebitaccountRegister/GetAccounts(menuType=${menuType})?$count=true&`;
};
export const getDirectDebitAuthorisationsOdataByMenuType = (
  menuType: DirectDebitMenu
) => {
  return `odata/receipting/internal/directdebitauthorisationregister/GetAuthorisations(menuType=${menuType})?$count=true&`;
};
export const DIRECT_DEBIT_ACCOUNTS_GROUPS_ODATA =
  "odata/receipting/internal/directdebitgroupregister?$count=true&";
export const getDirectDebitBankAccountURL = (isActive: boolean = false) => {
  return `odata/receipting/internal/directdebitbankaccountregister/GetBankAccounts(isActive=${isActive})?$count=true&`;
};
export const DIRECT_DEBIT_GROUPS_ODATA =
  "odata/receipting/internal/directdebitgroupregister?$count=true&";
export const DIRECT_DEBIT_MODULE_ITEM_TYPES_ODATA =
  "odata/receipting/internal/integrationmoduleitemtyperegister?$count=true&";
export const DIRECT_DEBIT_MODULE_STATUS_CODES_ODATA =
  "odata/receipting/internal/directdebitstatuscoderegister?$count=true&";

export const alertDeleteRecord: INotificationPortalContentExcludeId = {
  level: 1,
  autoClose: false,
  title: "Important Note",
  type: "info",
  description:
    "You are viewing a deleted record. You are not allowed to perform any Workflow functions or make changes to this record.",
};

export const getDirectDebitSessionOdataByMenuType = (
  menuType: DirectDebitMenu
) => {
  return `odata/receipting/internal/directdebitsessionheaderRegister/GetSessions(menuType=${menuType})?$count=true&`;
};

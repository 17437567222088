import { FormIdentifier } from "@app/products/crms/model";
import { getDirectDebitSessionBySessionID } from "@app/products/direct-debit/sessions/[id]/api";
import { ExistManageDirectDebitSession } from "@app/products/direct-debit/sessions/[id]/existed/_index";
import { TitleDirectDebitSessionManagePage } from "@app/products/direct-debit/sessions/constant";
import { DirectDebitSessionHeader } from "@app/products/direct-debit/sessions/model";
import { APIResponse } from "@common/apis/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

const ManageDirectDebitSession = () => {
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.DirectDebit_Dialog_Session,
    productType: PRODUCT_TYPE_NUMBER.DirectDebit,
  });

  // #region STORE
  const { listenNotificationPortal, clearNotifications } =
    useNotificationPortalStore();
  const { setDataForm, resetDataForm, isLoadingForm } = useFlexibleFormStore();
  // #endregion STORE

  // #region HOOK
  const { cancelToken } = useCancelRequest();
  const history = useHistory();
  // #endregion HOOK

  // #region Variable
  let { id } = useParams<{ id: string }>();
  // #endregion Variable

  const manageDirectDebitSessionSlider = useFlexibleFetchData({
    alias: "manageDirectDebitSessionSlider",
    slides: [
      {
        fetch: () =>
          getDirectDebitSessionBySessionID(parseInt(id), cancelToken()),
        handleSuccess: ({ dataFromApi }) => {
          const response: APIResponse<DirectDebitSessionHeader> = dataFromApi;
          const ddGroupFormData = response?.data;
          //#region save data to store
          setDataForm({
            GeneralForm: ddGroupFormData,
          });
          //#endregion save data to store

          //#region Notification
          listenNotificationPortal();
          //#endregion Notification
        },
      },
    ],
  });

  useEffect(() => {
    manageDirectDebitSessionSlider.fetchApi();
    return () => {
      resetDataForm();
      clearNotifications();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);

  return (
    <>
      <Loading
        isLoading={
          manageDirectDebitSessionSlider.isFetching ||
          isLoadingForm ||
          isLoadingPermission
        }
        isFullScreen
      />
      <FormNavigation title={TitleDirectDebitSessionManagePage} />
      {manageDirectDebitSessionSlider.errorComponent ? (
        manageDirectDebitSessionSlider.errorComponent
      ) : (
        <ExistManageDirectDebitSession checkPermissions={checkPermissions} />
      )}
    </>
  );
};

export default observer(ManageDirectDebitSession);

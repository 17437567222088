import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { postDirectDebitAccount } from "@app/products/direct-debit/accounts/[id]/api";
import { checkIsInactiveDirectDebitAccount } from "@app/products/direct-debit/accounts/[id]/util";
import { DirectDebitAccount } from "@app/products/direct-debit/accounts/model";
import { APIResponse } from "@common/apis/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { IdentityPacket } from "@common/models/identityPacket";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const SaveButtonDirectDebitAccount = observer(() => {
  //#region STORE ========/
  const { submitFormGetData, isLoadingForm, setIsLoadingForm } =
    useFlexibleFormStore();

  //#endregion STORE =====/

  //#region HOOK ========/
  const { pushNotificationPortal } = useNotificationPortalStore();
  const { cancelToken } = useCancelRequest();
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.DirectDebit_Dialog_Account,
    productType: PRODUCT_TYPE_NUMBER.DirectDebit,
  });
  //#endregion HOOK =====/

  const slider = useFlexibleFetchData({
    slides: [
      {
        fetch: ({ initialData }) => {
          const directDebitAccount: DirectDebitAccount = initialData;
          return postDirectDebitAccount(directDebitAccount, cancelToken());
        },
        handleSuccess: ({ dataFromApi }) => {
          const response: APIResponse<IdentityPacket | undefined> = dataFromApi;
          if (response?.data?.ID) {
            pushNotificationPortal({
              title: "Direct debit account saved successfully.",
              type: "success",
            });
          } else {
            pushNotificationPortal({
              title: "Direct debit account not found.",
              type: "error",
              autoClose: false,
            });
          }
          return false;
        },
        handleError: (error) => {
          const { errorFromApi, initialData } = error;
          pushNotificationPortal({
            title: initialData?.errorMsg,
            type: "error",
            description: errorFromApi.error,
            autoClose: false,
          });
        },
      },
    ],
  });

  const handleClickButton = async () => {
    const dataForm = await submitFormGetData("GeneralForm");
    const directDebitAccount = dataForm?.GeneralForm as DirectDebitAccount;

    if (directDebitAccount) {
      setIsLoadingForm(true);
      await slider.fetchApi({
        initialData: directDebitAccount,
      });
      setIsLoadingForm(false);
    }
  };

  return (
    <CCNavButton
      title="Save"
      onClick={handleClickButton}
      disabled={
        isLoadingForm ||
        checkIsInactiveDirectDebitAccount() ||
        isLoadingPermission ||
        !checkPermissions([FormAction.CORE_ALLOW_EDIT])
      }
    />
  );
});

import { format } from "@telerik/kendo-intl";
import { isNil, isNumber, trim } from "lodash";
export const validateBSB = (value: any) => {
  const regexBSb = new RegExp(/^\d{3}-\d{3}$/);
  if (isNil(value) || value?.length === 0) return "This field is required.";
  return !isNil(value) && value?.length && regexBSb.test(value)
    ? undefined
    : "BSB must be 6 digits.";
};

export const formatBSBInMasked = (value: number | string): string => {
  if (isNil(value)) return "";
  const bsbNumberStr = isNumber(value) ? String(value) : value?.trim();

  const formatValue = format(
    "{0:n}{1:n}{2:n}-{3:n}{4:n}{5:n}", //mask="000-000"
    Array.from(bsbNumberStr)
  );

  return trim(formatValue, "-");
};

import { alertDeleteRecord } from "@app/products/direct-debit/constant";
import { getDirectDebitBankAccountById } from "@app/products/direct-debit/system-admin/bank-accounts/[id]/api";
import { ExistManageDirectDebitBankAccount } from "@app/products/direct-debit/system-admin/bank-accounts/[id]/existed/_index";
import { NewManageDirectDebitBankAccount } from "@app/products/direct-debit/system-admin/bank-accounts/[id]/new/_index";
import { checkIsInactiveDirectDebitBankAccount } from "@app/products/direct-debit/system-admin/bank-accounts/[id]/util";
import { TitleDirectDebitBankAccountManagePage } from "@app/products/direct-debit/system-admin/bank-accounts/constant";
import { DirectDebitBankAccount } from "@app/products/direct-debit/system-admin/model";
import { APIResponse } from "@common/apis/model";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useIsNew } from "@common/hooks/useIsNew";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageDirectDebitBankAccount = () => {
  //#region STORE ========/
  const {
    listenNotificationPortal,
    pushNotificationPortal,
    clearNotifications,
  } = useNotificationPortalStore();
  const history = useHistory();
  const { setDataForm, resetDataForm, isLoadingForm } = useFlexibleFormStore();
  useEffectOnce(() => {
    return () => {
      resetDataForm();
    };
  });
  //#endregion STORE =====/

  //#region HOOK ========/
  const { cancelToken } = useCancelRequest();
  const isNew = useIsNew();
  //#endregion HOOK =====/

  //#region Variable ========/
  let { id } = useParams<{ id: string }>();
  if (isNew) id = "0";
  //#endregion Variable =====/

  const manageDirectDebitBankAccountSlider = useFlexibleFetchData({
    alias: "manageDirectDebitBankAccountSlider",
    slides: [
      {
        fetch: () => getDirectDebitBankAccountById(+id, cancelToken()),
        handleSuccess: ({ dataFromApi }) => {
          const response: APIResponse<DirectDebitBankAccount> = dataFromApi;
          const ddBankAccountFormData = response?.data;
          //#region save data to store ========/
          setDataForm({
            GeneralForm: ddBankAccountFormData,
          });
          //#endregion save data to store =====/

          //#region Notification ========/
          listenNotificationPortal();

          if (checkIsInactiveDirectDebitBankAccount()) {
            pushNotificationPortal(alertDeleteRecord, true);
          }
          //#endregion Notification =====/
        },
      },
    ],
  });

  useEffect(() => {
    manageDirectDebitBankAccountSlider.fetchApi();
    return () => clearNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);

  return (
    <>
      <Loading
        isLoading={
          manageDirectDebitBankAccountSlider.isFetching || isLoadingForm
        }
        isFullScreen
      />
      <FormNavigation title={TitleDirectDebitBankAccountManagePage} />
      {manageDirectDebitBankAccountSlider.errorComponent ? (
        manageDirectDebitBankAccountSlider.errorComponent
      ) : isNew ? (
        <NewManageDirectDebitBankAccount />
      ) : (
        <ExistManageDirectDebitBankAccount />
      )}
    </>
  );
};

export default observer(ManageDirectDebitBankAccount);

import { DBRowState } from "@app/products/crms/[id]/model";
import {
  DIRECT_DEBIT_BANK_ACCOUNTS,
  getDirectDebitBankAccountURL,
} from "@app/products/direct-debit/constant";
import { directDebitRoute } from "@app/products/direct-debit/route";
import { DeleteDirectDebitBankAccountButton } from "@app/products/direct-debit/system-admin/bank-accounts/[id]/components/buttons/delete/_index";
import { SysAdminBankAccountHistoryTab } from "@app/products/direct-debit/system-admin/bank-accounts/[id]/components/slidebar/history/_index";
import { colDirectDebitBankAccount } from "@app/products/direct-debit/system-admin/bank-accounts/config";
import { DirectDebitBankAccount } from "@app/products/direct-debit/system-admin/model";
import { DDSystemAdminBookmark } from "@app/products/direct-debit/system-admin/util";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { CCActionBarNavDropdownNew } from "@components/cc-list-view-action-bar/components/nav-dropdown-new/_index";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";
import { useHistory } from "react-router-dom";
const nameOf = nameOfFactory<DirectDebitBankAccount>();

export default () => {
  const history = useHistory();

  useCCListViewActionBar({
    title: directDebitRoute.name,
    leftComponents: [<CCActionBarNavDropdownNew />],
    centerComponents: [
      <CCNavButton
        title="New"
        onClick={() => {
          history.push(`${DIRECT_DEBIT_BANK_ACCOUNTS}/new`);
        }}
      />,
      <DeleteDirectDebitBankAccountButton />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={DIRECT_DEBIT_BANK_ACCOUNTS}
        productType={PRODUCT_TYPE.DirectDebit}
        recordType={RECORDTYPE.Receipting_Direct_Debit_Bank_Account}
        detail={DDSystemAdminBookmark.getBookmarkListViewDisplayName}
        displayName={
          DDSystemAdminBookmark.getBookmarkListViewDetailRecordBankAccount
        }
        listViewDetail={() =>
          DDSystemAdminBookmark.getBookmarkListViewDetail("Bank Accounts")
        }
        listViewDisplayName={
          DDSystemAdminBookmark.getBookmarkListViewDisplayName
        }
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "History",
        component: <SysAdminBankAccountHistoryTab />,
      },
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "History",
        component: <SysAdminBankAccountHistoryTab />,
      },
    ],
  });

  return (
    <>
      <CCProductListView
        columnFields={colDirectDebitBankAccount}
        dataUrl={getDirectDebitBankAccountURL()}
        primaryField={nameOf("Direct_Debit_Bank_Account_Id")}
        state={{
          sort: [{ field: nameOf("Direct_Debit_Bank_Acc_No"), dir: "desc" }],
          filter: {
            filters: [
              {
                field: nameOf("Sys_DBRowState"),
                operator: "eq",
                value: DBRowState.Active,
              },
            ],
            logic: "and",
          },
        }}
      />
    </>
  );
};

import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import { TabTableType } from "@app/core/tab-table/model";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { DDSessionLineByAccountNumber } from "@app/products/direct-debit/accounts/[id]/components/accordion/transfers/_index";
import { SaveButtonDirectDebitAccount } from "@app/products/direct-debit/accounts/[id]/components/button/save";
import { SetStatusButtonDirectDebit } from "@app/products/direct-debit/accounts/[id]/components/button/set-status";
import { DirectDebitAccountForm } from "@app/products/direct-debit/accounts/[id]/components/general/_index";
import { AccountHistoryTab } from "@app/products/direct-debit/accounts/[id]/components/slidebar/history/_index";
import { useDirectDebitStore } from "@app/products/direct-debit/accounts/[id]/store";
import {
  DirectDebitAccount,
  DirectDebitAccountStatus,
} from "@app/products/direct-debit/accounts/model";
import { DDContactsTab } from "@app/products/direct-debit/components/sidebar/contact";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { YieldNotificationPortal } from "@components/cc-notification-portal/_index";
import { TypePlaceId } from "@components/cc-notification-portal/constant";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
export const ExistManageDirectDebitAccount = observer(() => {
  const { id } = useParams<{ id: string }>();

  // #region STORE
  const { currentUserInfo } = useGlobalStore();
  const { dataForm } = useFlexibleFormStore();
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.DirectDebit_Dialog_Account,
    productType: PRODUCT_TYPE_NUMBER.DirectDebit,
  });
  const { initAccountContact } = useDirectDebitStore();
  // #endregion

  const [showSlideBar, setShowSlideBar] = useState(true);

  const directDebitAccount = dataForm?.GeneralForm as DirectDebitAccount;
  const listPanelBar: ITabProps[] = [
    {
      title: "Transfers",
      tabType: TabTableType.DDTransfers,
      component: (
        <DDSessionLineByAccountNumber
          accountNumber={directDebitAccount?.Account_Number}
        />
      ),
    },
  ];

  const getFormTitle = useMemo(() => {
    var title = `${directDebitAccount?.Account_Number}`;
    if (directDebitAccount?.Name) title += ` - ${directDebitAccount?.Name}`;
    if (directDebitAccount?.Integration_Module_Type_Name)
      title += ` - ${directDebitAccount?.Integration_Module_Type_Name}`;
    return title;
  }, [directDebitAccount]);

  const bookmarkList: IBookmarkItem[] = [
    {
      LinkText: `Direct Debit Account - ${id ?? 0}`,
      Text: `${getDisplayTextWithDashes([getFormTitle])}`,
      LinkUrl: window.location.pathname,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.DirectDebit,
      RecordType_ENUM: RECORDTYPE.Receipting_DirectDebit_Account,
      Record_ID: +id ?? 0,
    },
  ];
  return (
    <>
      <FormTitle
        title={getFormTitle}
        badge={directDebitAccount?.AccountStatus_Name}
      />
      <CCManagePageActionBar
        leftActions={[
          <SaveButtonDirectDebitAccount />,
          <SetStatusButtonDirectDebit
            status={DirectDebitAccountStatus.Active}
            title="Set active"
            disabled={!checkPermissions(FormAction.CORE_ALLOW_ACTIVATE)}
            isLoadingPermission={isLoadingPermission}
          />,
          <SetStatusButtonDirectDebit
            status={DirectDebitAccountStatus.Suspended}
            title="Suspended"
            disabled={!checkPermissions(FormAction.DIRECTDEBIT_FORM_SETSUSPEND)}
            isLoadingPermission={isLoadingPermission}
          />,
          <SetStatusButtonDirectDebit
            status={DirectDebitAccountStatus.Cancelled}
            title="Cancelled"
            disabled={!checkPermissions(FormAction.DIRECTDEBIT_FORM_SETCANCEL)}
            isLoadingPermission={isLoadingPermission}
          />,
          <SetStatusButtonDirectDebit
            status={DirectDebitAccountStatus.Terminated}
            title="Terminated"
            disabled={
              !checkPermissions(FormAction.DIRECTDEBIT_FORM_SETTERMINATE)
            }
            isLoadingPermission={isLoadingPermission}
          />,
        ]}
        rightActions={[
          <CCNavButton
            title={"Slide Bar"}
            iconClass="fal fa-info-circle"
            isActive={showSlideBar}
            onClick={() => {
              setShowSlideBar(!showSlideBar);
            }}
          />,
          <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
        ]}
      />
      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <YieldNotificationPortal placeId={TypePlaceId.Main} />
          <div className="cc-manage-form-body">
            {directDebitAccount && <DirectDebitAccountForm />}
            <TabTable
              id={parseInt(dataForm?.GeneralForm?.Account_ID)}
              recordType={RECORDTYPE.Receipting_DirectDebit_Account}
              initialPanels={listPanelBar}
            />
          </div>
        </div>

        {/* Handle later after Confirmation */}
        {showSlideBar && (
          <div className="cc-manage-right">
            <ReferenceSideBar
              components={[
                {
                  title: "Contact",
                  component: (
                    <DDContactsTab existedContact={initAccountContact} />
                  ),
                },
                {
                  title: "History",
                  component: <AccountHistoryTab />,
                },
              ]}
            />
          </div>
        )}
      </div>
    </>
  );
});

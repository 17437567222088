import { InspectionType } from "@app/core/inspections/[id]/model";
import { InspectionTypeFormElement } from "@common/pages/settings/lookups/inspection-types/_id/components/general/components/_index";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const InspectionTypeForm = observer(() => {
  //#region STORE
  const { dataForm, middlewareSubmitForm, setOnSubmitFlexible } =
    useFlexibleFormStore();
  //#endregion STORE

  //#region HOOK
  const { pushNotificationPortal } = useNotificationPortalStore();
  // #endregion

  //#region INITIAL_VALUES
  const inspectionType = dataForm?.GeneralForm as InspectionType;
  //#endregion INITIAL_VALUES

  const handleOnSubmit = (event: FormSubmitClickEvent) => {
    const { whenNotModified, whenNotValid } = middlewareSubmitForm(event);
    whenNotModified(() => {
      pushNotificationPortal({
        title: "The form is not modified.",
        type: "warning",
      });
    });

    whenNotValid(() => {
      pushNotificationPortal({
        title: "Please enter all required information.",
        type: "warning",
      });
    });
  };

  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleOnSubmit}
        initialValues={inspectionType}
        key={JSON.stringify(inspectionType)}
        render={(formRenderProps: FormRenderProps) => {
          setOnSubmitFlexible({
            formName: "GeneralForm",
            eventSubmit: formRenderProps.onSubmit,
          });
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <InspectionTypeFormElement formRenderProps={formRenderProps} />
              }
            />
          );
        }}
      />
    </div>
  );
});

import { eventEmitter } from "@/App";
import { ConfirmDelete } from "@app/core/delete/dialogs/_index";
import { DBRowState } from "@app/products/crms/[id]/model";
import {
  DirectDebitBankAccount,
  DirectDebitBankAccountView,
} from "@app/products/direct-debit/system-admin/model";
import { Svc_RecordDetails } from "@app/products/town-planning/ppr/permit-referrals/components/action-bar/buttons/delete/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import {
  fetchApiByAlias,
  useFlexibleFetchData,
} from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { usePageType } from "@common/hooks/usePageType";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { deleteBankAccount } from "./api";

export const DeleteDirectDebitBankAccountButton = observer(() => {
  //Stores
  const { gridSelectedRows, setGridSelectedRows, setGridSelectedIds } =
    useCCProductListViewStore();
  const { pushNotificationPortal } = useNotificationPortalStore();

  //Hooks
  const { dataForm } = useFlexibleFormStore();
  const { isProductListView } = usePageType();
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] =
    useState<boolean>(false);

  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const bankAccountFromStore = dataForm?.GeneralForm as DirectDebitBankAccount;

  const slider = useFlexibleFetchData({
    slides: [
      {
        fetch: ({ initialData }) => {
          const recordDetails: Svc_RecordDetails[] = initialData;
          return deleteBankAccount(recordDetails);
        },
        handleSuccess: async () => {
          if (isProductListView) {
            eventEmitter.emit(CCGridEventType.RefreshOData);
            setGridSelectedRows([]);
            setGridSelectedIds([]);
            pushNotificationPortal(
              {
                title: "Record(s) deleted successfully.",
                type: "success",
              },
              true
            );
          } else {
            setShowConfirmDeleteDialog(false);
            await fetchApiByAlias("manageDirectDebitBankAccountSlider");
            pushNotificationPortal({
              title: "Record(s) deleted successfully.",
              type: "success",
            });
          }
        },
        handleError: () => {
          pushNotificationPortal({
            title: "Delete record(s) failed.",
            type: "error",
            autoClose: false,
          });
        },
      },
    ],
  });

  const handleOnSubmit = async () => {
    setIsDeleting(true);
    let payload: Svc_RecordDetails[] = [];
    if (isProductListView) {
      const selectedRow: DirectDebitBankAccountView[] = gridSelectedRows.filter(
        (row) => row.Sys_DBRowState === DBRowState.Active
      );
      payload = selectedRow.map((row) => ({
        ID: row.Direct_Debit_Bank_Account_Id,
        RecordType_ENUM: RECORDTYPE.Receipting_Direct_Debit_Bank_Account,
      }));
    } else {
      payload = [
        {
          ID: bankAccountFromStore.Direct_Debit_Bank_Account_Id,
          RecordType_ENUM: RECORDTYPE.Receipting_Direct_Debit_Bank_Account,
        },
      ];
    }
    await slider.fetchApi({ initialData: payload });
    setShowConfirmDeleteDialog(false);
    setIsDeleting(false);
  };

  const isDisabled = useMemo(() => {
    if (isProductListView) {
      return (
        !gridSelectedRows?.length ||
        gridSelectedRows.every(
          (row) => row.Sys_DBRowState === DBRowState.Inactive
        )
      );
    } else {
      return bankAccountFromStore?.Sys_DBRowState === DBRowState.Inactive;
    }
  }, [
    gridSelectedRows,
    isProductListView,
    bankAccountFromStore?.Sys_DBRowState,
  ]);

  return (
    <>
      <CCNavButton
        title="Delete"
        disabled={isDisabled}
        isLoading={isDeleting}
        onClick={() => {
          setShowConfirmDeleteDialog(true);
        }}
      />
      {showConfirmDeleteDialog && (
        <ConfirmDelete
          onClose={() => setShowConfirmDeleteDialog(false)}
          handleSubmit={handleOnSubmit}
          isDeleting={isDeleting}
          header={"Confirm Deletion"}
        />
      )}
    </>
  );
});

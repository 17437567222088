import { Svc_Contact } from "@app/core/contacts/_id/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
class DirectDebitAccountStore {
  private _accountContact?: Svc_Contact = undefined;
  private _initAccountContact?: Svc_Contact = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get accountContact() {
    return toJS(this._accountContact);
  }
  setAccountContact = (accountContact: Svc_Contact | undefined) => {
    runInAction(() => {
      this._accountContact = accountContact;
    });
  };

  get initAccountContact() {
    return toJS(this._initAccountContact);
  }
  setInitAccountContact = (initAccountContact: Svc_Contact | undefined) => {
    runInAction(() => {
      this._initAccountContact = initAccountContact;
    });
  };
  //Action

  resetStore = () => {
    runInAction(() => {
      this._accountContact = undefined;
      this._initAccountContact = undefined;
    });
  };
}

const directDebitStoreContext = createContext(new DirectDebitAccountStore());
export const useDirectDebitStore = () => {
  return useContext(directDebitStoreContext);
};

import { AddressForParcel } from "@app/products/property/parcels/[id]/components/child-screens/general/components/form-element/components/address-for-parcel/_index";
import {
  DTO_ElectoralDivisions,
  DTO_FireServices,
  DTO_Parcel_Summary,
} from "@app/products/property/parcels/[id]/model";
import { useParcelsStore } from "@app/products/property/parcels/[id]/store";
import { isFieldVisible } from "@app/products/property/util";
import { OrganisationMode } from "@common/stores/products/model";
import { useCommonProductStore } from "@common/stores/products/store";
import { nameOfFactory } from "@common/utils/common";
import { CCInput } from "@components/cc-input/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Field, FormElement } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const PropertyParcelFormElement = observer(() => {
  const { parcel } = useParcelsStore();
  const nameOf = nameOfFactory<DTO_Parcel_Summary>();
  const nameOfElectoralDivisions = nameOfFactory<DTO_ElectoralDivisions>();
  const nameOfFireServices = nameOfFactory<DTO_FireServices>();
  const { currentOrganisationMode } = useCommonProductStore();
  const isLLS = currentOrganisationMode(OrganisationMode.LLS);
  return (
    <FormElement className="cc-property-parcel-form-elements">
      <section className="cc-field-group">
        <label className="cc-label">Classification</label>
        <div className="cc-custom-sub-panel-bar">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Parcel reference</label>
              <Field
                name={nameOf("Reference")}
                placeholder={"Parcel reference"}
                component={CCInput}
                readOnly
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Status</label>
              <Field
                name={nameOf("Status")}
                placeholder={"Status"}
                component={CCInput}
                readOnly
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Land area</label>
              <Field
                name={nameOf("LandArea")}
                placeholder={"Land area"}
                component={CCInput}
                readOnly
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Legal description</label>
              <Field
                name={nameOf("LegalDescription")}
                placeholder={"Legal description"}
                component={CCInput}
                readOnly
              />
            </div>
            {isFieldVisible(parcel?.EstateVisibility) && (
              <div className="cc-field">
                <label className="cc-label">Estate</label>
                <Field
                  name={nameOf("Estate")}
                  placeholder={"Estate"}
                  component={CCInput}
                  readOnly
                />
              </div>
            )}
            {/* Hide for LLS, Visible for General and Actro - Task: 14678 */}
            {isLLS ? null : (
              <div className="cc-field">
                <label className="cc-label">Stage</label>
                <Field
                  name={nameOf("Stage")}
                  placeholder={"Stage"}
                  component={CCInput}
                  readOnly
                />
              </div>
            )}
          </div>
          <br />
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Owners</label>
              <Field
                name={nameOf("Owners_Name")}
                placeholder={"Owners"}
                component={CCTextArea}
                rows={5}
                readOnly
              />
            </div>
            {isFieldVisible(parcel?.LandCategoryVisibility) && (
              <div className="cc-field">
                <label className="cc-label">Land category</label>
                <Field
                  name={nameOf("LandCategory")}
                  placeholder={"Land category"}
                  component={CCInput}
                  readOnly
                />
              </div>
            )}
            {isFieldVisible(parcel?.CensusDistrictsVisibility) ||
            isFieldVisible(parcel?.SwimmingPoolFlagVisibility) ? (
              <div className="cc-field">
                <div className="cc-form-cols-1">
                  {isFieldVisible(parcel?.CensusDistrictsVisibility) && (
                    <div className="cc-field">
                      <label className="cc-label">Census district</label>
                      <Field
                        name={nameOf("CensusDistrict")}
                        placeholder={"Census district"}
                        component={CCInput}
                        readOnly
                      />
                    </div>
                  )}
                  {isFieldVisible(parcel?.SwimmingPoolFlagVisibility) && (
                    <div className="cc-field">
                      <label className="cc-label">Has a swimming pool</label>
                      <Field
                        name={nameOf("HasSwimmingPool")}
                        component={CCSwitch}
                        checked={parcel?.HasSwimmingPool}
                        disabled
                      />
                    </div>
                  )}
                </div>
              </div>
            ) : null}
          </div>
          <br />

          {isFieldVisible(parcel?.ElectoralDivisions?.GroupVisibility) && (
            <>
              <section className="cc-field-group">
                <label className="cc-label">Electoral division</label>
                <div className="cc-custom-sub-panel-bar">
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Local</label>
                      <Field
                        name={nameOfElectoralDivisions("Local")}
                        placeholder={"Local"}
                        component={CCInput}
                        value={parcel?.ElectoralDivisions?.Local}
                        readOnly
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">State code</label>
                      <Field
                        name={nameOfElectoralDivisions("State")}
                        placeholder={"State code"}
                        component={CCInput}
                        value={parcel?.ElectoralDivisions?.State}
                        readOnly
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Federal</label>
                      <Field
                        name={nameOfElectoralDivisions("Federal")}
                        placeholder={"Federal"}
                        component={CCInput}
                        value={parcel?.ElectoralDivisions?.Federal}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </section>
              <br />
            </>
          )}

          {isFieldVisible(parcel?.FireServices?.GroupVisibility) && (
            <section className="cc-field-group">
              <label className="cc-label">Fire services</label>
              <div className="cc-custom-sub-panel-bar">
                <div className="cc-form-cols-3">
                  {isFieldVisible(
                    parcel?.FireServices?.PropertyCategoriesVisibility
                  ) && (
                    <div className="cc-field">
                      <label className="cc-label">Property category</label>
                      <Field
                        name={nameOfFireServices("PropertyCategory")}
                        placeholder={"Property category"}
                        component={CCInput}
                        value={parcel?.FireServices?.PropertyCategory}
                        readOnly
                      />
                    </div>
                  )}
                  {isFieldVisible(
                    parcel?.FireServices?.ClassificationsVisibility
                  ) && (
                    <div className="cc-field">
                      <label className="cc-label">Classification</label>
                      <Field
                        name={nameOfFireServices("Classification")}
                        placeholder={"Classification"}
                        component={CCInput}
                        value={parcel?.FireServices?.Classification}
                        readOnly
                      />
                    </div>
                  )}
                  {isFieldVisible(
                    parcel?.FireServices?.DistrictsVisibility
                  ) && (
                    <div className="cc-field">
                      <label className="cc-label">District</label>
                      <Field
                        name={nameOfFireServices("District")}
                        placeholder={"District"}
                        component={CCInput}
                        value={parcel?.FireServices?.District}
                        readOnly
                      />
                    </div>
                  )}
                </div>
              </div>
            </section>
          )}
        </div>
      </section>
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <AddressForParcel data={parcel?.Addresses ?? []} />
        </div>
      </section>
      <br />
    </FormElement>
  );
});

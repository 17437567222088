import { VO_Charge_Run_Levy_Calculation } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/levies-raised/model";
import {
  CURRENCY_FORMAT,
  DECIMAL_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import {
  formatStyleProminent,
  formatStyleUnRelevant,
} from "@common/utils/formatting";
import { totalColumnField } from "@common/utils/grid";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { GridFooterCellProps } from "@progress/kendo-react-grid";
import React from "react";

const nameOf = nameOfFactory<VO_Charge_Run_Levy_Calculation>();
export const colChargeRunLeviesRaised: IColumnFields[] = [
  {
    title: "Assessment Number",
    field: nameOf("Assess_NumberX"),
  },
  {
    title: "Associated Assessment Number",
    field: nameOf("Associated_Assessment_Number"),
  },
  {
    title: "Financial Area",
    field: nameOf("FA_Name"),
  },
  {
    title: "Levy",
    field: nameOf("Levy_Name"),
  },
  {
    title: "Rate",
    field: nameOf("LC_Rate"),
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    title: "LevyAmount",
    field: nameOf("AL_Levy_Quantity_Amount"),
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    title: "Quantity",
    field: nameOf("LC_Quantity"),
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    title: "Valuation",
    field: nameOf("LC_Valuation"),
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (_props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOf("LC_Valuation"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    title: "Base Amount",
    field: nameOf("LC_Adjusted_Base_Amount"),
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (_props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOf("LC_Adjusted_Base_Amount"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    title: "Rate By Valuation Amount",
    field: nameOf("LC_Adjusted_RateByValue_Amount"),
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (_props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOf("LC_Adjusted_RateByValue_Amount"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    title: "Gross Levy Amount",
    field: nameOf("LC_X_Gross_Amount"),
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (_props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOf("LC_X_Gross_Amount"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    title: "NettLevy Amount",
    field: nameOf("LC_X_Nett_Amount"),
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (_props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOf("LC_X_Nett_Amount"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    title: "Attributable Value",
    field: nameOf("LC_Attributable_Value"),
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (_props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOf("LC_Attributable_Value"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    title: "Assessment ID",
    field: nameOf("Assessment_Id"),
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    title: "Assessment Reference",
    field: nameOf("Assess_Reference"),
  },
  {
    title: "Property Name Address Locality",
    field: nameOf("Property_Name_Address_Locality"),
  },
  {
    title: "Ratepayer",
    field: nameOf("Ratepayer_Name"),
  },
  {
    title: "Charge Run ID",
    field: nameOf("Charge_Run_Id"),
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    title: "Increments to Minimum",
    field: nameOf("LC_Increment_to_Minimum"),
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    title: "Reduction to Maximum",
    field: nameOf("LC_Reduction_to_Maximum"),
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    title: "Capping Adjustments",
    field: nameOf("LC_Capping_Adjustment"),
    format: NUMBER_FORMAT.NUMBER1,
  },
  {
    title: "Tax Amount",
    field: nameOf("LC_Tax_Amount"),
    format: CURRENCY_FORMAT.CURRENCY1,
  },

  {
    title: "Rebates",
    field: nameOf("LC_Rebate_Amount"),
    format: CURRENCY_FORMAT.CURRENCY1,
  },

  {
    title: "Discount Amount 1",
    field: nameOf("LC_Discount_Amount_1"),
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    title: "Discount Amount 2",
    field: nameOf("LC_Discount_Amount_2"),
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    title: "Tax Included",
    field: nameOf("LC_Tax_Included"),
    format: NUMBER_FORMAT.NUMBER1,
  },

  {
    title: "Postponed Amount",
    field: nameOf("LC_Postponed_Amount"),
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    title: "Levy ID",
    field: nameOf("Levy_Id"),
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    title: "Locality ID",
    field: nameOf("Locality_Id"),
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    title: "Assess Legal Description",
    field: nameOf("Assess_Legal_Description"),
  },
  {
    title: "Financial Area ID",
    field: nameOf("Financial_Area_Id"),
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    title: "Associated Assessment ID",
    field: nameOf("Associated_Assessment_Id"),
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

import { postAddressValidationCandidate } from "@app/products/property/assessments/components/form-steps/modify-assessment/dialog/new-address/api";
import {
  IEditAddAddressLOVs,
  INewAddressInitValues,
} from "@app/products/property/assessments/components/form-steps/modify-assessment/dialog/new-address/models";
import {
  processPropertyAddress,
  validateStreetAndLocality,
} from "@app/products/property/assessments/components/form-steps/modify-assessment/dialog/new-address/util";
import { AddressValidationSearch } from "@app/products/property/assessments/components/form-steps/modify-assessment/dialog/new-address/validation-adress-search/_index";
import { EAddressValidation } from "@app/products/property/assessments/components/form-steps/modify-assessment/dialog/new-address/validation-adress-search/model";
import { DTO_Address } from "@app/products/property/assessments/components/form-steps/modify-assessment/model";
import { getInitStreetLocality } from "@app/products/property/assessments/components/form-steps/new-assessment/components/form-elements/physical-address/api";
import { StreetSearch } from "@app/products/property/assessments/components/form-steps/new-assessment/components/street-search/_index";
import { VO_Street_Locality } from "@app/products/property/assessments/components/form-steps/new-assessment/components/street-search/model";
import { nameOfLov } from "@app/products/property/model";
import { convertValueLOVToNumber } from "@app/products/property/util";
import { isSuccessResponse } from "@common/apis/util";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { commonCoreStore } from "@common/stores/core/store";
import { getNumberValueSetting } from "@common/stores/products/util";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { isEmpty, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

interface INewAddressDialogProps {
  onClose: () => void;
  isLoadingFinish?: boolean;
  dataItem?: any;
  handleAddAddress?: (data: any) => void;
  handleUpdateAddress?: (data: any) => void;
  subId?: number;
  isNewAssessment?: boolean;
  initialValue?: INewAddressInitValues;
  assessmentLOVs: IEditAddAddressLOVs | undefined;
}

const nameOf = nameOfFactory<DTO_Address>();
export const NewAddressDialog = observer(
  ({
    onClose,
    isLoadingFinish = false,
    dataItem,
    handleAddAddress,
    handleUpdateAddress,
    subId,
    isNewAssessment = false,
    initialValue,
    assessmentLOVs,
  }: INewAddressDialogProps) => {
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    const onChangeRef = useRef<any>();
    const valueGetterRef = useRef<any>();

    const settings = commonCoreStore.settings;
    const isShowSearchAddressValidation = useMemo(() => {
      return (
        getNumberValueSetting(
          settings[ECorporateSettingsField.CorporateSettings_AddressValidation]
        ) !== EAddressValidation.None
      );
    }, [settings]);

    const addressTypeLOVs = convertValueLOVToNumber(
      assessmentLOVs?.Address_Type ?? [],
      "Code"
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const getStreetFromDefault = async (defaultStreetId: number) => {
      setIsLoading(true);
      getInitStreetLocality(defaultStreetId).then((response) => {
        if (isSuccessResponse(response)) {
          onChangeRef.current("StreetAndLocality", {
            value: response.data?.value?.[0] || "",
          });
        } else {
          notificationRef?.current?.pushNotification({
            type: "error",
            title: "Load street and locality failed",
            autoClose: false,
          });
        }
        setIsLoading(false);
      });
    };

    const handleMapAddressValidation = async (event: ComboBoxChangeEvent) => {
      if (isNil(event?.value)) return;
      setIsDisabled(true);
      const response = await postAddressValidationCandidate(event?.value?.id);
      if (isSuccessResponse(response)) {
        mappingAddressData(response?.data);
      } else {
        notificationRef?.current?.pushNotification({
          type: "error",
          title: "Get address validation candidate failed",
          autoClose: false,
        });
      }
      setIsDisabled(false);
    };

    const mappingAddressData = (address: DTO_Address | undefined) => {
      if (isNil(address)) return;
      const buildingUnitNumber =
        getDropdownValue(
          address?.BuildingUnitNumber,
          assessmentLOVs?.Address_UnitType ?? [],
          "Name"
        )?.Code ?? null;
      const floorType =
        getDropdownValue(
          address?.FloorType,
          assessmentLOVs?.Address_FloorType ?? [],
          "Name"
        )?.Code ?? null;

      const dataMapping: any = {
        AddressTypeId: address?.AddressTypeId ?? null,
        IsAddressPrimary: address?.IsAddressPrimary || false,
        PropertyName: address?.PropertyName || "",
        BuildingUnitNumber: buildingUnitNumber,
        UnitNumberFrom: address?.UnitNumberFrom || "",
        UnitNumberTo: address?.UnitNumberTo || "",
        FloorType: floorType,
        FloorNumberTo: address?.FloorNumberTo || "",
        FloorNumberFrom: address?.FloorNumberFrom || "",
        BuildingName: address?.BuildingName || "",
        IsRuralHouseNumber: address?.IsRuralHouseNumber || false,
        IsOutsideProperty: address?.IsOutsideProperty || false,
        HouseFrom: address?.HouseFrom || "",
        HouseTo: address?.HouseTo || "",
        State: address?.State || null,
        Country: address?.Country || null,
        PostCode: address?.PostCode || null,
        LocationDescriptor: address?.LocationDescriptor || "",
        SideOfStreet: address?.SideOfStreet || null,
        Locality: address?.Locality || "",
        StreetAndLocalityName: address?.StreetAndLocalityName || "",
        StreetName: address?.StreetName || "",
        StreetType: address?.StreetType || "",
        AddressPFI: address?.AddressPFI || 0,
        LocalityPFI: address?.LocalityPFI || "",
        StreetLocalityPFI: address?.StreetLocalityPFI || "",
        IsAddressValid: address?.IsAddressValid || false,
        PropertyAddress: address?.PropertyAddress || "",
      };

      for (const key in dataMapping) {
        onChangeRef.current(key, {
          value: dataMapping[key],
        });
      }
    };

    useEffectOnce(() => {
      if (!isEmpty(dataItem) && dataItem?.StreetAndLocalityId) {
        getStreetFromDefault(dataItem?.StreetAndLocalityId);
      } else {
        onChangeRef.current("StreetAndLocality.Street_Name_Locality", {
          value: dataItem?.StreetAndLocalityName || "",
        });
      }
    });
    return (
      <Form
        initialValues={{
          IsAddressPrimary: false,
          ...dataItem,
          ...initialValue,
        }}
        onSubmitClick={({ values, ...rest }: any) => {
          const newValues = {
            ...values,
            PropertyAddress: rest?.event?.PropertyAddress,
          };
          if (isEmpty(dataItem)) {
            return handleAddAddress?.(newValues);
          }
          return handleUpdateAddress?.(newValues);
        }}
        render={(formRenderProps: FormRenderProps) => {
          const { valueGetter, onChange } = formRenderProps;
          onChangeRef.current = onChange;
          valueGetterRef.current = valueGetter;
          const getFieldValue = (name: keyof DTO_Address) =>
            valueGetter(nameOf(name));
          const propertyAddress = processPropertyAddress(
            valueGetter,
            assessmentLOVs
          );

          const handleChangeStreetAndLocalityName = (
            event: ComboBoxChangeEvent
          ) => {
            const address: VO_Street_Locality = event?.target?.value;
            onChange("StreetAndLocality", {
              value: address,
            });
            onChange("StreetAndLocalityName", {
              value: address?.Street_Name_Locality,
            });
            onChange("Full_Street_Name", {
              value: address?.Full_Street_Name,
            });
            onChange(nameOf("StreetAndLocalityId"), {
              value: address?.Street_Locality_Id,
            });
            onChange(nameOf("Locality"), {
              value: address?.Locality_Name,
            });
            onChange(nameOf("PostCode"), {
              value: address?.Locality_Postcode,
            });
          };

          return (
            <CCDialog
              titleHeader={isEmpty(dataItem) ? "New Address" : "Edit Address"}
              maxWidth="50%"
              height="auto"
              onClose={onClose}
              isLoading={isLoading}
              disabled={isDisabled}
              bodyElement={
                <FormElement className="cc-form cc-address-dialog">
                  <section className="cc-field-group">
                    <CCLocalNotification ref={notificationRef} />
                    <div className="cc-form-cols-1">
                      {isShowSearchAddressValidation && (
                        <div className="cc-field">
                          <CCLabel title="Search" />
                          <Field
                            name={"StreetValidation"}
                            component={AddressValidationSearch}
                            onError={(error: any) => {
                              notificationRef.current?.pushNotification({
                                type: "error",
                                title: "Pick address validator errors",
                                description: error,
                                autoClose: false,
                              });
                            }}
                            isLoadingParent={isDisabled}
                            placeholder={"Search"}
                            onChange={async (event: ComboBoxChangeEvent) => {
                              await handleMapAddressValidation(event);
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="cc-form-cols-3">
                      <div className="cc-field">
                        <CCLabel title="Address type" isMandatory />
                        <Field
                          name={nameOf("AddressTypeId")}
                          component={CCSearchComboBox}
                          textField={nameOfLov("Name")}
                          dataItemKey={nameOfLov("Code")}
                          data={addressTypeLOVs}
                          validator={requiredValidator}
                          value={getDropdownValue(
                            getFieldValue("AddressTypeId"),
                            addressTypeLOVs,
                            nameOfLov("Code")
                          )}
                          onChange={(event: ComboBoxChangeEvent) => {
                            onChange(nameOf("AddressTypeId"), {
                              value: event.value?.Code ?? null,
                            });
                            onChange(nameOf("AddressTypeName"), {
                              value: event.value?.Name ?? null,
                            });
                          }}
                        />
                      </div>
                      <div className="cc-field">
                        <CCLabel title="Is the primary address" />
                        <Field
                          name={nameOf("IsAddressPrimary")}
                          component={CCSwitch}
                          checked={getFieldValue("IsAddressPrimary")}
                        />
                      </div>
                      {/*Requirement : just display  */}
                      <div className="cc-field">
                        <CCValueField label="Address ID" value="" />
                      </div>
                    </div>
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">Address</label>
                        <div className="cc-custom-sub-panel-bar cc-form-cols-1">
                          <div className="cc-field">
                            <CCLabel title="Property name" />
                            <Field
                              name={nameOf("PropertyName")}
                              component={CCInput}
                            />
                          </div>
                          <div className="cc-form-cols-2">
                            <div className="cc-field">
                              <label className="cc-label">
                                Building unit number(s)
                              </label>
                              <div className="cc-custom-sub-panel-bar cc-form-cols-3">
                                <div className="cc-field">
                                  <label className="cc-label">&nbsp;</label>
                                  <Field
                                    id="cc-new-address-BuildingUnitNumber"
                                    name={nameOf("BuildingUnitNumber")}
                                    textField={nameOfLov("Name")}
                                    dataItemKey={nameOfLov("Code")}
                                    isUseDefaultOnchange
                                    component={CCSearchComboBox}
                                    data={
                                      assessmentLOVs?.Address_UnitType ?? []
                                    }
                                  />
                                </div>
                                <div className="cc-field">
                                  <label className="cc-label">&nbsp;</label>
                                  <Field
                                    id="cc-new-address-UnitNumberFrom"
                                    name={nameOf("UnitNumberFrom")}
                                    component={CCInput}
                                  />
                                </div>
                                <div className="cc-field">
                                  <label className="cc-label">to</label>
                                  <Field
                                    id="cc-new-address-UnitNumberTo"
                                    name={nameOf("UnitNumberTo")}
                                    component={CCInput}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="cc-field">
                              <label className="cc-label">
                                Floor/Level number(s)
                              </label>
                              <div className="cc-custom-sub-panel-bar cc-form-cols-3">
                                <div className="cc-field">
                                  <label className="cc-label">&nbsp;</label>
                                  <Field
                                    id="cc-new-address-FloorType"
                                    name={nameOf("FloorType")}
                                    textField={nameOfLov("Name")}
                                    dataItemKey={nameOfLov("Code")}
                                    isUseDefaultOnchange
                                    component={CCSearchComboBox}
                                    data={
                                      assessmentLOVs?.Address_FloorType ?? []
                                    }
                                  />
                                </div>
                                <div className="cc-field">
                                  <label className="cc-label">&nbsp;</label>
                                  <Field
                                    id="cc-new-address-FloorNumberFrom"
                                    name={nameOf("FloorNumberFrom")}
                                    component={CCInput}
                                  />
                                </div>
                                <div className="cc-field">
                                  <label className="cc-label">to</label>
                                  <Field
                                    id="cc-new-address-FloorNumberTo"
                                    name={nameOf("FloorNumberTo")}
                                    component={CCInput}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">Building name</label>
                            <Field
                              name={nameOf("BuildingName")}
                              component={CCInput}
                            />
                          </div>
                          <label className="cc-label">
                            House number(s), street and locality
                          </label>
                          <div className="cc-custom-sub-panel-bar cc-form-cols-1">
                            <div className="cc-field">
                              <Field
                                name={nameOf("IsRuralHouseNumber")}
                                component={Checkbox}
                                label={"Rural numbering"}
                                onChange={(event: CheckboxChangeEvent) => {
                                  onChange(nameOf("IsRuralHouseNumber"), {
                                    value: event.value,
                                  });
                                  if (!event.value) {
                                    onChange(nameOf("IsOutsideProperty"), {
                                      value: event.value,
                                    });
                                  }
                                  onChange(nameOf("HouseFrom"), {
                                    value: "",
                                  });
                                  onChange(nameOf("HouseTo"), {
                                    value: "",
                                  });
                                }}
                              />
                            </div>
                            {!!getFieldValue("IsRuralHouseNumber") && (
                              <div className="cc-form-cols-3">
                                <div className="cc-field">
                                  <Field
                                    name={nameOf("IsOutsideProperty")}
                                    component={Checkbox}
                                    label={
                                      "Rural number is outside the property"
                                    }
                                  />
                                </div>
                                {getFieldValue("IsOutsideProperty") ||
                                getFieldValue("IsRuralHouseNumber") ? (
                                  <div className="cc-field cc-col-span-2">
                                    <Field
                                      name="Input"
                                      component={CCInput}
                                      value={
                                        getFieldValue("HouseFrom")
                                          ? `${
                                              getFieldValue("HouseFrom") / 100
                                            } kms from Start of Road ${
                                              getFieldValue("IsOutsideProperty")
                                                ? `(Outside the Property)`
                                                : ""
                                            }`
                                          : ""
                                      }
                                      readOnly
                                    />
                                  </div>
                                ) : null}
                              </div>
                            )}
                            <div
                              className={
                                getFieldValue("IsRuralHouseNumber")
                                  ? "cc-form-cols-2"
                                  : "cc-form-cols-3"
                              }
                            >
                              {getFieldValue("IsRuralHouseNumber") ? (
                                <div className="cc-field">
                                  <CCLabel title="House number from" />
                                  <Field
                                    id="cc-new-address-HouseFrom"
                                    name={nameOf("HouseFrom")}
                                    component={CCNumericTextBox}
                                    min={0}
                                    format={NUMBER_FORMAT.NUMBER2}
                                  />
                                </div>
                              ) : (
                                <div className="cc-field">
                                  <CCLabel title="House number from" />
                                  <Field
                                    id="cc-new-address-HouseFrom"
                                    name={nameOf("HouseFrom")}
                                    component={CCInput}
                                  />
                                </div>
                              )}
                              {!getFieldValue("IsRuralHouseNumber") && (
                                <div className="cc-field">
                                  <CCLabel title="House number to" />
                                  <Field
                                    id="cc-new-address-HouseTo"
                                    name={nameOf("HouseTo")}
                                    component={CCInput}
                                    min={getFieldValue("HouseFrom")}
                                    format={NUMBER_FORMAT.NUMBER2}
                                  />
                                </div>
                              )}
                              <div className="cc-field">
                                <CCLabel
                                  title="Street and locality"
                                  isMandatory
                                />
                                {isShowSearchAddressValidation ? (
                                  <Field
                                    name={"StreetAndLocalityName"}
                                    component={CCInput}
                                    placeholder={"Street and locality"}
                                    validator={requiredValidator}
                                    isUseDefaultOnchange
                                  />
                                ) : (
                                  <Field
                                    name={"StreetAndLocality"}
                                    component={StreetSearch}
                                    onError={(error: any) => {
                                      notificationRef.current?.pushNotification(
                                        {
                                          type: "error",
                                          title: "Pick street errors",
                                          description: error,
                                          autoClose: false,
                                        }
                                      );
                                    }}
                                    textField="Street_Name_Locality"
                                    suburbId={subId}
                                    placeholder={"Search (street and locality)"}
                                    onChange={(event: ComboBoxChangeEvent) =>
                                      handleChangeStreetAndLocalityName(event)
                                    }
                                    validator={validateStreetAndLocality}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="cc-form-cols-2">
                            <div className="cc-field">
                              <label className="cc-label">State</label>
                              <Field
                                name={nameOf("State")}
                                textField="Name"
                                dataItemKey="Code"
                                data={assessmentLOVs?.Address_State ?? []}
                                component={CCSearchComboBox}
                                value={getDropdownValue(
                                  getFieldValue("State"),
                                  assessmentLOVs?.Address_State ?? [],
                                  "Code"
                                )}
                                onChange={(event: ComboBoxChangeEvent) => {
                                  onChange(nameOf("State"), {
                                    value: event?.value?.Code ?? null,
                                  });
                                }}
                              />
                            </div>
                            <div className="cc-field">
                              <label className="cc-label">Postcode</label>
                              <Field
                                name={nameOf("PostCode")}
                                component={CCInput}
                                isUseDefaultOnchange
                                maxLength={4}
                              />
                            </div>
                            <div className="cc-field">
                              <label className="cc-label">Country</label>
                              <Field
                                name={nameOf("Country")}
                                textField="Name"
                                dataItemKey="Code"
                                data={assessmentLOVs?.Country ?? []}
                                component={CCSearchComboBox}
                                value={getDropdownValue(
                                  getFieldValue("Country"),
                                  assessmentLOVs?.Country ?? [],
                                  "Name"
                                )}
                                onChange={(event: ComboBoxChangeEvent) => {
                                  onChange(nameOf("Country"), {
                                    value: event.value?.Name ?? null,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cc-form-cols-3">
                      <div className="cc-field cc-col-span-2">
                        <label className="cc-label">Location descriptor</label>
                        <Field
                          name={nameOf("LocationDescriptor")}
                          component={CCInput}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Side of street</label>
                        <Field
                          name={nameOf("SideOfStreet")}
                          textField={nameOfLov("Name")}
                          dataItemKey={nameOfLov("Code")}
                          isUseDefaultOnchange
                          component={CCSearchComboBox}
                          data={assessmentLOVs?.Address_Side_of_Street ?? []}
                        />
                      </div>
                    </div>
                    <div className="cc-form-cols-1">
                      {isNewAssessment && (
                        <>
                          <div className="cc-field">
                            <label className="cc-label">Map number</label>
                            <Field
                              name={nameOf("MapNumber")}
                              component={CCInput}
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">Map reference</label>
                            <Field
                              name={nameOf("MapReference")}
                              component={CCInput}
                            />
                          </div>
                        </>
                      )}
                      <div className="cc-field">
                        <label className="cc-label">Postal delivery ID</label>
                        <Field
                          name={nameOf("PostalDeliveryId")}
                          component={CCInput}
                          readOnly
                        />
                      </div>
                    </div>
                    <br />
                  </section>
                </FormElement>
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button
                    className="cc-dialog-button"
                    iconClass={isLoadingFinish ? "fas fa-spinner fa-spin" : ""}
                    themeColor="primary"
                    onClick={(e: any) =>
                      formRenderProps.onSubmit({
                        ...e,
                        PropertyAddress: propertyAddress,
                      })
                    }
                    type="submit"
                    disabled={!formRenderProps.valid}
                  >
                    OK
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);

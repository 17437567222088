import { InspectionType } from "@app/core/inspections/[id]/model";
import { InspectionTypeForm } from "@common/pages/settings/lookups/inspection-types/_id/components/general/_index";
import { TitleInspectionTypeManagePage } from "@common/pages/settings/lookups/inspection-types/_id/constant";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { YieldNotificationPortal } from "@components/cc-notification-portal/_index";
import { TypePlaceId } from "@components/cc-notification-portal/constant";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffectOnce } from "react-use";

export const NewManageInspectionTypeComponent = observer(() => {
  const { listenNotificationPortal } = useNotificationPortalStore();
  const { dataForm } = useFlexibleFormStore();
  const inspectionType = dataForm?.GeneralForm as InspectionType;

  useEffectOnce(() => {
    listenNotificationPortal();
  });

  return (
    <>
      <FormTitle title={`New ${TitleInspectionTypeManagePage}`} />
      <CCManagePageActionBar
        leftActions={[<CCNavButton title="Save" onClick={() => {}} />]}
      />
      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <YieldNotificationPortal placeId={TypePlaceId.Main} />
          <div className="cc-manage-form-body">
            {inspectionType && <InspectionTypeForm />}
          </div>
        </div>
      </div>
    </>
  );
});

import { InspectionTypeFee } from "@app/core/inspections/[id]/model";
import { getFeeTypesById } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/components/dialogs/add-fee-types/api";
import { isSuccessResponse } from "@common/apis/util";
import { CURRENCY_FORMAT } from "@common/constants/common-format";
import {
  InspectionTypeLovs,
  nameOfIKeyValuePacket,
} from "@common/pages/settings/lookups/inspection-types/_id/model";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";

export interface IFormElementFieldProps {
  formRenderProps: FormRenderProps;
  isLoading?: boolean;
  setIsLoading: (value: boolean) => void;
}

const nameOfInspectionTypeFees = nameOfFactory<InspectionTypeFee>();

export const FormElementField = observer(
  ({
    formRenderProps,
    isLoading = false,
    setIsLoading,
  }: IFormElementFieldProps) => {
    const { dataForm } = useFlexibleFormStore();
    const { pushNotification } = useCCAppNotificationStore();
    const { valueGetter, onChange } = formRenderProps;

    // #region  Get Lovs
    const permitTypeLOVs = dataForm?.GeneralFormLovs as InspectionTypeLovs;
    // #endregion Get Lovs

    const inheritFeeValue =
      valueGetter(nameOfInspectionTypeFees("InheritInspectionTypeFeeType")) ??
      false;

    // #region Function
    const handleChangeAdditionalFees = async (event: ComboBoxChangeEvent) => {
      if (event?.value === null) {
        onChange(nameOfInspectionTypeFees("InheritInspectionTypeFeeType"), {
          value: false,
        });
        onChange(nameOfInspectionTypeFees("InspectionTypeFee_Amount"), {
          value: null,
        });
        onChange(nameOfInspectionTypeFees("InspectionTypeFeeType_Name"), {
          value: "",
        });
      }
      const selectedValue = event.value?.[nameOfIKeyValuePacket("Key")] ?? null;
      onChange(nameOfInspectionTypeFees("InspectionTypeFeeType_ID"), {
        value: selectedValue,
      });
      onChange(nameOfInspectionTypeFees("InspectionTypeFeeType_Name"), {
        value: event.value?.[nameOfIKeyValuePacket("Value")],
      });

      if (
        valueGetter(nameOfInspectionTypeFees("InheritInspectionTypeFeeType"))
      ) {
        const feeType = await getFeeTypesData(selectedValue);
        onChange(nameOfInspectionTypeFees("InspectionTypeFee_Amount"), {
          value: feeType?.DefaultAmount,
        });
      }
    };

    const handleChangeAdditionalFeesInherit = async (
      event: SwitchChangeEvent
    ) => {
      onChange(nameOfInspectionTypeFees("InheritInspectionTypeFeeType"), {
        value: event?.target?.value ?? false,
      });
      const feeTypeId = valueGetter(
        nameOfInspectionTypeFees("InspectionTypeFeeType_ID")
      );

      if (event?.target?.value && feeTypeId) {
        const feeType = await getFeeTypesData(feeTypeId);
        onChange(nameOfInspectionTypeFees("InspectionTypeFee_Amount"), {
          value: feeType?.DefaultAmount,
        });
      }
    };

    const getFeeTypesData = async (feeTypeId: number) => {
      setIsLoading(true);
      const response = await getFeeTypesById(feeTypeId);
      setIsLoading(false);
      if (isSuccessResponse(response) && response?.data) {
        return response.data;
      } else {
        pushNotification({
          autoClose: false,
          title: "Get fee type failed",
          description: response?.error,
          type: "error",
        });
      }
      return null;
    };

    // #endregion Function

    return (
      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title="Additional fees" />
            <Field
              name={nameOfInspectionTypeFees("InspectionTypeFeeType_ID")}
              component={CCSearchComboBox}
              data={permitTypeLOVs?.FeeType ?? []}
              dataItemKey={nameOfIKeyValuePacket("Key")}
              textField={nameOfIKeyValuePacket("Value")}
              value={getDropdownValue(
                valueGetter(
                  nameOfInspectionTypeFees("InspectionTypeFeeType_ID")
                ),
                permitTypeLOVs?.FeeType ?? [],
                nameOfIKeyValuePacket("Key")
              )}
              onChange={handleChangeAdditionalFees}
            />
          </div>
          {!isNil(
            valueGetter(nameOfInspectionTypeFees("InspectionTypeFeeType_ID"))
          ) ? (
            <>
              <div className="cc-field">
                <CCLabel title="Inherit fee" isLoading={isLoading} />
                <Field
                  name={
                    nameOfInspectionTypeFees("InheritInspectionTypeFeeType") ??
                    false
                  }
                  component={CCSwitch}
                  checked={valueGetter(
                    nameOfInspectionTypeFees("InheritInspectionTypeFeeType")
                  )}
                  onChange={handleChangeAdditionalFeesInherit}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Amount" isMandatory />
                <Field
                  name={nameOfInspectionTypeFees("InspectionTypeFee_Amount")}
                  component={CCNumericTextBox}
                  disabled={inheritFeeValue}
                  format={CURRENCY_FORMAT.CURRENCY2}
                  validator={!inheritFeeValue ? requiredValidator : undefined}
                />
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  }
);

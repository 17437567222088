import { ddAccountsRoute } from "@app/products/direct-debit/accounts/route";
import { ddAuthorisationsRoute } from "@app/products/direct-debit/authorisations/route";
import { ddSessionsRoute } from "@app/products/direct-debit/sessions/route";
import { ddSystemAdminRoute } from "@app/products/direct-debit/system-admin/route";
import { ICCRoute } from "@common/constants/ICCRoute";
import { eProductType } from "@common/stores/products/enum";

export const directDebitRoute: ICCRoute = {
  path: "direct-debit",
  name: "Direct Debit",
  enum: eProductType.DirectDebit,
  children: [
    ddAccountsRoute,
    ddAuthorisationsRoute,
    ddSessionsRoute,
    ddSystemAdminRoute,
  ],
};

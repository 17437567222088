import { DBRowState } from "@app/products/crms/[id]/model";
import {
  DIRECT_DEBIT_GROUPS,
  DIRECT_DEBIT_GROUPS_ODATA,
} from "@app/products/direct-debit/constant";
import { directDebitRoute } from "@app/products/direct-debit/route";
import { DirectDebitGroupDeleteButton } from "@app/products/direct-debit/system-admin/groups/[id]/components/buttons/delete/_index";
import { SysAdminGroupHistoryTab } from "@app/products/direct-debit/system-admin/groups/[id]/components/slidebar/history/_index";
import { colDirectDebitGroups } from "@app/products/direct-debit/system-admin/groups/config";
import { DirectDebitGroup } from "@app/products/direct-debit/system-admin/model";
import { DDSystemAdminBookmark } from "@app/products/direct-debit/system-admin/util";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { CCActionBarNavDropdownNew } from "@components/cc-list-view-action-bar/components/nav-dropdown-new/_index";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router-dom";

const nameOf = nameOfFactory<DirectDebitGroup>();
export default observer(() => {
  const history = useHistory();

  useCCListViewActionBar({
    title: directDebitRoute.name,
    leftComponents: [<CCActionBarNavDropdownNew />],
    centerComponents: [
      <CCNavButton
        title="New"
        onClick={() => {
          history.push(`${DIRECT_DEBIT_GROUPS}/new`);
        }}
      />,
      <DirectDebitGroupDeleteButton />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={DIRECT_DEBIT_GROUPS}
        productType={PRODUCT_TYPE.DirectDebit}
        recordType={RECORDTYPE.Receipting_DirectDebitGroup}
        detail={DDSystemAdminBookmark.getBookmarkListViewDisplayName}
        displayName={DDSystemAdminBookmark.getBookmarkListViewDetailRecordGroup}
        listViewDetail={() =>
          DDSystemAdminBookmark.getBookmarkListViewDetail("Groups")
        }
        listViewDisplayName={
          DDSystemAdminBookmark.getBookmarkListViewDisplayName
        }
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "History",
        component: <SysAdminGroupHistoryTab />,
      },
    ],
  });

  return (
    <>
      <CCProductListView
        columnFields={colDirectDebitGroups}
        dataUrl={DIRECT_DEBIT_GROUPS_ODATA}
        primaryField={nameOf("Direct_Debit_Group_ID")}
        state={{
          sort: [{ field: nameOf("Description"), dir: "asc" }],
          filter: {
            filters: [
              {
                field: nameOf("Sys_DBRowState"),
                operator: "eq",
                value: DBRowState.Active,
              },
            ],
            logic: "and",
          },
        }}
      />
    </>
  );
});

import { DirectDebitSessionLine } from "@app/products/direct-debit/sessions/model";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DirectDebitSessionLine>();
export const colDirectDebitSessionLine: IColumnFields[] = [
  {
    field: nameOf("Account_Number"),
    title: "Account Number",
    locked: true,
  },
  {
    field: nameOf("Name"),
    title: "Name",
  },
  {
    field: nameOf("Integration_Module_Type_Name"),
    title: "Module Type",
  },
  {
    field: nameOf("Amount"),
    title: "Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Register_ID"),
    title: "Register ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Register_Account_ID"),
    title: "Register Account ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("AssessmentID"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Assessment_Number"),
    title: "Assessment Number",
  },
  {
    field: nameOf("Assessment_Reference"),
    title: "Assessment Reference",
  },
  {
    field: nameOf("Finance_Customer_No"),
    title: "Finance Customer Number",
  },
  {
    field: nameOf("Finance_Invoice_No"),
    title: "Finance Invoice Number",
  },
  {
    field: nameOf("Bank_Account_Name"),
    title: "Bank Account Name",
  },
  {
    field: nameOf("BSB_No"),
    title: "BSB Number",
  },
  {
    field: nameOf("Bank_Account_No"),
    title: "Bank Account Number",
  },
  {
    field: nameOf("Is_Rejected"),
    title: "Rejected",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Rejected_Reason"),
    title: "Rejected Reason",
  },
];

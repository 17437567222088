import { DirectDebitStatusCode } from "@app/products/direct-debit/system-admin/model";
import { DeleteDirectDebitStatusCodeButton } from "@app/products/direct-debit/system-admin/status-codes/[id]/components/buttons/delete/_index";
import { SaveButtonDirectDebitStatusCode } from "@app/products/direct-debit/system-admin/status-codes/[id]/components/buttons/save/_index";
import { DirectDebitStatusCodeForm } from "@app/products/direct-debit/system-admin/status-codes/[id]/components/general/_index";
import { SysAdminStatusCodeHistoryTab } from "@app/products/direct-debit/system-admin/status-codes/[id]/components/slidebar/history/_index";
import { generateBadgeDBRowState } from "@app/products/direct-debit/system-admin/status-codes/[id]/util";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { FormTitle } from "@components/cc-form/title/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { TypePlaceId } from "@components/cc-notification-portal/constant";
import { YieldNotificationPortal } from "@components/cc-notification-portal/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

export const ExistManageDirectDebitStatusCode = observer(() => {
  const { currentUserInfo } = useGlobalStore();
  const { dataForm } = useFlexibleFormStore();
  const directDebitStatusCode = dataForm?.GeneralForm as DirectDebitStatusCode;

  //#region HOOK ========/
  const location = useLocation();
  const [showSlideBar, setShowSlideBar] = useState(true);
  //#endregion HOOK =====/

  const bookmarkList: IBookmarkItem[] = [
    {
      LinkText: `Direct Debit - System Admin - ${directDebitStatusCode?.Direct_Debit_Status_Code_ID}`,
      Text: `Direct Debit - System Admin`,
      LinkUrl: location.pathname,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.DirectDebit,
      RecordType_ENUM: RECORDTYPE.Receipting_DirectDebit_Status_Code,
      Record_ID: directDebitStatusCode?.Direct_Debit_Status_Code_ID,
    },
  ];

  return (
    <>
      <FormTitle
        title={getDisplayTextWithDashes([
          directDebitStatusCode?.Status_Code,
          directDebitStatusCode?.Status_Description,
        ])}
        badge={
          directDebitStatusCode &&
          generateBadgeDBRowState(directDebitStatusCode?.Sys_DBRowState)
        }
      />
      <CCManagePageActionBar
        leftActions={[
          <SaveButtonDirectDebitStatusCode />,
          <DeleteDirectDebitStatusCodeButton />,
        ]}
        rightActions={[
          <CCNavButton
            title={"Slide Bar"}
            iconClass="fal fa-info-circle"
            isActive={showSlideBar}
            onClick={() => {
              setShowSlideBar(!showSlideBar);
            }}
          />,
          <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
        ]}
      />
      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <YieldNotificationPortal placeId={TypePlaceId.Main} />
          <div className="cc-manage-form-body">
            {directDebitStatusCode && <DirectDebitStatusCodeForm />}
          </div>
        </div>
        {showSlideBar && (
          <div className="cc-manage-right">
            <ReferenceSideBar
              components={[
                {
                  title: "History",
                  component: <SysAdminStatusCodeHistoryTab />,
                },
              ]}
            />
          </div>
        )}
      </div>
    </>
  );
});

import { getAssessmentDetailsTabById } from "@app/products/property/assessments/[id]/components/reference-sidebar/detail/api";
import { AssessmentTabDetailsEventType } from "@app/products/property/assessments/[id]/components/reference-sidebar/detail/config";
import {
  DTO_Assessment_Alert,
  DTO_Assessment_Flag,
  DTO_Assessment_TabDetails,
  DTO_Valuation_Value,
} from "@app/products/property/assessments/[id]/components/reference-sidebar/detail/model";
import { eFlags } from "@app/products/property/assessments/[id]/model";
import { sanitizeAndConvertLineBreaks } from "@app/products/property/assessments/[id]/util";
import { ECustomColNameProperty } from "@app/products/property/config";
import {
  EPropertySettingOption,
  SettingField,
} from "@app/products/property/system-admin/settings/model";
import { isFieldVisible } from "@app/products/property/util";
import { isSuccessResponse } from "@common/apis/util";
import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { AusState } from "@common/constants/enumAusState";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { Label } from "@common/stores/products/config";
import { OrganisationMode } from "@common/stores/products/model";
import { useCommonProductStore } from "@common/stores/products/store";
import { getDropdownValue } from "@common/utils/common";
import {
  formatDisplayValue,
  formatNumberDynamic,
  replaceHyphenByNonBreakingHyphen,
} from "@common/utils/formatting";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import "./_index.scss";

interface IAssessmentDetailTabCommonProps {
  id?: string;
}

export const AssessmentDetailTabCommon = observer(
  ({ id }: IAssessmentDetailTabCommonProps) => {
    const { currentOrganisationMode, currentStateProperty } =
      useCommonProductStore();
    const isLLS = currentOrganisationMode(OrganisationMode.LLS);
    const isGeneral = currentOrganisationMode(OrganisationMode.General);
    const isVic = currentStateProperty(AusState.AUS_STATE_VIC);
    const isActro = currentOrganisationMode(OrganisationMode.ACTRO);
    const [isLoading, setIsLoading] = useState(false);
    const [assessmentInfo, setAssessmentInfo] = useState<
      DTO_Assessment_TabDetails | undefined
    >();

    //Get labels
    const [
      ratepayerLabel,
      assessmentNumbersLabel,
      assessmentNumberLabel,
      assessmentReferenceLabel,
      assessmentIDLabel,
      assessmentGroupLabel,
      categoryLabel,
    ] = Label.CommunityProperty.getLabel([
      ECustomColNameProperty.Ratepayer,
      ECustomColNameProperty.AssessmentNumbers,
      ECustomColNameProperty.AssessmentNumber,
      ECustomColNameProperty.Assessment_Reference,
      ECustomColNameProperty.AssessmentID,
      ECustomColNameProperty.AssessmentGroup,
      ECustomColNameProperty.Category,
    ]);

    useEffect(() => {
      if (id) {
        loadAssessmentDetailTab(id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useAddUniqueEventEmitter([
      {
        eventType: AssessmentTabDetailsEventType.RefreshData,
        listener: () => {
          if (id) {
            loadAssessmentDetailTab(id);
          }
        },
      },
    ]);

    const processFlagsOnOrganisation = (flags: DTO_Assessment_Flag[]) => {
      if (isNil(flags) || flags?.length === 0) return [];
      return flags?.filter((item) => {
        if (
          (!isLLS &&
            item.Id === eFlags["Single Farm Enterprise Exemption Granted"]) ||
          (!isVic && item.Id === eFlags["Pay in Full"])
        ) {
          return false;
        }
        return true;
      });
    };

    const loadAssessmentDetailTab = async (assessmentId: string) => {
      if (assessmentId) {
        setIsLoading(true);
        const response = await getAssessmentDetailsTabById(
          parseInt(assessmentId)
        );
        if (
          Array.isArray(response) &&
          isSuccessResponse(response[0]) &&
          isSuccessResponse(response[1])
        ) {
          const [detail, optionSettings] = response;
          if (detail?.data && optionSettings?.data) {
            const data = detail?.data;
            const setting = optionSettings?.data;
            const flags = (
              data?.Flags?.length ? processFlagsOnOrganisation(data.Flags) : []
            ) as DTO_Assessment_Flag[];
            const isAssessmentRefFlag =
              setting?.SettingFields?.find(
                (settingField: SettingField) =>
                  settingField.FieldName ===
                  EPropertySettingOption.UsingAssessReference
              )?.Value ?? false;
            setAssessmentInfo(
              isNil(data)
                ? undefined
                : {
                    ...data,
                    Flags: flags,
                    isAssessmentRef: isAssessmentRefFlag,
                  }
            );
          }
        }
        setIsLoading(false);
      }
    };
    /**
     * isLLS it is previous requirement
     * isGeneral - 14547 apply for Glenorchy
     */
    const isHiddenGroupLeaseField = useMemo(() => {
      return isLLS || isGeneral;
    }, [isGeneral, isLLS]);

    if (isLoading) return <Loading isLoading={isLoading} />;
    if (!assessmentInfo) return <NoData />;

    return (
      <ProductReferenceBody>
        <PanelBar expandMode={"single"}>
          <PanelBarItem
            title={
              <ProductReferenceHeading
                title={ratepayerLabel}
                value={sanitizeAndConvertLineBreaks(
                  assessmentInfo?.Assessment?.Ratepayer
                )}
                primary
              />
            }
          >
            <>
              <ProductReferenceBlock>
                {!isLLS && (
                  <>
                    <ProductReferenceRow
                      title={"Overdue:"}
                      value={formatDisplayValue(
                        assessmentInfo?.FinancialAreaBalances?.Totals?.Overdue,
                        CURRENCY_FORMAT.CURRENCY1
                      )}
                      format="currency"
                    />
                    <ProductReferenceRow
                      title={"Due:"}
                      value={formatDisplayValue(
                        assessmentInfo?.FinancialAreaBalances?.Totals?.Due,
                        CURRENCY_FORMAT.CURRENCY1
                      )}
                      format="currency"
                    />
                    <ProductReferenceRow
                      title={"Due + overdue:"}
                      value={formatDisplayValue(
                        assessmentInfo?.FinancialAreaBalances?.Totals
                          ?.OverdueDue,
                        CURRENCY_FORMAT.CURRENCY1
                      )}
                      format="currency"
                    />
                    <ProductReferenceRow
                      title={"Pending:"}
                      value={formatDisplayValue(
                        assessmentInfo?.FinancialAreaBalances?.Totals?.Pending,
                        CURRENCY_FORMAT.CURRENCY1
                      )}
                      format="currency"
                    />
                    <ProductReferenceRow
                      title={"Balance owing:"}
                      value={formatDisplayValue(
                        assessmentInfo?.FinancialAreaBalances?.Totals?.Balance,
                        CURRENCY_FORMAT.CURRENCY1
                      )}
                      format="currency"
                    />
                  </>
                )}
              </ProductReferenceBlock>
            </>
          </PanelBarItem>

          <PanelBarItem
            title={
              <ProductReferenceHeading
                title={assessmentNumbersLabel}
                id={`cc-title-ref-${ECustomColNameProperty.AssessmentNumbers}`}
              />
            }
          >
            <ProductReferenceBlock>
              {/* TODO: Krishna confirmed that we can use ECustomColName.AssessmentNumber instead of ECustomColName.AssessmentNumberX */}
              {/* Waiting confirm to split phrase or whole phrase */}
              <ProductReferenceRow
                title={assessmentNumberLabel}
                id={`cc-product-ref-${ECustomColNameProperty.AssessmentNumber}`}
                value={assessmentInfo?.Assessment?.AssessmentNumberX}
              />
              {!isLLS && assessmentInfo?.isAssessmentRef && (
                <ProductReferenceRow
                  title={replaceHyphenByNonBreakingHyphen(
                    assessmentReferenceLabel ?? ""
                  )}
                  id={`cc-product-ref-${ECustomColNameProperty.Assessment_Reference}`}
                  value={assessmentInfo?.Assessment?.AssessmentReference}
                />
              )}
              <ProductReferenceRow
                title={assessmentIDLabel + ":"}
                id={`cc-product-ref-${ECustomColNameProperty.AssessmentID}`}
                value={assessmentInfo?.Assessment?.Id}
              />
              {!isLLS && (
                <ProductReferenceRow
                  title={"Valuation number:"}
                  value={assessmentInfo?.Assessment?.ValuationNumberX}
                />
              )}
              {isFieldVisible(
                assessmentInfo?.Assessment?.BPayReferenceVisibility
              ) && (
                <ProductReferenceRow
                  title={"Bpay number:"}
                  value={assessmentInfo?.Assessment?.BPayReference}
                />
              )}
              {isFieldVisible(
                assessmentInfo?.Assessment?.AustPostReferenceVisibility
              ) && (
                <ProductReferenceRow
                  title={"Australia post:"}
                  value={assessmentInfo?.Assessment?.AustPostReference}
                />
              )}
              {isFieldVisible(
                assessmentInfo?.Assessment?.CBAReferenceVisibility
              ) && (
                <ProductReferenceRow
                  title={"CBA reference:"}
                  value={assessmentInfo?.Assessment?.CBAReference}
                />
              )}
            </ProductReferenceBlock>
          </PanelBarItem>

          <PanelBarItem
            title={<ProductReferenceHeading title={"Land Information"} />}
          >
            <ProductReferenceBlock>
              <ProductReferenceRow
                title={"Legal description:"}
                value={assessmentInfo?.Assessment?.Legal_Description}
              />
              {!isActro && (
                <>
                  {isFieldVisible(
                    assessmentInfo?.Assessment?.ImprovementVisibility
                  ) && (
                    <ProductReferenceRow
                      title={"Improvements:"}
                      value={assessmentInfo?.Assessment?.Improvements}
                    />
                  )}
                  {isFieldVisible(
                    assessmentInfo?.Assessment?.CalculatedLandAreaVisibility
                  ) && (
                    <ProductReferenceRow
                      title={"Calculated land area:"}
                      value={
                        assessmentInfo?.Assessment?.CalculatedLandAreaFormatted
                      }
                    />
                  )}
                </>
              )}
              <ProductReferenceRow
                title={"Primary land use:"}
                value={assessmentInfo?.Assessment?.PrimaryLandUse}
              />
              <ProductReferenceRow
                title={"Secondary land use:"}
                value={assessmentInfo?.Assessment?.SecondaryLandUse}
              />
              <ProductReferenceRow
                title={"GIS land area:"}
                value={
                  (assessmentInfo?.Assessment?.GISLandArea ?? "") +
                  ` ${assessmentInfo?.Assessment?.GISLandAreaUnit ?? ""}`
                }
              />
              <ProductReferenceRow
                title={"Actual land area:"}
                value={
                  (assessmentInfo?.Assessment?.ActualLandArea ?? "") +
                  ` ${assessmentInfo?.Assessment?.ActualLandAreaUnit ?? ""}`
                }
              />
              {isHiddenGroupLeaseField ? null : (
                <>
                  <ProductReferenceRow
                    title={"Lease commenced:"}
                    value={formatDisplayValue(
                      assessmentInfo?.Assessment?.LeaseCommenced,
                      DATE_FORMAT.DATE
                    )}
                  />
                  <ProductReferenceRow
                    title={"Lessee in:"}
                    value={formatDisplayValue(
                      assessmentInfo?.Assessment?.LesseeIn,
                      DATE_FORMAT.DATE
                    )}
                  />
                  <ProductReferenceRow
                    title={"Land tax levy:"}
                    value={assessmentInfo?.Assessment?.LandTaxLevy}
                  />
                </>
              )}
            </ProductReferenceBlock>
          </PanelBarItem>

          <PanelBarItem
            title={<ProductReferenceHeading title={"Status And Groups"} />}
          >
            <ProductReferenceBlock>
              <ProductReferenceRow
                title={"Status:"}
                value={assessmentInfo?.Assessment?.Status}
              />
              <ProductReferenceRow
                title={"Type:"}
                value={assessmentInfo?.Assessment?.Type}
              />
              <ProductReferenceRow
                title={assessmentGroupLabel + ":"}
                value={assessmentInfo?.Assessment?.Group}
              />
              <ProductReferenceRow
                title={categoryLabel + ":"}
                value={assessmentInfo?.Assessment?.Category}
              />
            </ProductReferenceBlock>
          </PanelBarItem>

          <PanelBarItem
            title={
              <ProductReferenceHeading
                flexBetween
                title={"Valuations"}
                value={(() => {
                  const rateableUV = getDropdownValue(
                    "Rateable UV",
                    assessmentInfo?.Assessment?.CurrentValuation?.Values ?? [],
                    "Name"
                  );
                  return formatNumberDynamic(
                    rateableUV?.Value,
                    CURRENCY_FORMAT.CURRENCY1 +
                      Math.trunc(rateableUV?.DecimalPlaces ?? 0)
                  );
                })()}
                format="currency"
              />
            }
          >
            <ProductReferenceBlock>
              {assessmentInfo?.Assessment?.CurrentValuation?.Values?.length ? (
                assessmentInfo.Assessment.CurrentValuation.Values.map(
                  (item: DTO_Valuation_Value, index: number) => {
                    const isCurrency = item.IsCurrency;
                    const format =
                      (isCurrency
                        ? CURRENCY_FORMAT.CURRENCY1
                        : NUMBER_FORMAT.NUMBER) +
                      Math.trunc(item.DecimalPlaces ?? 0);

                    return (
                      <ProductReferenceRow
                        key={index}
                        title={`${item.Name}:`}
                        value={formatNumberDynamic(item.Value, format)}
                        format={isCurrency ? "currency" : "number"}
                      />
                    );
                  }
                )
              ) : (
                <NoData />
              )}
            </ProductReferenceBlock>
          </PanelBarItem>

          <PanelBarItem
            title={<ProductReferenceHeading title={"Flags"} />}
            expanded={true}
          >
            <div className="cc-flags-container">
              <div className="cc-flags-group">
                {assessmentInfo?.Flags?.length ? (
                  <ProductReferenceBlock flex>
                    {assessmentInfo.Flags.map(
                      (item: DTO_Assessment_Flag, index: number) => (
                        <ProductReferenceRow
                          key={index}
                          badgeSmall
                          value={item.Name}
                          success
                        />
                      )
                    )}
                  </ProductReferenceBlock>
                ) : null}
                {assessmentInfo?.AlertMessages?.length
                  ? assessmentInfo.AlertMessages.map(
                      (item: DTO_Assessment_Alert, index: number) => (
                        <ProductReferenceRow
                          key={index}
                          badge
                          value={item.Message}
                          danger
                        />
                      )
                    )
                  : null}
              </div>
            </div>
          </PanelBarItem>
        </PanelBar>
      </ProductReferenceBody>
    );
  }
);

import AssessmentReAllocationDialog from "@app/products/property/assessments/components/dialogs/re-allocation/_index";
import { OrganisationMode } from "@common/stores/products/model";
import { useCommonProductStore } from "@common/stores/products/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { postReallocationOfCredits } from "@app/products/property/assessments/components/action-bar/buttons/re-allocation-of-credits/api";
import { isSuccessResponse } from "@common/apis/util";

export const ReAllocationOfCreditsButton = observer(() => {
  const { currentOrganisationMode } = useCommonProductStore();
  const isLLS = currentOrganisationMode(OrganisationMode.LLS);
  const { pushNotification } = useCCAppNotificationStore();
  const [showReAllocationDialog, setShowReAllocationDialog] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handlePostHouseKeeping = async () => {
    setIsLoading(true);
    const response = await postReallocationOfCredits();
    setIsLoading(false);
    if (isSuccessResponse(response) && response?.data?.IsSuccess) {
      pushNotification({
        title:
          response.data?.SuccessMessage ??
          "A Credit Reallocation task is created successfully",
        type: "success",
      });
    } else {
      pushNotification({
        autoClose: false,
        title:
          response.data?.ErrorMessage ??
          "A Credit Reallocation task is already being processed or is on the job queue.",
        type: "error",
      });
    }
    setShowReAllocationDialog(false);
  };

  return (
    <>
      <CCNavButton
        title="Reallocation Credits"
        onClick={() => setShowReAllocationDialog(true)}
        invisible={isLLS}
      />

      {showReAllocationDialog && (
        <AssessmentReAllocationDialog
          onClosePopup={() => {
            setShowReAllocationDialog(false);
          }}
          onAsyncConfirm={() => {
            handlePostHouseKeeping();
          }}
          isLoadingYes={isLoading}
        />
      )}
    </>
  );
});

import {
  DTO_CreateAssessment_Parcel,
  DTO_Title,
} from "@app/products/property/assessments/components/form-steps/new-assessment/components/form-elements/associations-parcel/model";
import { IListDialog } from "@app/products/property/components/action-bar/property-workflow/model";
import { DTO_GIS } from "@app/products/property/components/dialogs/gis-reference/model";
import { DTO_WorkflowHeader, eVisibility } from "@app/products/property/model";
import { APIResponse } from "@common/apis/model";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { DTO_LOV } from "@common/models/odataResponse";
export interface DTO_Workflow_CreateAssessment {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_CreateAssessment;
}

export interface DTO_WorkflowDetail_CreateAssessment {
  Details: DTO_Default_Assessment_Page;
  PhysicalAddresses: DTO_Address[];
  AssessmentDetail: DTO_Assessment_Detail;
  SelectedSecondaryWorkflow: number[];
  SelectedCollection: Collection[];
  Association: DTO_CreateAssessment_Association;
  //Using for Finish feature => show secondary workflow
  SecondaryWorkflowSelectedList?: IListDialog[];
}
export interface DTO_Default_Assessment_Page {
  Reference: string;
  ApplicantName: string;
  ReasonForCreation: string;
  LeaseAdviceNumber: number;
  LeaseType: number;
  LastLeaseNumber: number;
  LeaseAdviceNumberVisibility: eVisibility;
  LeaseTypeVisibility: eVisibility;
  LastLeaseNumberVisibility: eVisibility;
  Type?: number;
}
export interface DTO_Assessment_Detail {
  PlanRegistrationDate?: Date;
  FSL_Land_Status?: number;
  SingleFarmEnterpriseExemption?: boolean;
  SpatialLandArea?: number;
  RateableLandArea?: number;
  InstalmentPlanTypeId: number;
  Unit: string;
  Block: string;
  Section: string;
  Suburb: string;
  GisReferences: DTO_GIS[];
  VV_Valuation?: number;
  Alert_Message: string;
  Created_By: string;
  GurasID?: number;
  OfficialAddressStringOID?: number;
  LeaseAdviceNumberVisibility: eVisibility;
  SuburbVisibility: eVisibility;
  SectionVisibility: eVisibility;
  BlockVisibility: eVisibility;
  FSL_Land_StatusVisibility: eVisibility;
  LGAVisibility: eVisibility;
  GISReferenceGridVisibility: eVisibility;
  SAPHoldingNumberVisibility: eVisibility;
  ImprovementVisibility: eVisibility;
  ActualLandAreaUnitCodeVisibility: eVisibility;
  ActualLandAreaVisibility: eVisibility;
  CountryVisibility: eVisibility;
  StateVisibility: eVisibility;
  PlanRegistrationDateVisibility: eVisibility;
  PlanRegistrationNumberVisibility: eVisibility;
  FolioVisibility: eVisibility;
  VolumeVisibility: eVisibility;
  CreationDateVisibility: eVisibility;
  AssessmentNumberVisibility: eVisibility;
  AssessmentReferenceVisibility: eVisibility;
  UnitVisibility: eVisibility;
  PropId?: number;
  Centroid_Lat?: number;
  Centroid_Lng?: number;
  Effective_To_Date?: Date;
  PrimaryLandUseId?: number;
  PlanRegistrationNumber: string;
  DifferentialCategoryName: string;
  CreationDate?: Date;
  CalculatedLandArea: string;
  Region_Name: string;
  Sector?: number;
  Type_Name: string;
  Type?: number;
  ProposedStatus_Name: string;
  ProposedStatus?: number;
  Status_Name: string;
  Status: number;
  ValuationNumber?: number;
  AssessmentReference: string;
  AssessmentNumber?: number;
  AssessmentId: number;
  PrimaryLandUse: string;
  RLPBoardVisibility: eVisibility;
  SecondaryLandUseId?: number;
  LGA: string;
  Effective_From_Date?: Date;
  GIS_GlobalID: string;
  GIS_Property_ID?: number;
  LGA_Id?: number;
  Differential_Rate_Classification_Id?: number;
  DRC_Code: string;
  SAPHoldingNumber: string;
  GISLandAreaUnitCode: string;
  GISLandArea?: number;
  ActualLandAreaUnitCode: string;
  Country: string;
  State: string;
  Folio: string;
  Volume: string;
  IsCommonProperty?: boolean;
  Improvements: string;
  ActualLandArea?: number;
  SecondaryLandUse: string;
  GISLandAreaVisibility: eVisibility;
}

export interface DTO_Address {
  Locality: string;
  ParcelId: number;
  PostalDeliveryId: number | null;
  MapReference: string;
  MapNumber: string;
  StreetAndLocalityId: number | null;
  SideOfStreet: string;
  LocationDescriptor: string;
  FormattedAddress: string;
  Country: string;
  State: string;
  HouseTo: string;
  HouseFrom: string;
  AddressPFI: number;
  IsOutsideProperty: boolean;
  IsRuralHouseNumber: boolean;
  FloorNumberTo: string;
  FloorNumberFrom: string;
  FloorType: string;
  UnitNumberTo: string;
  UnitNumberFrom: string;
  BuildingUnitNumber: string;
  BuildingName: string;
  PropertyName: string;
  IsAddressPrimary: boolean;
  PropertyAddress: string;
  AddressTypeName: string;
  AddressTypeId: number;
  AddressId: number;
  StreetName: string;
  PostCode: number;
  StreetAndLocalityName: string;
  IsAddressValid: boolean;

  // Gen mock id
  RowId: string;
}

export interface DTO_Folio {
  Parcel_Id?: number;
  PropertyAddress?: string;
  ParcelReference?: string;
  Locality?: string;
  LegalDescription?: string;
  CensusDistrict?: string;
  ElectoralDivision?: string;
  StateCodeElectoralDivision?: string;
  FederalElectoralDivision?: string;
  LandArea?: string;
  Zones?: string;
  LandUse?: string;
  Status?: string;
  OwnerName?: string;
}
export interface DTO_Workflow_Create_Assessment_Response {
  WorkflowAssessment?: DTO_Workflow_Assessment_Response;
}
export interface Collection {
  Collection_Id: number;
  CollectionTypeName: string;
}
export interface DTO_Workflow_Assessment_Response {
  AssessmentDetails?: DTO_WorkflowDetail_CreateAssessment;
}

export enum FormTypes {
  StandardForm = 1,
  Definable_Form = 2,
  Report = 3,
  ListView = 4,
  Workflow = 5,
}
export interface AssessmentDetail {
  Instructions?: string;
  Reference?: string;
  Applicant?: string;
  ReasonForCreation?: IKeyValuePacket;
  ReasonRejection?: string;
  FolioList?: DTO_Folio[];
}
export enum EKeysOfSteps {
  PhysicalAddress = "PhysicalAddresses",
  Details = "Details",
  Comments = "Comments",
  Documents = "Documents",
  AssessmentDetail = "AssessmentDetail",
  SecondaryWorkflow = "SecondaryWorkflow",
  Collection = "SelectedCollection",
  Association = "Association",
}

export const keysOfSendSteps = [
  EKeysOfSteps.Details,
  EKeysOfSteps.PhysicalAddress,
  EKeysOfSteps.AssessmentDetail,
  EKeysOfSteps.Association,
  EKeysOfSteps.Collection,
  EKeysOfSteps.SecondaryWorkflow,
];

export interface DTO_Create_Assessment_LOVs {
  LocalityName_Id: DTO_LOV[];
  LocalityCode_PFI: DTO_LOV[];
  LocalityName_PostCode: DTO_LOV[];
  LocalityPFI_Id: DTO_LOV[];
  Instalment_Plan_Type: DTO_LOV[];
  Assessment_Group: DTO_LOV[];
  Workflow_Memo_Category: DTO_LOV[];
  Assessment_LGA: DTO_LOV[];
  Assessment_Secondary_Land_Use: DTO_LOV[];
  Assessment_Primary_Land_Use: DTO_LOV[];
  Assessment_Assess_Type: DTO_LOV[];
  Assessment_Type: DTO_LOV[];
  Assessment_Status: DTO_LOV[];
  Address_Side_of_Street: DTO_LOV[];
  Address_Location_Descriptor: DTO_LOV[];
  Address_State: DTO_LOV[];
  Address_FloorType: DTO_LOV[];
  Address_UnitType: DTO_LOV[];
  Components: DTO_LOV[];
  ReasonForCreation: DTO_LOV[];
  ACT_Lease_Advice_Type: DTO_LOV[];
  Collection_Type: DTO_LOV[];
  Country: DTO_LOV[];
  LandArea_Unit: DTO_LOV[];
  Address_Type: DTO_LOV[];
  Differential_Rate_Classification: DTO_LOV[];
  GISType: DTO_LOV[];
  FSLLandStatus: DTO_LOV[];
  Assessment_ProposedStatus: DTO_LOV[];
}

export interface DTO_CreateAssessment_Association {
  AssociateParcels: DTO_CreateAssessment_Parcel[];
  AssociateTitles: DTO_Title[];
}

export interface DTO_Assessment_LOVs {
  dtoCreate_Assessment_LOVs: DTO_Create_Assessment_LOVs;
}

export type IGetInitialDataAssessment =
  | [
      APIResponse<DTO_Assessment_LOVs>,
      APIResponse<DTO_Workflow_CreateAssessment>
    ]
  | APIResponse<DTO_Assessment_LOVs | DTO_Workflow_CreateAssessment>
  | undefined;

import { DirectDebitMenu } from "@app/products/direct-debit/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const ddSessionsRoute: ICCRoute = {
  path: "sessions",
  name: "Sessions",
  enum: DirectDebitMenu.Sessions,
  children: [
    {
      path: ":id([0-9]+)",
      component: require("./[id]/_index").default,
    },
    {
      path: "by-status",
      name: "By Status",
      enum: DirectDebitMenu.SessionsByStatus,
      component: require("./by-status/_index").default,
    },
    {
      path: "by-date",
      name: "By Date",
      enum: DirectDebitMenu.SessionsByDate,
      component: require("./by-date/_index").default,
    },
    {
      path: "not-completed",
      name: "Not Completed",
      enum: DirectDebitMenu.SessionsNotCompleted,
      component: require("./not-completed/_index").default,
    },
  ],
};

import {
  CC_DIALOG_VISIBLE_CLASS_NAME,
  CC_NAV_DIALOG_CLASS_NAME,
  defaultSize,
} from "@components/cc-dialog/config";
import { EBadgeDialogType, IBadgeDialog } from "@components/cc-dialog/model";
import {
  setMaxHeight,
  setMaxWidth,
  setMinWidth,
} from "@components/cc-dialog/util";
import { AttributeYieldNotificationPortal } from "@components/cc-notification-portal/constant";
import { notificationPortalStoreInstance } from "@components/cc-notification-portal/store";
import Loading from "@components/loading/Loading";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { isNil } from "lodash";
import React, { useEffect, useMemo, useRef } from "react";
import { useWindowSize } from "react-use";
import "./_index.scss";
export interface ICCDialogProps {
  bodyElement: React.ReactElement;
  footerElement?: React.ReactElement;
  titleHeader: string | React.ReactElement;
  badge?: IBadgeDialog[];
  height: string | number;
  width: string | number;
  minWidth?: string | number;
  maxWidth?: string | number;
  maxHeight?: string | number;
  unsetMinHeight?: boolean;
  fixedWidth?: string | number;
  onClose: (value: any) => void;
  closeIcon?: boolean;
  disabled?: boolean;
  dialogWrapperClassName?: string;
  isLoading?: boolean;
  yieldNotificationPortal?: string;
  isFormStep?: boolean;
}
export const CCDialog = (props: ICCDialogProps) => {
  const dialogRef = useRef<any>(null);
  const { width: widthOfWindow, height: heightOfWindow } = useWindowSize();
  const {
    isLoading = false,
    minWidth,
    maxWidth,
    fixedWidth,
    maxHeight,
    titleHeader,
    badge,
    closeIcon,
    width,
    height,
    onClose,
    dialogWrapperClassName,
    disabled,
    unsetMinHeight,
    bodyElement,
    footerElement,
    yieldNotificationPortal,
    isFormStep,
  } = props;

  useEffect(() => {
    setMinWidth(widthOfWindow, minWidth, dialogRef.current, fixedWidth);
    setMaxWidth(widthOfWindow, maxWidth, dialogRef.current, fixedWidth);
    setMaxHeight(maxHeight, dialogRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widthOfWindow, heightOfWindow]);

  useEffect(() => {
    const navDropdown: HTMLDivElement | null = dialogRef.current?.element
      ? dialogRef.current?.element.closest(CC_NAV_DIALOG_CLASS_NAME)
      : null;
    const dialogs: HTMLCollectionOf<Element> =
      document.getElementsByClassName("cc-dialog");
    if (navDropdown) navDropdown.classList.add(CC_DIALOG_VISIBLE_CLASS_NAME);
    return () => {
      if (navDropdown && dialogs.length === 1)
        navDropdown.classList.remove(CC_DIALOG_VISIBLE_CLASS_NAME);
    };
  }, [dialogRef]);

  const titleHeaderDialog = useMemo(() => {
    return (
      <div className="cc-dialog-title-header">
        <div>{titleHeader}</div>
        {isNil(badge) ||
        !Array.isArray(badge) ||
        (Array.isArray(badge) && badge?.length === 0) ? null : (
          <div className="cc-dialog-badge-list">
            {badge?.map((item: IBadgeDialog) => (
              <span
                className={`cc-dialog-badge-${
                  item?.type ?? EBadgeDialogType.NORMAL
                }`}
              >
                {item.content}
              </span>
            ))}
          </div>
        )}
      </div>
    );
  }, [titleHeader, badge]);

  useEffect(() => {
    isFormStep &&
      yieldNotificationPortal &&
      notificationPortalStoreInstance.setYieldNotificationPortal(
        yieldNotificationPortal
      );
    return () => {
      yieldNotificationPortal &&
        notificationPortalStoreInstance.removeNotificationPortalByPlaceId(
          yieldNotificationPortal
        );
    };
  }, [yieldNotificationPortal, isFormStep]);

  return (
    <Dialog
      ref={dialogRef}
      closeIcon={closeIcon}
      width={fixedWidth ? fixedWidth : width}
      height={height}
      title={titleHeaderDialog}
      onClose={onClose}
      className={`${
        dialogWrapperClassName ? dialogWrapperClassName : ""
      } cc-dialog ${disabled ? "cc-dialog-disabled" : ""} ${
        unsetMinHeight ? "cc-unset-min-height" : ""
      }`}
    >
      <div className="cc-dialog-body">
        {!isFormStep && (
          <div
            {...{ [AttributeYieldNotificationPortal]: yieldNotificationPortal }}
          />
        )}
        {isLoading ? <Loading isLoading={isLoading} /> : bodyElement}
      </div>
      {footerElement && (
        <DialogActionsBar>
          <div className="cc-dialog-footer">{footerElement}</div>
        </DialogActionsBar>
      )}
    </Dialog>
  );
};

CCDialog.defaultProps = {
  width: defaultSize.width,
  height: defaultSize.height,
  maxWidth: defaultSize.maxWidth,
};

import { DirectDebitAccount } from "@app/products/direct-debit/accounts/model";

export const DDAccountsBookmark = {
  getBookmarkListViewDisplayName() {
    return "Direct Debit - Account";
  },

  getBookmarkListViewDetail(viewName: string) {
    return `Direct Debit - Account - ${viewName}`;
  },

  getBookmarkListViewDetailRecord(selectedRow: DirectDebitAccount) {
    const dynamicDisplayName = selectedRow?.Account_ID
      ? `- ${selectedRow.Account_ID}`
      : "";
    return `Direct Debit - Account ${dynamicDisplayName}`;
  },
};
